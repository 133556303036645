import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom';
import "react-tabs/style/react-tabs.css";
import Select from 'react-select';
import axios from 'axios';
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import Aviso from '../../components/Aviso';


class Form extends Component {

    state = {
        detalhes: {
            ativo: "1",
            acao: "",
            descricao: "",
            monitorado_executa: "",
            equipamento_executa: "",
            monitorado_recebe: "",
            equipamento_recebe: "",
            rele: "1",
            dias_da_semana: { "dom": false, "seg": false, "ter": false, "qua": false, "qui": false, "sex": false, "sab": false },
        },
        formerrors: [],
        total_relays: 0,
        redirect: false,
        path: "",
        condicao_dias_personalizados: false,
        dias_pre_definido_cond: "",
        acoes: [{ label: "Ligar", value: 1 }, { label: "Desligar", value: 0 }],
        monitorados: [],
        equipamento_executa: [],
        equipamento_recebe: [],
        equipamentos_automacao: [],
        //condicoes: [{campo: "", condicao: "", valor: ""}],
        condicoes: [],
        //condicoes: [{equipamento: "", condicao: "igual", valor: ""}],
        condicoes_automacoes: [],
        condicoesSelected: { campo: { 0: "" }, condicao: { 0: "" } },
        periodos: [],
        optionSelected: {
            "acao": {},
            "monitorado_executa": {},
            "equipamento_executa": {},
            "monitorado_recebe": {},
            "equipamento_recebe": {}
        },
        erros: [],
        campos: [
            { label: "Horímetro Virtual", value: "horimetro_virtual", dataType: "text" },
            { label: "Consumo Diário", value: "consumo_diario", dataType: "text" },
        ],
        operadores: [
            { label: "Igual (=)", value: "igual" },
            { label: "Maior ou Igual (>=)", value: "maior_igual" },
            { label: "Menor ou Igual (<=)", value: "menor_igual" },
            { label: "Maior (>)", value: "maior" },
            { label: "Menor (<)", value: "menor" },
            { label: "Diferente (!=)", value: "diferente" },
        ],
        'equipamento_selecionado': { value: '', label: '' },
        aviso: { show: false, args: null, message: '' },
        cooldown: false,
    }

    componentWillMount = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado/', config_me))

        axios.all(requestArray)
            .then(axios.spread((monitorados) => {
                console.log(monitorados)
                let monitoradoSelected = []
                monitorados.data.forEach(monitorado => {
                    monitoradoSelected.push({ value: monitorado.id, label: monitorado.nome })
                });
                this.setState({ "monitorados": monitoradoSelected })
            }));
    }

    componentDidMount = () => {
        if (this.props.location.state) {
            let equipamento_selecionado = ("equipamento_selecionado" in this.props.location.state) ? this.props.location.state.equipamento_selecionado : false
            let monitorado_selecionado = ("monitorado_selecionado" in this.props.location.state) ? this.props.location.state.monitorado_selecionado : false
            this.setState({ 'equipamento_selecionado': equipamento_selecionado, 'monitorado_selecionado': monitorado_selecionado })
            this.handleChangeSelectExecuta(monitorado_selecionado, 'monitorado_executa')
            this.handleChangeSelectRecebe(monitorado_selecionado, 'monitorado_recebe')
            this.handleChangeSelectEquipamentoBuscaCondicoes(equipamento_selecionado, 'equipamento_executa')
            this.handleChangeSelect(equipamento_selecionado, 'equipamento_recebe')
        }
        if (this.props.match.params.id) {
            this.getData()
        }
    }

    getData = () => {
        let resource = "automacao"
        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((automacao_registro) => {
                console.log(automacao_registro.data)

                let requestArray = []
                requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/item/?page=0&pageSize=10&filter=' + encodeURI(JSON.stringify([{ "id": "monitorado_id", "value": automacao_registro.data.monitorado_executa, "type": "int" }])), config_me))
                requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/item/?page=0&pageSize=10&filter=' + encodeURI(JSON.stringify([{ "id": "monitorado_id", "value": automacao_registro.data.monitorado_recebe, "type": "int" }])), config_me))

                axios.all(requestArray)
                    .then(axios.spread((equipamento_executa, equipamento_recebe) => {
                        console.log(equipamento_executa, equipamento_recebe)

                        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/buscar-entradas-hidrohub/' + automacao_registro.data.equipamento_executa__imei + '/', config_me)
                            .then((entradas_hidrohub) => {

                                //ADICIONA OS CAMPOS SELECIONADOS NAS CONDIÇÕES
                                let entradasCampos = [
                                    { label: "Horímetro Virtual", value: "horimetro_virtual", dataType: "text" },
                                ]
                                if (entradas_hidrohub.status === 200) {
                                    entradas_hidrohub.data.entrada4a20.forEach((value) => {
                                        entradasCampos.push({ label: "#" + value.canal + ' ' + value.descricao, value: 'entrada4a20_' + value.canal, dataType: "text" })
                                    })
                                    entradas_hidrohub.data.entradalogica.forEach((value) => {
                                        if (value.tipo_entrada_logica_id === 3) {
                                            entradasCampos.push({ label: "#" + value.canal + ' ' + value.tipo_entrada_logica__descricao, value: 'status_' + value.canal, dataType: "text" })
                                        } else {
                                            entradasCampos.push({ label: "#" + value.canal + ' ' + value.tipo_entrada_logica__descricao, value: 'entradalogica_' + value.canal, dataType: "text" })
                                            if (value.tipo_entrada_logica_id === 1)
                                                entradasCampos.push({ label: "#" + value.canal + ' ' + 'Vazão', value: 'ct_pulso_' + value.canal, dataType: "text" })
                                        }
                                    })
                                    entradas_hidrohub.data.outros_campos.forEach((value) => {
                                        entradasCampos.push({ label: value.label, value: value.value })
                                    })
                                }
                                //////////////////////////////////////////

                                //PREENCHE COM OS EQUIPAMENTOS, BASEADO NA SELEÇÃO DO MONITORADO
                                let detalhes = {}
                                detalhes['acao'] = (automacao_registro.data.acao === true) ? 1 : 0
                                detalhes['ativo'] = (automacao_registro.data.ativo === true) ? 1 : 0
                                detalhes['dias_da_semana'] = JSON.parse(automacao_registro.data.dias_da_semana)
                                detalhes['descricao'] = automacao_registro.data.descricao
                                detalhes['monitorado_executa'] = automacao_registro.data.monitorado_executa
                                detalhes['equipamento_executa'] = automacao_registro.data.equipamento_executa__imei
                                detalhes['monitorado_recebe'] = automacao_registro.data.monitorado_recebe
                                detalhes['equipamento_recebe'] = automacao_registro.data.equipamento_recebe__imei
                                detalhes['rele'] = automacao_registro.data.rele
                                ////////////////////////////////////////////
                                let equipamentos_executa = []
                                if (entradas_hidrohub.status === 200) {
                                    if (equipamento_executa.data !== "") {
                                        equipamento_executa.data.results.forEach((value) => {
                                            equipamentos_executa.push({ label: value.equipamento__imei, value: value.equipamento__imei })
                                        })
                                    }
                                }
                                let equipamentos_recebe = []
                                if (entradas_hidrohub.status === 200) {
                                    if (equipamento_recebe.data !== "") {
                                        equipamento_recebe.data.results.forEach((value) => {
                                            equipamentos_recebe.push({ label: value.equipamento__imei, value: value.equipamento__imei })
                                        })
                                    }
                                }
                                ////////////////////////////////////////////

                                //ADICIONAR VALORES NO ESTADO DETALHES, RESPONSAVEL PELA MAIORIA DOS CAMPOS

                                //ADICIONANDO EM CONDICAO E PERIODO
                                let condicoes = JSON.parse(automacao_registro.data.condicao)
                                let condicoes_automacoes = (automacao_registro.data.condicao_automacao) ? JSON.parse(automacao_registro.data.condicao_automacao) : []
                                let periodos = (automacao_registro.data.periodo) ? JSON.parse(automacao_registro.data.periodo) : []
                                ////////////////////////////////////////////

                                //GERAR ITENS DOS SELECTS
                                let objSelected = this.state.optionSelected
                                if (detalhes['acao'] === 1) {
                                    objSelected['acao'] = { label: "Ligar", value: detalhes['acao'] }
                                } else {
                                    objSelected['acao'] = { label: "Desligar", value: detalhes['acao'] }
                                }

                                let monitorado_executa_selecionado = this.state.monitorados.filter((monitorado) => {
                                    if (monitorado.value === detalhes['monitorado_executa']) {
                                        return true
                                    } else {
                                        return false
                                    }
                                })

                                if (monitorado_executa_selecionado.length){
                                    objSelected['monitorado_executa'] = { label: monitorado_executa_selecionado[0].label, value: monitorado_executa_selecionado[0].value }
                                }

                                let monitorado_recebe_selecionado = this.state.monitorados.filter((monitorado) => {
                                    if (monitorado.value === detalhes['monitorado_recebe']) {
                                        return true
                                    } else {
                                        return false
                                    }
                                })

                                if(monitorado_recebe_selecionado.length){
                                    objSelected['monitorado_recebe'] = { label: monitorado_recebe_selecionado[0].label, value: monitorado_recebe_selecionado[0].value }
                                }

                                let equip_executa_selecionado = equipamento_executa.data.results.filter((equip) => {
                                    if (equip.equipamento__imei === detalhes['equipamento_executa']) {
                                        return true
                                    } else {
                                        return false
                                    }
                                })

                                if (equip_executa_selecionado.length){
                                    objSelected['equipamento_executa'] = { label: equip_executa_selecionado[0].equipamento__imei, value: equip_executa_selecionado[0].equipamento__imei }
                                }

                                let equip_recebe_selecionado = equipamento_recebe.data.results.filter((equip) => {
                                    if (equip.equipamento__imei === detalhes['equipamento_recebe']) {
                                        return true
                                    } else {
                                        return false
                                    }
                                })
                                
                                if(equip_recebe_selecionado.length){
                                    objSelected['equipamento_recebe'] = { label: equip_recebe_selecionado[0].equipamento__imei, value: equip_recebe_selecionado[0].equipamento__imei }
                                }

                                let condicoesSelected = { campo: {}, condicao: {} }

                                if (condicoes) {
                                    condicoes.forEach((condicao, index) => {
                                        let campo_selecionado = entradasCampos.filter((campo) => {
                                            console.log(campo, condicao)
                                            if (campo.value === condicao.campo) {
                                                return true
                                            } else {
                                                return false
                                            }
                                        })
                                        let operador_selecionado = this.state.operadores.filter((operador) => {
                                            if (operador.value === condicao.condicao) {
                                                return true
                                            } else {
                                                return false
                                            }
                                        })
                                        //console.log(campo_selecionado)
                                        condicoesSelected['campo'][index] = campo_selecionado[0]
                                        condicoesSelected['condicao'][index] = operador_selecionado[0]
                                    })
                                } else {
                                    condicoes = []
                                }
                                ////////////////////////////////////////////
                                //console.log(condicoesSelected)
                                //SELECIONA TIPO DO PERIODO
                                let pre_definido = ""
                                let dias_personalizados = false
                                if (JSON.stringify(detalhes.dias_da_semana) === JSON.stringify({ "dom": true, "seg": true, "ter": true, "qua": true, "qui": true, "sex": true, "sab": true })) {
                                    pre_definido = "todos_os_dias"
                                } else if (JSON.stringify(detalhes.dias_da_semana) === JSON.stringify({ "dom": true, "seg": false, "ter": false, "qua": false, "qui": false, "sex": false, "sab": true })) {
                                    pre_definido = "finais_semana"
                                } else if (JSON.stringify(detalhes.dias_da_semana) === JSON.stringify({ "dom": false, "seg": true, "ter": true, "qua": true, "qui": true, "sex": true, "sab": false })) {
                                    pre_definido = "seg_sex"
                                } else if (JSON.stringify(detalhes.dias_da_semana) === JSON.stringify({ "dom": false, "seg": true, "ter": true, "qua": true, "qui": true, "sex": true, "sab": true })) {
                                    pre_definido = "seg_sab"
                                } else {
                                    dias_personalizados = true
                                }
                                ////////////////////////////////////////////
                                this.setState({ optionSelected: objSelected, dias_pre_definido_cond: pre_definido, condicao_dias_personalizados: dias_personalizados, condicoesSelected: condicoesSelected, detalhes: detalhes, condicoes: condicoes, condicoes_automacoes: condicoes_automacoes, periodos: periodos, campos: entradasCampos, equipamento_executa: equipamentos_executa, equipamento_recebe: equipamentos_recebe });
                                this.getRelays()
                            })

                    }));
            })
    }

    handleChange = (e) => {
        var obj = this.state.detalhes

        if (e.target.type === "checkbox") {
            obj[e.target.id] = (e.target.checked === true) ? true : false
        } else {
            obj[e.target.id] = e.target.value
        }
        document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""
        let { erros } = this.state
        erros = erros.filter(err => { return err !== 'descricao' })
        this.setState({ "detalhes": obj, erros: erros })
    }

    saveAction = (novo_cadastro) => {

        let userInfo = auth.getUserInfo();
        let resource = "automacao"
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/';
            verb = 'post'
        }

        var bodyFormData = new FormData();

        for (var key in this.state.detalhes) {
            if (key === "dias_da_semana") {
                bodyFormData.append(key, JSON.stringify(this.state.detalhes[key]));
            } else {
                bodyFormData.append(key, this.state.detalhes[key]);
            }
        }

        if (this.state.periodos.length > 0) {
            bodyFormData.append('periodo', JSON.stringify(this.state.periodos));
        } else {
            bodyFormData.append('periodo', false);
        }

        if (this.state.condicoes.length > 0) {
            bodyFormData.append('condicao', JSON.stringify(this.state.condicoes));
        } else {
            bodyFormData.append('condicao', false);
        }

        let condicoes_automacoes = []

        for (let index = 0; index < this.state.condicoes_automacoes.length; index++) {
            const element = this.state.condicoes_automacoes[index];
            condicoes_automacoes.push({monitorado: element['monitorado'], equipamento: element['equipamento'], automacao: element['automacao'], condicao: element['condicao'], valor: element['valor'], tipo: element['tipo'], execucoes_limite: element['execucoes_limite']})
        }

        if (this.state.condicoes.length > 0) {
            bodyFormData.append('condicao_automacao', JSON.stringify(condicoes_automacoes));
        } else {
            bodyFormData.append('condicao_automacao', false);
        }

        this.setState({ cooldown: true })

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (novo_cadastro === true) {
                    window.location.reload()
                } else {
                    const aviso = {
                        show: true, message: `Automação ${verb === 'patch' ? 'editada' : 'criada'} com sucesso.`,
                        args: { redirect: true, path: "/equipamento/" + this.state.equipamento_selecionado.value + "/status/" + this.props.location.state.equipamentoId}
                    }
                    this.setState({ aviso })
                }
            })
            .catch(error => {
                console.log(error)
                let erros = error.response.data
                let erroState = []
                for (let i = 0; i < erros.length; i++)
                    erroState.push(erros[i][0])

                this.setState({ erros: erroState })
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span)
                            span.innerHTML = erros[i][1]
                    }
                }
            })

        this.setState({ cooldown: false })

    }

    handleChangeSelect = (selectedOption, field) => {
        var obj = this.state.detalhes
        var obj_selected = this.state.optionSelected
        obj[field] = selectedOption.value
        obj_selected[field] = selectedOption
        if (document.querySelector(`#validacao-${field}`))
            document.querySelector(`#validacao-${field}`).innerHTML = ""
        this.setState({ optionSelected: obj_selected, "detalhes": obj });
        this.getRelays()
    }

    handleChangeRele = (selectedOption, field) => {
        var obj = this.state.detalhes
        var obj_selected = this.state.optionSelected
        obj[field] = String(selectedOption.value)
        obj_selected[field] = selectedOption
        this.setState({ optionSelected: obj_selected, "detalhes": obj });
        console.log(this.state)
    }

    handleChangeSelectEquipamentoBuscaCondicoes = (selectedOption, field) => {
        var obj = this.state.detalhes
        var obj_selected = this.state.optionSelected
        obj[field] = selectedOption.value
        obj_selected[field] = selectedOption

        this.setState({ loading: true });
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        let userInfo = auth.getUserInfo();

        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/buscar-entradas-hidrohub/' + selectedOption.value + '/', config_me)
            .then((response) => {
                let entradasCampos = [
                    { label: "Horímetro Virtual", value: "horimetro_virtual", dataType: "text" },
                    { label: "Consumo Diário", value: "consumo_diario", dataType: "text" },
                ]
                if (response.status === 200) {
                    response.data.entrada4a20.forEach((value) => {
                        entradasCampos.push({ label: "#" + value.canal + ' ' + value.descricao, value: 'entrada4a20_' + value.canal, dataType: "text" })
                    })
                    response.data.entradalogica.forEach((value) => {
                        if (value.tipo_entrada_logica_id === 3) {
                            entradasCampos.push({ label: "#" + value.canal + ' ' + value.tipo_entrada_logica__descricao, value: 'status_' + value.canal, dataType: "text" })
                        } else {
                            entradasCampos.push({ label: "#" + value.canal + ' ' + value.tipo_entrada_logica__descricao, value: 'entradalogica_' + value.canal, dataType: "text" })
                            if (value.tipo_entrada_logica_id === 1)
                                entradasCampos.push({ label: "#" + value.canal + ' ' + 'Vazão', value: 'ct_pulso_' + value.canal, dataType: "text" })
                        }
                    })
                    response.data.outros_campos.forEach((value) => {
                        entradasCampos.push({ label: value.label, value: value.value })
                    })
                }
                this.setState({ optionSelected: obj_selected, "detalhes": obj, campos: entradasCampos });
            })
    }

    handleChangeSelectExecuta = (selectedOption, field) => {
        var obj = this.state.detalhes
        var obj_selected = this.state.optionSelected
        obj[field] = selectedOption.value
        obj_selected[field] = selectedOption


        this.setState({ loading: true });
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        let userInfo = auth.getUserInfo();

        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/item/?page=0&pageSize=10&filter=' + encodeURI(JSON.stringify([{ "id": "monitorado_id", "value": selectedOption.value, "type": "int" }])), config_me)
            .then((response) => {
                let equipamentos_executa = []
                if (response.status === 200) {
                    response.data.results.forEach((value) => {
                        if (equipamentos_executa.filter((ee) => ee.value === value.equipamento__imei ).length === 0)
                            equipamentos_executa.push({ label: value.equipamento__imei, value: value.equipamento__imei })
                    })
                }
                this.setState({ optionSelected: obj_selected, "detalhes": obj, equipamento_executa: equipamentos_executa });
            })
    }
    handleChangeSelectRecebe = (selectedOption, field) => {
        var obj = this.state.detalhes
        var obj_selected = this.state.optionSelected
        obj[field] = selectedOption.value
        obj_selected[field] = selectedOption


        this.setState({ loading: true });
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        let userInfo = auth.getUserInfo();

        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/item/?page=0&pageSize=10&filter=' + encodeURI(JSON.stringify([{ "id": "monitorado_id", "value": selectedOption.value, "type": "int" }])), config_me)
            .then((response) => {
                let equipamentos_executa = []
                if (response.status === 200) {
                    response.data.results.forEach((value) => {
                        if (equipamentos_executa.filter((ee) => ee.value === value.equipamento__imei ).length === 0)
                            equipamentos_executa.push({ label: value.equipamento__imei, value: value.equipamento__imei })
                    })
                }
                this.setState({ optionSelected: obj_selected, "detalhes": obj, equipamento_recebe: equipamentos_executa });
            })
    }

    handleGetEquipamentos = (selectedOption, index) => {

        let condicoes_automacoes = this.state.condicoes_automacoes
        let condicao_automacao = condicoes_automacoes[index]

        condicao_automacao['equipamentos'] = []
        condicao_automacao['automacoes'] = []

        condicoes_automacoes[index] = condicao_automacao

        this.setState({condicoes_automacoes: condicoes_automacoes});

        this.setState({ loading: true });
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        let userInfo = auth.getUserInfo();

        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/item/?page=0&pageSize=10&filter=' + encodeURI(JSON.stringify([{ "id": "monitorado_id", "value": selectedOption.value, "type": "int" }])), config_me)
            .then((response) => {
                let equipamentos_automacao = []
                if (response.status === 200) {
                    response.data.results.forEach((value) => {
                        if (equipamentos_automacao.filter((ee) => ee.value === value.equipamento__imei ).length === 0)
                            equipamentos_automacao.push({ label: value.equipamento__imei, value: value.equipamento__imei })
                    })
                    condicao_automacao['equipamentos'] = equipamentos_automacao
                }
                condicoes_automacoes[index] = condicao_automacao
                this.setState({condicoes_automacoes: condicoes_automacoes });
            })
    }

    handleGetEquipamentoAutomacoes = (selectedOption, index) => {

        let condicoes_automacoes = this.state.condicoes_automacoes
        let condicao_automacao = condicoes_automacoes[index]

        condicao_automacao['automacoes'] = []

        condicoes_automacoes[index] = condicao_automacao

        this.setState({condicoes_automacoes: condicoes_automacoes });

        this.setState({ loading: true });
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        let userInfo = auth.getUserInfo();

        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/automacao/?page=0&pageSize=10&filter=' + encodeURI(JSON.stringify([{ "id": "equipamento_executa__imei", "value": selectedOption.value, "type": "int" }])), config_me)
            .then((response) => {
                let equipamentos_automacao = []
                if (response.status === 200) {
                    response.data.results.forEach((value) => {
                        if (equipamentos_automacao.filter((ee) => ee.id === value.id ).length === 0)
                            equipamentos_automacao.push({ label: value.descricao, value: value.id })
                    })
                    condicao_automacao['automacoes'] = equipamentos_automacao
                }
                condicoes_automacoes[index] = condicao_automacao
                this.setState({condicoes_automacoes: condicoes_automacoes });
            })
    }

    handleChangeCondicao = (selectedOption, campo, index) => {
        let campo_tipo = selectedOption.value.split('_')
        let condicoes = this.state.condicoes
        let condicoesSelected = this.state.condicoesSelected
        let condicao = condicoes[index]

        if (campo_tipo[0] === 'status') {

            let force_operador = { "label": "Igual (=)", "value": "igual" }
            condicao[campo] = selectedOption.value
            condicoesSelected[campo][index] = selectedOption

            condicao['condicao'] = force_operador.value
            condicoes[index] = condicao
            condicoesSelected['condicao'][index] = force_operador

        } else {

            condicao[campo] = selectedOption.value
            condicoes[index] = condicao
            condicoesSelected[campo][index] = selectedOption
        }

        this.setState({ condicoes: condicoes, condicoesSelected: condicoesSelected })

    }

    handleChangeCondicaoAutomation = (selectedOption, campo, index) => {
        //let campo_tipo = selectedOption.value.split('_')
        let condicoes_automacoes = this.state.condicoes_automacoes
        let condicao_automacao = condicoes_automacoes[index]

        condicao_automacao[campo] = selectedOption.value
        condicoes_automacoes[index] = condicao_automacao

        this.setState({ condicoes_automacoes: condicoes_automacoes })

    }

    handleChangeCondicaoValue = (e, index) => {
        let condicoes = this.state.condicoes
        let condicao = condicoes[index]
        condicao[e.target.id] = e.target.value
        condicoes[index] = condicao
        this.setState({ condicoes: condicoes })
    }

    formatar = (e, index) => {
        let v = e.target.value.replace(/\D/g, '')
        v = (v / 100).toFixed(2) + ''
        v = v.replace(" ", ",")
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2,$3,")
        v = v.replace(/(\d)(\d{3}),/g, "$1.$2,")
        let condicoes = this.state.condicoes
        let condicao = condicoes[index]
        condicao[e.target.id] = v
        condicoes[index] = condicao
        this.setState({ condicoes: condicoes })
    }

    handleChangePeriodoValue = (e, index, campo) => {
        let periodos = this.state.periodos
        let periodo = periodos[index]
        periodo[campo] = e.format('HH:mm:ss')
        periodos[index] = periodo
        this.setState({ periodos: periodos })
    }

    createNewCondition = () => {
        let condicoes = this.state.condicoes
        condicoes.push({ campo: "", condicao: "", valor: "" })
        document.querySelector('#validacao-condicao').innerHTML = ""
        let { erros } = this.state
        erros = erros.filter(err => { return err !== 'condicao' })
        this.setState({ condicoes, erros })
    }

    createNewConditionAutomation = () => {
        let condicoes_automacoes = this.state.condicoes_automacoes
        condicoes_automacoes.push({ monitorado: "", equipamento: "", automacao: "", condicao: "igual", valor: "1", tipo: "1", execucoes_limite: "1" , equipamentos: [], automacoes: []})
        document.querySelector('#validacao-condicao-automation').innerHTML = ""
        let { erros } = this.state
        erros = erros.filter(err => { return err !== 'condicao' })
        this.setState({ condicoes_automacoes, erros })
    }

    removeCondition = (index) => {
        let condicoes = this.state.condicoes
        condicoes.splice(index, 1)
        this.setState({ condicoes: condicoes })
    }

    removeConditionAutomation = (index) => {
        let condicoes_automacoes = this.state.condicoes_automacoes
        condicoes_automacoes.splice(index, 1)
        this.setState({ condicoes_automacoes: condicoes_automacoes })
    }

    createNewPeriodo = () => {
        let periodos = this.state.periodos
        periodos.push({ horainicio: "00:00", horafim: "00:00" })
        let { erros } = this.state
        erros = erros.filter(err => { return err !== 'periodo' })
        this.setState({ periodos, erros })
        if (document.querySelector('#validacao-periodo'))
            document.querySelector('#validacao-periodo').innerHTML = ""
    }

    removePeriodo = (index) => {
        let periodos = this.state.periodos
        periodos.splice(index, 1)
        this.setState({ periodos: periodos })
    }

    selecionaDiasSemanaPreDefinido = (condicao) => {
        let detalhes = this.state.detalhes
        switch (condicao) {
            case 'todos_os_dias':
                detalhes.dias_da_semana = { "dom": true, "seg": true, "ter": true, "qua": true, "qui": true, "sex": true, "sab": true }
                break;
            case 'finais_semana':
                detalhes.dias_da_semana = { "dom": true, "seg": false, "ter": false, "qua": false, "qui": false, "sex": false, "sab": true }
                break;
            case 'seg_sex':
                detalhes.dias_da_semana = { "dom": false, "seg": true, "ter": true, "qua": true, "qui": true, "sex": true, "sab": false }
                break;
            case 'seg_sab':
                detalhes.dias_da_semana = { "dom": false, "seg": true, "ter": true, "qua": true, "qui": true, "sex": true, "sab": true }
                break;
            default:
                break;
        }
        this.setState({ detalhes: detalhes, dias_pre_definido_cond: condicao, condicao_dias_personalizados: false })
    }

    habilitaDiasPersonalizados = () => {
        let detalhes = this.state.detalhes
        detalhes.dias_da_semana = { "dom": false, "seg": false, "ter": false, "qua": false, "qui": false, "sex": false, "sab": false }

        this.setState({ detalhes: detalhes, dias_pre_definido_cond: "", condicao_dias_personalizados: !this.state.condicao_dias_personalizados })
    }

    diasSemanaPersonalizado = (dia) => {
        let diaSelecionado = this.state.detalhes.dias_da_semana[dia]
        let detalhes = this.state.detalhes

        diaSelecionado = !diaSelecionado
        detalhes.dias_da_semana[dia] = diaSelecionado

        this.setState({ detalhes: detalhes })
    }

    geraPeriodos = () => {
        if (this.state.periodos.length > 0) {
            return this.state.periodos.map((condicao, index) => {
                return (
                    <div key={index} className="row">
                        <div className="col-md-2">
                            <label htmlFor="horainicio">Hora Início</label>
                            <TimePicker value={moment(this.state.periodos[index].horainicio, "HH:mm:ss")} showSecond={false} onChange={(e) => this.handleChangePeriodoValue(e, index, 'horainicio')} />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="horafim">Hora Fim: </label>
                            <TimePicker value={moment(this.state.periodos[index].horafim, "HH:mm:ss")} showSecond={false} onChange={(e) => this.handleChangePeriodoValue(e, index, 'horafim')} />
                        </div>
                        <div className="col-md-2">
                            <label >&nbsp;</label>
                            <button onClick={() => this.removePeriodo(index)} className="hdv-btn-forms hdv-btn-red hdv-automacao-delete-button"><i className="fa fa-minus-circle fa-2x"></i></button>
                        </div>
                    </div>
                )
            })
        } else return false
    }

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    getRelays = () => {

        var obj = this.state.detalhes

        let data = new FormData()
        data.append("id_dispositivo", obj.equipamento_recebe)

        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        let userInfo = auth.getUserInfo();

        axios.post(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reporte-ultimo-evento/', data, config_me)
            .then((response) => {
                let total_relays = 0
                if (response.status === 200) {
                    if(response && response.data && response.data.rele){
                        if (Array.isArray(response.data.rele)){
                            total_relays = response.data.rele.length
                        }else{
                            total_relays = 1
                        }
                    }
                }
                this.setState({ total_relays: total_relays });
            })
    }

    render() {
        const { acao,
            monitorado_executa,
            monitorado_recebe,
            equipamento_executa,
            equipamento_recebe } = this.state.optionSelected;

        console.log(this.state.condicoes_automacoes)

        const reles = []
        const { erros } = this.state

        for (let i = 1; i <= this.state.total_relays; i++) reles.push({ label: `Relé ${i}`, value: `${i}` })

        if (this.state.redirect === true) {
            return <Redirect to={{
                pathname: this.state.path,
                state: {
                    tabIndex: 1
                }
            }} />
        }

        console.log(this.state.detalhes)
        return (
            <div>
                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.setState(this.state.aviso.args)}
                    open={this.state.aviso.show}
                    continue={Boolean(this.props.match.params.id) ? () => {
                        let { aviso } = this.state
                        aviso = { show: false, args: null, message: '' }
                        this.setState({ aviso })
                    }
                        : null}
                />
                {this.props.match.params.id ? <h4>Editar Regra de Automação</h4> : <h4>Nova Regra de Automação</h4>}
                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Detalhes <b style={{ color: 'tomato', display: erros.includes('descricao') ? 'inherit' : 'none' }}>&times;</b> </Tab>
                        <Tab>Condições <b style={{ color: 'tomato', display: erros.includes('condicao') ? 'inherit' : 'none' }}>&times;</b></Tab>
                        <Tab>Intervalos <b style={{ color: 'tomato', display: erros.includes('periodo') ? 'inherit' : 'none' }}>&times;</b></Tab>
                    </TabList>

                    <TabPanel>
                        <div className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="descricao">Ativo: </label>
                                    <input type="checkbox" checked={(this.state.detalhes.ativo == "1") ? true : false} onChange={(e) => this.handleChange({target: {value: (e.target.checked) ? "1" : "0", name: "ativo", id: "ativo"}})} className="checkbox" />
                                    <span id="validacao-ativo"></span>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="descricao">Descrição: </label>
                                    <input type="text" name="descricao" id="descricao" value={this.state.detalhes.descricao} onChange={this.handleChange} />
                                    <span id="validacao-descricao"></span>
                                </div>



                                <div className="col-md-6">
                                    <label htmlFor="nome">Selecione o monitorado que irá acionar a ação: </label>
                                    <Select
                                        value={monitorado_executa}
                                        onChange={(e) => this.handleChangeSelectExecuta(e, 'monitorado_executa')}
                                        options={this.state.monitorados}
                                    />
                                    <span id="validacao-monitorado_executa">{!monitorado_executa ? "Este campo não pode estar vazio" : ""}</span>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="nome">Qual equipamento deseja usar: </label>
                                    <Select
                                        value={equipamento_executa}
                                        onChange={(e) => this.handleChangeSelectEquipamentoBuscaCondicoes(e, 'equipamento_executa')}
                                        options={this.state.equipamento_executa}
                                    />
                                    <span id="validacao-equipamento_executa">{!equipamento_executa ? "Este campo não pode estar vazio" : ""}</span>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="nome">Selecione o monitorado que receberá a ação: </label>
                                    <Select
                                        value={monitorado_recebe}
                                        onChange={(e) => this.handleChangeSelectRecebe(e, 'monitorado_recebe')}
                                        options={this.state.monitorados}
                                    />
                                    <span id="validacao-monitorado_recebe">{!monitorado_recebe ? "Este campo não pode estar vazio" : ""}</span>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="nome">Qual equipamento deseja usar: </label>
                                    <Select
                                        value={equipamento_recebe}
                                        onChange={(e) => this.handleChangeSelect(e, 'equipamento_recebe')}
                                        options={this.state.equipamento_recebe}
                                    />
                                    <span id="validacao-equipamento_recebe">{!equipamento_recebe ? "Este campo não pode estar vazio" : ""}</span>
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="nome">Selecione o relê </label>
                                    <Select
                                        value={this.state.detalhes.rele ?
                                            { label: `Relê ${this.state.detalhes.rele}`, value: this.state.detalhes.rele } :
                                            { label: "Relê 1", value: "1" }}
                                        onChange={(e) => this.handleChangeRele(e, 'rele')}
                                        options={reles}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="nome">Selecione a ação da regra: </label>
                                    <Select
                                        value={acao}
                                        onChange={(e) => this.handleChangeSelect(e, 'acao')}
                                        options={this.state.acoes}
                                    />
                                    <span id="validacao-acao"></span>
                                </div>

                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="hdv-form-default">
                            <fieldset style={{border: "1px solid #aaa", padding: "0px 15px 15px 15px"}}>
                                <legend style={{fontSize: "small", padding: "0px 15px"}}>Campos do Equipamento</legend>
                                {
                                    this.state.condicoes.map((condicao, index) => {
                                        return (
                                            <div style={{backgroundColor: "#e9ecef", padding: "1px 15px 15px 15px", marginBottom: "10px", borderRadius: "5px", marginLeft: "5px", marginRight: "5px"}} key={index} className="row">
                                                <div className="col-md-4">
                                                    <label htmlFor="campo">Quando o Campo: </label>
                                                    <Select
                                                        value={this.state.condicoesSelected['campo'][index]}
                                                        onChange={(e) => this.handleChangeCondicao(e, 'campo', index)}
                                                        options={this.state.campos}
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="condicao">For: </label>
                                                    {console.log(this.state.condicoesSelected)}
                                                    <Select
                                                        value={this.state.condicoesSelected['condicao'][index]}
                                                        onChange={(e) => this.handleChangeCondicao(e, 'condicao', index)}
                                                        options={this.state.operadores}
                                                        isDisabled={
                                                            (this.state.condicoesSelected['campo'][index]) ?
                                                                (this.state.condicoesSelected['campo'][index].value.split('_')[0] === 'status') ? true : false
                                                                :
                                                                false
                                                        }
                                                    />
                                                </div>
                                                {
                                                    (this.state.condicoesSelected['campo'][index]) ?
                                                        (this.state.condicoesSelected['campo'][index].value.split('_')[0] === 'status') ?
                                                            <div className="col-md-2">
                                                                <label htmlFor="condicao">Status: </label>
                                                                <div>
                                                                    <input type="radio" id="valor" name="valor" checked={(this.state.condicoes[index].valor !== "0" ? true : false)} onChange={(e) => this.handleChangeCondicaoValue(e, index)} value="1" />
                                                                    <span>Ligado</span>
                                                                </div>
                                                                <div>
                                                                    <input type="radio" id="valor" name="valor" checked={(this.state.condicoes[index].valor === "0" ? true : false)} onChange={(e) => this.handleChangeCondicaoValue(e, index)} value="0" />
                                                                    <span>Desligado</span>
                                                                </div>
                                                            </div>
                                                            : <div className="col-md-2">
                                                                <label htmlFor="valor">Valor: </label>
                                                                <input type="text" name="valor" id="valor" value={this.state.condicoes[index].valor} onChange={(e) => this.handleChangeCondicaoValue(e, index)} />
                                                            </div>
                                                        : <div className="col-md-2">
                                                            <label htmlFor="valor">Valor: </label>
                                                            <input
                                                                type="text"
                                                                name="valor"
                                                                id="valor"
                                                                value={this.state.condicoes[index].valor}
                                                                onChange={(e) => this.formatar(e, index)}
                                                            />
                                                        </div>
                                                }
                                                {/*<div className={(index === 0) ? "hdv-noshow-item" : "col-md-2" }>*/}
                                                <div className={"col-md-2"}>
                                                    <label >&nbsp;</label>
                                                    <button onClick={() => this.removeCondition(index)} className="hdv-btn-forms hdv-btn-red hdv-automacao-delete-button"><i className="fa fa-minus-circle fa-2x"></i></button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="row">
                                    <div className="col-md-5">
                                        <button onClick={this.createNewCondition} className="hdv-btn-forms hdv-btn-blue hdv-automacao-add-new-button d-flex align-items-center">
                                            <i className="fa fa-plus-circle fa-2x"></i> &nbsp; Nova condição
                                        </button>
                                    </div>
                                </div>
                                <span id="validacao-condicao"></span>
                            </fieldset>
                            <fieldset style={{border: "1px solid #aaa", padding: "0px 15px 15px 15px", marginTop: "20px"}}>
                                <legend style={{fontSize: "small", padding: "0px 15px"}}>Outras Automações</legend>
                                {
                                    this.state.condicoes_automacoes.map((condicao, index) => {
                                        return (
                                            <div style={{backgroundColor: "#e9ecef", padding: "1px 15px 15px 15px", marginBottom: "10px", borderRadius: "5px", marginLeft: "5px", marginRight: "5px"}} key={index} className="row">
                                                <div className="col-md-4">
                                                    <label htmlFor="monitorado">Monitorado: </label>
                                                    <Select
                                                        value={this.state.monitorados.filter((monitorado) => monitorado.value == parseInt(condicao['monitorado'] ))[0]}
                                                        onChange={(e) => {this.handleChangeCondicaoAutomation(e, 'monitorado', index); this.handleGetEquipamentos(e, index)}}
                                                        options={this.state.monitorados}
                                                        noOptionsMessage={() => "Nenhum monitorado encontrado"}
                                                        placeholder={""}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="equipamento">Equipamento:</label>
                                                    <Select
                                                        value={(condicao.equipamentos.filter((equipamento) => equipamento.value == condicao['equipamento'])[0]) ? condicao.equipamentos.filter((equipamento) => equipamento.value == condicao['equipamento'])[0] : {"value": null, "label": null}}
                                                        onChange={(e) => {this.handleChangeCondicaoAutomation(e, 'equipamento', index); this.handleGetEquipamentoAutomacoes(e, index)}}
                                                        options={condicao.equipamentos}
                                                        noOptionsMessage={() => "Nenhum equipamento encontrado"}
                                                        placeholder={""}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="automacao">Automação: </label>
                                                    <Select
                                                        value={(condicao.automacoes.filter((automacao) => automacao.value == parseInt(condicao['automacao'] ))[0]) ? condicao.automacoes.filter((automacao) => automacao.value == parseInt(condicao['automacao'] ))[0] : {"value": null, "label": null}}
                                                        onChange={(e) => this.handleChangeCondicaoAutomation(e, 'automacao', index)}
                                                        options={condicao.automacoes}
                                                        noOptionsMessage={() => "Nenhuma automação encontrada"}
                                                        placeholder={""}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="automacao">Tipo: </label>
                                                    <div>
                                                        <input type="radio" checked={(condicao['tipo'] !== "0" ? true : false)} onChange={(e) => this.handleChangeCondicaoAutomation({value: e.target.value}, "tipo", index)} value="1" />
                                                        <span>Status atual da Automação</span>
                                                    </div>
                                                    <div>
                                                        <input type="radio" checked={(condicao['tipo'] === "0" ? true : false)} onChange={(e) => this.handleChangeCondicaoAutomation({value: e.target.value}, "tipo", index)} value="0" />
                                                        <span>Quantidade de Execuções no dia</span>
                                                    </div>
                                                </div>
                                                <div style={(condicao['tipo'] !== "0" ? {"display": "block"} : {"display": "none"})} className="col-md-2">
                                                    <label htmlFor="condicao">Status: </label>
                                                    <div>
                                                        <input type="radio" checked={(condicao['valor'] !== "0" ? true : false)} onChange={(e) => this.handleChangeCondicaoAutomation({value: e.target.value}, "valor", index)} value="1" />
                                                        <span>Ativo</span>
                                                    </div>
                                                    <div>
                                                        <input type="radio" checked={(condicao['valor'] === "0" ? true : false)} onChange={(e) => this.handleChangeCondicaoAutomation({value: e.target.value}, "valor", index)} value="0" />
                                                        <span>Desativado</span>
                                                    </div>
                                                </div>
                                                <div  style={(condicao['tipo'] === "0" ? {"display": "block"} : {"display": "none"})} className="col-md-2">
                                                    <label htmlFor="condicao">Execuções: </label>
                                                    <input type="number" onChange={(e) => this.handleChangeCondicaoAutomation({value: e.target.value}, "execucoes_limite", index)} value={condicao['execucoes_limite']} />
                                                </div>
                                                {/*<div className={(index === 0) ? "hdv-noshow-item" : "col-md-2" }>*/}
                                                <div className={"col-md-1"}>
                                                    <label >&nbsp;</label>
                                                    <button onClick={() => this.removeConditionAutomation(index)} className="hdv-btn-forms hdv-btn-red hdv-automacao-delete-button"><i className="fa fa-minus-circle fa-2x"></i></button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="row">
                                    <div className="col-md-5">
                                        <button onClick={this.createNewConditionAutomation} className="hdv-btn-forms hdv-btn-blue hdv-automacao-add-new-button d-flex align-items-center">
                                            <i className="fa fa-plus-circle fa-2x"></i> &nbsp; Nova condição
                                        </button>
                                    </div>
                                </div>
                                <span id="validacao-condicao-automation"></span>
                            </fieldset>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <fieldset className="hdv-fieldset">
                            <legend className="hdv-legend">Dias Permitidos</legend>
                            <div className="hdv-form-default">
                                <div className="row">
                                    <div className="col-md-12">
                                        <label htmlFor="">Pré Definido</label>
                                        <input checked={(this.state.dias_pre_definido_cond === "todos_os_dias") ? true : false} onChange={() => this.selecionaDiasSemanaPreDefinido("todos_os_dias")} type="checkbox" /> Todos os dia
                                    </div>
                                    <div className="col-md-12">
                                        <input checked={(this.state.dias_pre_definido_cond === "finais_semana") ? true : false} onChange={() => this.selecionaDiasSemanaPreDefinido("finais_semana")} type="checkbox" /> Finais de Semana
                                    </div>
                                    <div className="col-md-12">
                                        <input checked={(this.state.dias_pre_definido_cond === "seg_sex") ? true : false} onChange={() => this.selecionaDiasSemanaPreDefinido("seg_sex")} type="checkbox" /> Segunda a Sexta
                                    </div>
                                    <div className="col-md-12">
                                        <input checked={(this.state.dias_pre_definido_cond === "seg_sab") ? true : false} onChange={() => this.selecionaDiasSemanaPreDefinido("seg_sab")} type="checkbox" /> Segunda a Sábado
                                    </div>
                                    <div className="col-md-12">&nbsp;</div>
                                    <div className="col-md-12">
                                        <input checked={this.state.condicao_dias_personalizados} onChange={this.habilitaDiasPersonalizados} type="checkbox" /> Personalizar os Dias
                                    </div>
                                    <div className={(this.state.condicao_dias_personalizados) ? "col-md-12" : "col-md-12 hdv-dias-semana-automacao"}>
                                        <label htmlFor="">Dias da Semana</label>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <input checked={this.state.detalhes.dias_da_semana.dom} onChange={() => this.diasSemanaPersonalizado('dom')} type="checkbox" /> Dom
                                            </div>
                                            <div className="col-md-1">
                                                <input checked={this.state.detalhes.dias_da_semana.seg} onChange={() => this.diasSemanaPersonalizado('seg')} type="checkbox" /> Seg
                                            </div>
                                            <div className="col-md-1">
                                                <input checked={this.state.detalhes.dias_da_semana.ter} onChange={() => this.diasSemanaPersonalizado('ter')} type="checkbox" /> Ter
                                            </div>
                                            <div className="col-md-1">
                                                <input checked={this.state.detalhes.dias_da_semana.qua} onChange={() => this.diasSemanaPersonalizado('qua')} type="checkbox" /> Qua
                                            </div>
                                            <div className="col-md-1">
                                                <input checked={this.state.detalhes.dias_da_semana.qui} onChange={() => this.diasSemanaPersonalizado('qui')} type="checkbox" /> Qui
                                            </div>
                                            <div className="col-md-1">
                                                <input checked={this.state.detalhes.dias_da_semana.sex} onChange={() => this.diasSemanaPersonalizado('sex')} type="checkbox" /> Sex
                                            </div>
                                            <div className="col-md-1">
                                                <input checked={this.state.detalhes.dias_da_semana.sab} onChange={() => this.diasSemanaPersonalizado('sab')} type="checkbox" /> Sáb
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className="hdv-fieldset">
                            <legend className="hdv-legend">Intervalo de Execução</legend>
                            <div className="hdv-form-default">
                                {this.geraPeriodos()}
                                <div className="row">
                                    <div className="col-md-5">
                                        <button onClick={this.createNewPeriodo} className="hdv-btn-forms hdv-btn-blue hdv-automacao-add-new-button d-flex align-items-center">
                                            <i className="fa fa-plus-circle fa-2x"></i> &nbsp; Novo intervalo
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <span id="validacao-periodo"></span>

                    </TabPanel>

                </Tabs>

                <div className="hdv-btn-group">
                    <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>Salvar</button>
                    <button style={(this.props.match.params.id) ? {display: "none"} : {backgroundColor: "#56C17C"}} 
                            onClick={() => this.saveAction(true)} 
                            className="hdv-btn-forms hdv-btn-geenlight" 
                            disabled={this.state.cooldown}>
                                Salvar e Cadastrar um Novo
                    </button>
                    <Link to={{
                        pathname: '/equipamento/' + this.state.equipamento_selecionado.value + '/status/' + this.props.location.state.equipamentoId,
                        state: {
                            tabIndex: 1
                        }
                    }}>
                        <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>

            </div>
        );
    }
}

export default Form;