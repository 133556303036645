import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom'
import validator from 'validator';
import "react-tabs/style/react-tabs.css";
import Select from 'react-select';
import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import Aviso from '../../components/Aviso'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class Form extends Component {

    state = {
        detalhes: { "razao_social": "", "nome_fantasia": "", "pessoa_tipo": "", "cpf_cnpj": "", "setor": "", "status": "ativo", "perfil_id": "", "email_administrador": "" },
        validacao: {
            "razao_social": { "validacoes": ["required"], "descricao": "Razão Social" },
            "nome_fantasia": { "validacoes": ["required"], "descricao": "Nome Fantasia" },
            "pessoa_tipo": { "validacoes": ["required"], "descricao": "Pessoa Tipo" },
            "cpf_cnpj": { "validacoes": ["required"], "descricao": "CPF / CNPJ" },
            "setor": { "validacoes": ["required"], "descricao": "Setor" },
            "status": { "validacoes": ["required"], "descricao": "Status" },
            "perfil_id": { "validacoes": ["required"], "descricao": "Perfil" },
            "email_administrador": { "validacoes": ["required", "email"], "descricao": "E-mail Administrador" }
        },
        enderecos: [],
        contatos: [],
        contato: { "contato": "", "telefone": "", "celular": "", "email": "" },
        endereco: { "descricao": "", "endereco": "", "bairro": "", "municipio": "", "estado": "", "cep": "" },
        id_editando_contato: false,
        id_editando_endereco: false,
        formerrors: [],
        dropdownOpen: false,
        selectedOption: null,
        aviso: { show: false, args: null, message: '' },
        cooldown: false,
        cancelModal: null,
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    componentWillMount = () => {
        this.getInitialData()
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }
    }

    getInitialData = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/perfil/', config_me))

        axios.all(requestArray)
            .then(axios.spread((perfis) => {
                let optionsPerfis = []
                if (perfis.data.length > 0) {
                    perfis.data.forEach((c) => {
                        optionsPerfis.push({ "value": c.id, "label": c.nome })
                    })
                }
                this.setState({ perfis: optionsPerfis });
            }));
    }

    getData = () => {
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        let requestArray = []

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/endereco/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/contato/', config_me))

        axios.all(requestArray)
            .then(axios.spread((detalhes, enderecos, contatos) => {
                delete detalhes.data.id
                if (enderecos.status === 204)
                    enderecos.data = []
                if (contatos.status === 204)
                    contatos.data = []
                this.setState({ detalhes: detalhes.data, enderecos: enderecos.data, contatos: contatos.data, selectedOption: { value: detalhes.data.perfil_id, label: detalhes.data.perfil__nome } });
            }));
    }

    handleChange = (e) => {
        var obj = this.state.detalhes
        obj[e.target.id] = e.target.value
        document.querySelector(`#validacao-${e.target.name}`).innerHTML = ''

        this.setState({ "detalhes": obj })
    }

    handleEnderecoChange = (e) => {
        var obj = this.state.endereco
        obj[e.target.id] = e.target.value
        if (document.querySelector(`#validacao-${e.target.name}`))
            document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""

        this.setState({ "endereco": obj })
    }

    handleContatoChange = (e) => {
        var obj = this.state.contato
        obj[e.target.id] = e.target.value
        this.setState({ "contato": obj })
        if (document.querySelector(`#validacao-${e.target.name}`))
            document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""
    }

    handleChangeSelect = (selectedOption, field) => {
        console.log(selectedOption, field)
        var obj = this.state.detalhes
        obj[field] = selectedOption.value
        if (document.querySelector(`#validacao-${field.slice(0, -3)}`))
            document.querySelector(`#validacao-${field.slice(0, -3)}`).innerHTML = ""

        this.setState({ selectedOption, "detalhes": obj });
    }

    addNovoEndereco = () => {
        if (this.props.match.params.id) {

            let userInfo = auth.getUserInfo();
            var bodyFormData = new FormData();
            let verb = ""
            let id_update = ""
            let urlAction = ""

            for (var key in this.state.endereco) {
                bodyFormData.append(key, this.state.endereco[key]);
            }

            if (Number.isInteger(this.state.id_editando_endereco) === true) {
                verb = "patch"
                id_update = this.state.enderecos[this.state.id_editando_endereco]['id']
                urlAction = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/endereco/' + id_update + '/'
            } else {
                verb = "post"
                urlAction = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/endereco/'
            }

            axios({
                method: verb,
                url: urlAction,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then((response) => {
                    let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                    axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/endereco/', config_me)
                        .then((resp) => {
                            let endereco = { "descricao": "", "endereco": "", "bairro": "", "municipio": "", "estado": "", "cep": "" }
                            this.setState({ "endereco": endereco, "enderecos": resp.data, "id_editando_endereco": false })
                        })
                })
                .catch((error) => {
                    console.log(error)
                    let erros = error.response.data
                    if (Array.isArray(erros)) {
                        for (let i = 0; i < erros.length; i++) {
                            let span = document.querySelector(`#validacao-${erros[i][0]}`)
                            if (span)
                                span.innerHTML = erros[i][1]
                        }
                    }
                })

        } else {
            let endereco = this.state.endereco
            let enderecos = this.state.enderecos
            let fields_errors = [];

            Object.keys(endereco).forEach((key) => {
                if (validator.isEmpty(endereco[key])) {
                    fields_errors.push(key)
                }
            })

            if (fields_errors.length === 0) {
                if (Number.isInteger(this.state.id_editando_endereco)) {
                    enderecos[this.state.id_editando_endereco] = endereco
                } else {
                    enderecos.push(endereco)
                }
                endereco = { "descricao": "", "endereco": "", "bairro": "", "municipio": "", "estado": "", "cep": "" }
                this.setState({ "enderecos": enderecos, "endereco": endereco, "id_editando_endereco": false, "formerrors": [] })
            } else {
                let formerror = []
                fields_errors.map((value, key) => {
                    let error = value
                    formerror.push(error)
                    return true
                })
                this.setState({ formerrors: formerror })
            }
        }
    }

    cancelEditEndereco = () => {
        let endereco = { "descricao": "", "endereco": "", "bairro": "", "municipio": "", "estado": "", "cep": "" }
        this.setState({ "endereco": endereco, "id_editando_endereco": false })
    }

    addNovoContato = () => {
        if (this.props.match.params.id) {

            let userInfo = auth.getUserInfo();
            let bodyFormData = new FormData();
            let verb = ""
            let id_update = ""
            let urlAction = ""

            for (var key in this.state.contato) {
                bodyFormData.append(key, this.state.contato[key]);
            }

            if (Number.isInteger(this.state.id_editando_contato) === true) {
                verb = "patch"
                id_update = this.state.contatos[this.state.id_editando_contato]['id']
                urlAction = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/contato/' + id_update + '/'
            } else {
                verb = "post"
                urlAction = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/contato/'
            }

            axios({
                method: verb,
                url: urlAction,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then((response) => {
                    let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                    axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/contato/', config_me)
                        .then((resp) => {
                            let contato = { "contato": "", "telefone": "", "celular": "", "email": "" }
                            this.setState({ "contato": contato, "contatos": resp.data, "id_editando_contato": false })
                        })
                })
                .catch((error) => {
                    console.log(error)
                    let erros = error.response.data
                    if (Array.isArray(erros)) {
                        for (let i = 0; i < erros.length; i++) {
                            let span = document.querySelector(`#validacao-${erros[i][0]}`)
                            if (span)
                                span.innerHTML = erros[i][1]
                        }
                    }
                })

        } else {
            let contato = this.state.contato
            let contatos = this.state.contatos
            let fields_errors = [];

            Object.keys(contato).forEach((key) => {
                if (validator.isEmpty(contato[key])) {
                    fields_errors.push(key)
                }
            })

            if (fields_errors.length === 0) {
                if (this.state.id_editando_contato !== false) {
                    contatos[this.state.id_editando_contato] = contato
                } else {
                    contatos.push(contato)
                }
                contato = { "contato": "", "telefone": "", "celular": "", "email": "" }
                this.setState({ "contatos": contatos, "contato": contato, "id_editando_contato": false, "formerrors": [] })
            } else {
                let formerror = []
                fields_errors.map((value, key) => {
                    let error = value
                    formerror.push(error)
                    return true
                })
                this.setState({ formerrors: formerror })
            }
        }
    }

    removeItem = (i, source) => {
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        if (this.state[source][i]['id']) {
            if (source === "enderecos") {
                axios.delete(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/endereco/' + this.state[source][i]['id'] + '/', config_me)
                    .then((response) => {
                        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/endereco/', config_me)
                            .then((resp) => {
                                let endereco = { "descricao": "", "endereco": "", "bairro": "", "municipio": "", "estado": "", "cep": "" }
                                this.setState({ "endereco": endereco, "enderecos": resp.data, "id_editando_endereco": false })
                            })
                    })
            } else {
                axios.delete(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/contato/' + this.state[source][i]['id'] + '/', config_me)
                    .then((response) => {
                        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/contato/', config_me)
                            .then((resp) => {
                                let contato = { "contato": "", "telefone": "", "celular": "", "email": "" }
                                this.setState({ "contato": contato, "contatos": resp.data, "id_editando_contato": false })
                            })
                    })
            }
        } else {
            let data = this.state[source];
            delete data[i];
            let newdata = {};
            newdata[source] = data;
            this.setState(newdata)
        }
    }

    cancelEditContato = () => {
        let contato = { "contato": "", "telefone": "", "celular": "", "email": "" }
        this.setState({ "contato": contato, "id_editando_contato": false })
    }

    checkErrorField = (field) => {
        return this.state.formerrors.includes(field) ? "hdv-form-error" : ""
    }

    createRows = (campoativo, source, campo_principal, campo_secundario) => {
        return this.state[source].map((campo, i) =>
            <tr key={i}>
                <td>
                    {campo[campo_principal]}
                </td>
                <td>
                    {campo[campo_secundario]} {campo['id']}
                </td>
                <td className="hdv-subtable-action-td">

                    <button disabled={(parseInt(this.state["id_editando_" + campoativo]) === i) ? true : false}
                        onClick={() => {
                            let data = this.state[source];
                            let newdata = {};
                            newdata[campoativo] = data[i];
                            newdata["id_editando_" + campoativo] = i;
                            this.setState(newdata)
                        }}
                        className="hdv-btn-forms hdv-btn-yellow">Editar</button>
                    <button disabled={(parseInt(this.state["id_editando_" + campoativo]) === i) ? true : false}
                        onClick={() => { this.removeItem(i, source) }}
                        className="hdv-btn-forms hdv-btn-red">Deletar</button>
                </td>
            </tr>
        );
    }

    openModal = () => {
        this.setState({cancelModal: true})
    }

    closeModal = () => {
        this.setState({cancelModal:false})
    }

    confirmModal = () => {
        window.location = "/cliente"
    }

    saveAction = (novo_cadastro) => {
        this.setState({ cooldown: true })
        if (this.props.match.params.id) {
            let userInfo = auth.getUserInfo();
            let requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/' + this.props.match.params.id + '/';
            var bodyFormData = new FormData();

            for (var key in this.state.detalhes) {
                bodyFormData.append(key, this.state.detalhes[key]);
            }

            if (this.state.detalhes.nome_fantasia.length > 50) {
                if (document.querySelector("#validacao-nome_fantasia"))
                    document.querySelector("#validacao-nome_fantasia").innerHTML = "Este campo não pode ter mais que 50 caracteres."

                setTimeout(() => this.setState({ cooldown: false }), 1000)
                return
            }


            axios({
                method: 'patch',
                url: requestURL,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then(() => {
                    if (novo_cadastro === true) {
                        if (this.props.match.params.id) {
                            const aviso = {     
                                show: true, message: 'Cliente editado com sucesso', args: { redirect: true, path: "/cliente" } 
                            }
                            this.setState({ aviso })
                        } else {
                            window.location.reload()
                        }
                    } else {
                        alert("teste else")
                        const aviso = { 
                            show: true, message: 'Cliente editado com sucesso', args: { redirect: true, path: "/cliente" } 
                        }
                        this.setState({ aviso })
                    }
                })
                .catch((error) => {
                    console.log(error)
                    let erros = error.response.data
                    if (Array.isArray(erros)) {
                        for (let i = 0; i < erros.length; i++) {
                            let span = document.querySelector(`#validacao-${erros[i][0]}`)
                            if (span)
                                span.innerHTML = erros[i][1]
                        }
                    }
                })
        } else {
            let userInfo = auth.getUserInfo();
            let requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/';
            let bodyFormData = new FormData();

            if (this.state.detalhes.nome_fantasia.length > 50) {
                if (document.querySelector("#validacao-nome_fantasia"))
                    document.querySelector("#validacao-nome_fantasia").innerHTML = "Este campo não pode ter mais que 50 caracteres."

                setTimeout(() => this.setState({ cooldown: false }), 1000)
                return
            }

            for (var key_detail in this.state.detalhes) {
                bodyFormData.append(key_detail, this.state.detalhes[key_detail]);
            }

            axios({
                method: 'post',
                url: requestURL,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then((response) => {
                    const aviso = { 
                        show: true, message: 'Cliente criado com sucesso', args: { redirect: true, path: "/cliente/" + response.data.id + "/editar/" } 
                    }
                    this.setState({ aviso })

                })
                .catch((error) => {
                    console.log(error)
                    let erros = error.response.data
                    if (Array.isArray(erros)) {
                        for (let i = 0; i < erros.length; i++) {
                            let span = document.querySelector(`#validacao-${erros[i][0]}`)
                            if (span)
                                span.innerHTML = erros[i][1]
                        }
                    }
                })
        }
    }

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    render() {
        const { selectedOption } = this.state;
        
        if (this.state.redirect === true) {
            return <Redirect to={{
                pathname: '/redirect',
                state: { pathto: this.state.path }
            }}
            />
        }
        
        return (
            <div> 
                <Modal isOpen={this.state.cancelModal}>
                    <ModalHeader>
                        Cancelar
                    </ModalHeader>
                    <ModalBody>
                        {this.props.match.params.id ? <h6>Certeza que deseja cancelar a edição do cliente?</h6> : <h6>Certeza que deseja cancelar a criação do cliente?</h6>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>
                            Não
                        </Button>
                        <Button color="danger" onClick={this.confirmModal}>
                            Sim
                        </Button>
                    </ModalFooter>
                </Modal>

                {this.props.match.params.id ? <h4>Editar Cliente</h4> : <h4>Novo Cliente</h4>}
                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.setState(this.state.aviso.args)}
                    open={this.state.aviso.show}
                />
                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Detalhes do cliente</Tab>
                        <Tab disabled={(this.props.match.params.id) ? false : true} >Endereços</Tab>
                        <Tab disabled={(this.props.match.params.id) ? false : true} >Contatos</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <label htmlFor="razao_social" className="required">Razão Social</label>
                            <input type="text" name="razao_social" id="razao_social" value={this.state.detalhes.razao_social} onChange={this.handleChange} />
                            <span id="validacao-razao_social"></span>
                            <label htmlFor="nome_fantasia" className="required">Nome Fantasia</label>
                            <input type="text" name="nome_fantasia" id="nome_fantasia" value={this.state.detalhes.nome_fantasia} onChange={this.handleChange} />
                            <span id="validacao-nome_fantasia"></span>
                            <label htmlFor="pessoa_tipo" className="required">Pessoa Tipo</label>
                            <input type="radio" name="pessoa_tipo" id="pessoa_tipo" checked={this.state.detalhes.pessoa_tipo === 'fisica'} value="fisica" onChange={this.handleChange} /> Física
                            <input type="radio" name="pessoa_tipo" id="pessoa_tipo" checked={this.state.detalhes.pessoa_tipo === 'juridica'} value="juridica" onChange={this.handleChange} /> Jurídica
                            <br />
                            <span id="validacao-pessoa_tipo"></span>
                            <label htmlFor="cpf_cnpj" className="required">CPF / CNPJ</label>
                            <input type="text" name="cpf_cnpj" id="cpf_cnpj" value={this.state.detalhes.cpf_cnpj} onChange={this.handleChange} />
                            <span id="validacao-cpf_cnpj"></span>
                            <label htmlFor="setor" className="required">Setor</label>
                            <input type="radio" name="setor" id="setor" checked={this.state.detalhes.setor === 'publico'} value="publico" onChange={this.handleChange} /> Público
                            <input type="radio" name="setor" id="setor" checked={this.state.detalhes.setor === 'privado'} value="privado" onChange={this.handleChange} /> Privado
                            <br />
                            <span id="validacao-setor"></span>
                            <label htmlFor="status" className="required">Status</label>
                            <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'ativo'} value="ativo" onChange={this.handleChange} /> Ativo
                            <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'bloqueado'} value="bloqueado" onChange={this.handleChange} /> Bloqueado
                            <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'cancelado'} value="cancelado" onChange={this.handleChange} /> Cancelado
                            <br />
                            <span id="validacao-status"></span>
                            <label className="required" htmlFor="perfil">Perfil</label>
                            <Select
                                value={selectedOption}
                                onChange={(e) => this.handleChangeSelect(e, 'perfil_id')}
                                options={this.state.perfis}
                            />
                            <span id="validacao-perfil"></span>
                            <label htmlFor="email_administrador">E-mail Administrador</label>
                            <input type="text" name="email_administrador" id="email_administrador" value={this.state.detalhes.email_administrador} onChange={this.handleChange} />
                            <span id="validacao-email_administrador"></span>
                        </form>
                    </TabPanel>
                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="descricao">Descrição</label>
                                    <input
                                        className={this.checkErrorField("descricao")}
                                        type="text" name="descricao" id="descricao" value={this.state.endereco.descricao} onChange={this.handleEnderecoChange} />
                                    <span id="validacao-descricao"></span>
                                </div>
                                <div className="col-md-8">
                                    <label htmlFor="endereco">Endereço</label>
                                    <input
                                        className={this.checkErrorField("endereco")}
                                        type="text" name="endereco" id="endereco" value={this.state.endereco.endereco} onChange={this.handleEnderecoChange} />
                                    <span id="validacao-endereco"></span>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="bairro">Bairro</label>
                                    <input
                                        className={this.checkErrorField("bairro")}
                                        type="text" name="bairro" id="bairro" value={this.state.endereco.bairro} onChange={this.handleEnderecoChange} />
                                    <span id="validacao-bairro"></span>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="municipio">Município</label>
                                    <input
                                        className={this.checkErrorField("municipio")}
                                        type="text" name="municipio" id="municipio" value={this.state.endereco.municipio} onChange={this.handleEnderecoChange} />
                                    <span id="validacao-municipio"></span>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="estado">Estado</label>
                                    <input
                                        className={this.checkErrorField("estado")}
                                        type="text" name="estado" id="estado" value={this.state.endereco.estado} onChange={this.handleEnderecoChange} />
                                    <span id="validacao-estado"></span>
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="cep">CEP</label>
                                    <input
                                        className={this.checkErrorField("cep")}
                                        type="text" name="cep" id="cep" value={this.state.endereco.cep} onChange={this.handleEnderecoChange} />
                                    <span id="validacao-cep"></span>
                                </div>
                            </div>
                        </form>
                        <button className={(Number.isInteger(this.state.id_editando_endereco)) ? "hdv-btn-forms hdv-top15 hdv-btn-yellow" : "hdv-btn-forms hdv-top15 hdv-btn-blue"}
                            onClick={this.addNovoEndereco} >
                            {(Number.isInteger(this.state.id_editando_endereco)) ? <i className="fa fa-refresh"></i> : <i className="fa fa-plus"></i>}
                            {(Number.isInteger(this.state.id_editando_endereco)) ? "Atualizar Endereço" : "Adicionar Endereço"}
                        </button>

                        <button className={(Number.isInteger(this.state.id_editando_endereco)) ? "hdv-btn-forms hdv-btn-orange" : "hdv-btn-forms hdv-btn-orange hdv-noshow-item"}
                            onClick={this.cancelEditEndereco} > <i className="fa fa-cancel"></i> Cancelar Edição
                        </button>

                        <h6 className="hdv-subtable-title">Lista de endereços cadastrados</h6>
                        <table className="hdv-subtable">
                            <thead>
                                <tr>
                                    <td>Descrição</td>
                                    <td>Endereço</td>
                                    <td>Ações</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.createRows("endereco", "enderecos", "descricao", "endereco")}
                            </tbody>
                        </table>
                    </TabPanel>
                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="contato">Contato</label>
                                    <input
                                        className={this.checkErrorField("contato")}
                                        type="text" name="contato" id="contato" value={this.state.contato.contato} onChange={this.handleContatoChange} />
                                    <span id="validacao-contato"></span>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="telefone">Telefone</label>
                                    <input
                                        className={this.checkErrorField("telefone")}
                                        type="text" name="telefone" id="telefone" value={this.state.contato.telefone} onChange={this.handleContatoChange} />
                                    <span id="validacao-telefone"></span>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="celular">Celular</label>
                                    <input
                                        className={this.checkErrorField("celular")}
                                        type="text" name="celular" id="celular" value={this.state.contato.celular} onChange={this.handleContatoChange} />
                                    <span id="validacao-celular"></span>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="email">E-mail</label>
                                    <input
                                        className={this.checkErrorField("email")}
                                        type="text" name="email" id="email" value={this.state.contato.email} onChange={this.handleContatoChange} />
                                    <span id="validacao-email"></span>
                                </div>
                            </div>
                        </form>
                        <button className={(Number.isInteger(this.state.id_editando_contato)) ? "hdv-btn-forms hdv-top15 hdv-btn-yellow" : "hdv-btn-forms hdv-top15 hdv-btn-blue"}
                            onClick={this.addNovoContato} >
                            {(Number.isInteger(this.state.id_editando_contato)) ? <i className="fa fa-refresh"></i> : <i className="fa fa-plus"></i>}
                            {(Number.isInteger(this.state.id_editando_contato)) ? "Atualizar Contato" : "Adicionar Contato"}
                        </button>
                        <button className={(Number.isInteger(this.state.id_editando_contato)) ? "hdv-btn-forms hdv-btn-orange" : "hdv-btn-forms hdv-btn-orange hdv-noshow-item"}
                            onClick={this.cancelEditContato} > <i className="fa fa-cancel"></i> Cancelar Edição
                        </button>
                        <h6 className="hdv-subtable-title">Lista de contatos cadastrados</h6>
                        <table className="hdv-subtable">
                            <thead>
                                <tr>
                                    <td>Descrição</td>
                                    <td>E-mail</td>
                                    <td>Ações</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.createRows("contato", "contatos", "contato", "email")}
                            </tbody>
                        </table>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">

                    <button onClick={() => this.saveAction(!!this.props.match.params.id)} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>Salvar</button>
                    
                        <button onClick={() => this.openModal()} className="hdv-btn-forms hdv-btn-yellow ">Cancelar</button>
                </div>

            </div>
        );
    }
}

export default Form;