import React, { Component } from 'react'
//import { WidthProvider, Responsive } from "react-grid-layout"
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
//import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, BarChart, Tooltip, Legend, Bar, ReferenceLine } from 'recharts';
import axios from 'axios'
import moment from 'moment-timezone'
import Mapa from '../../components/Mapa'
import ReactTable from "react-table"
import { Modal, ModalBody, Button, ModalFooter, ModalHeader } from 'reactstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import AlertaDashboard from '../../components/AlertaDashboard'
import auth from '../../utils/auth'
import config from '../../utils/config'
import _ from 'underscore'
import Select, { components } from 'react-select'
import helper_fn from '../../utils/helper'

class Dashboard extends Component {
    state = {
        mapObject: false,
        markers_created: [],
        atualizados: 0,
        inativos: 0,
        alertas: 0,
        semcomunicacao: 0,
        filtered: [],
        selected: [],
        selectAll: 0,
        loading: true,
        pages: 1,
        pageSize: 10,
        tabIndex: 0,
        data: [],
        monitorado: '',
        modal: false,
        descricaoMotivo: '',
        observacao: '',
        editarObservacao: '',
        motivoSelecionado: false,
        editarMotivoSelecionado: false,
        motivos: [],
        erros: [],
        markers: [],
        modalAlerta: false,
        markersState: { warning: true, off: true, on: true, danger: true },
        mapFilter: null,
        markerFiltered: [],
        filter: {
            toggle: false,
            value: "",
            filtered: [],
        }
    }

    updateMapObject = (mapObj) => {
        this.setState({ mapObject: mapObj })
    }

    componentDidMount = () => {
        this.buscarDadosMapa()
        window.addEventListener('showModal', async (e) => {
            let monitorado = await e.detail
            localStorage.setItem('monitorado_obs', monitorado)
            this.setState({ monitorado: monitorado, filtered: [{ "id": "monitorado_id", "value": e.detail, "type": "int" }] })
            this.buscarMotivos()
            this.toggleModal()
        })
        window.addEventListener('showAlerta', async (e) => {
            this.setState({ modalAlerta: true, alertaImei: e.detail })
        })
    }

    buscarMotivos = () => {
        const userInfo = auth.getUserInfo()
        axios({
            method: 'GET',
            url: config.base_api_url + `${config.api_version}/${userInfo.empresa}/motivo-observacao/`,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(res => {
            let motivos = []
            if (res.data)
                res.data.map(motivo => motivos.push({ label: motivo.descricao, value: motivo.id }))
            this.setState({ motivos })
        })
    }

    excluirMotivo = (id) => {
        const userInfo = auth.getUserInfo()
        axios({
            method: 'DELETE',
            url: config.base_api_url + `${config.api_version}/${userInfo.empresa}/motivo-observacao/${id}/`,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(() => this.buscarMotivos())
    }

    criarMotivo = () => {
        let { descricaoMotivo, erros } = this.state
        if (descricaoMotivo.length === 0) {
            erros.push('novomotivo')
            this.setState({ erros })
            return
        }
        erros = erros.filter(item => item !== 'novomotivo')
        this.setState({ erros })

        const userInfo = auth.getUserInfo()
        let data = new FormData()

        data.append('descricao', descricaoMotivo)
        axios({
            method: 'POST',
            url: config.base_api_url + `${config.api_version}/${userInfo.empresa}/motivo-observacao/`,
            data: data,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(() => {
                this.buscarMotivos()
                this.setState({ descricaoMotivo: '' })
            })
            .catch(err => console.log(err))
    }

    saveAction = () => {
        let { editarObservacao, editarMotivoSelecionado, selected, erros } = this.state
        if (editarObservacao.length === 0 || !editarMotivoSelecionado) {
            erros.push('editar-obs')
            this.setState({ erros })
            return
        }

        erros = erros.filter(item => item !== 'editar-obs')
        this.setState({ erros })

        const userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()

        bodyFormData.append('observacao', editarObservacao)
        bodyFormData.append('motivo_id', editarMotivoSelecionado.value)
        bodyFormData.append('monitorado_id', selected[0].monitorado_id)
        axios({
            method: 'PATCH',
            url: config.base_api_url + `${config.api_version}/${userInfo.empresa}/observacao/${selected[0].id}/`,
            data: bodyFormData,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(() => {
            this.setState({ modalEditar: false })
            this.refReactTable.fireFetchData()
        })
    }

    deletar = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        this.setState({ loading: true, enableDeletar: false, enableEditar: false })

        this.state.selected.map((obs, index) => requestArray.push(axios.delete(config.base_api_url + `${config.api_version}/${userInfo.empresa}/observacao/${obs.id}/`, config_me)))
        axios.all(requestArray).then(axios.spread((acct, perms) => this.refReactTable.fireFetchData()))
    }

    getData = _.debounce((state, instance) => {
        if (state) {
            if ("pageSize" in state) {
                const userInfo = auth.getUserInfo()
                this.setState({ loading: true })

                axios({
                    method: 'GET',
                    url: config.base_api_url + config.api_version + '/' + userInfo.empresa + '/observacao/?page=' + state.page + '&pageSize=' + state.pageSize + '&filter=' + encodeURI(JSON.stringify(state.filtered)),
                    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
                })
                    .then((res) => {
                        if (res.data.results) {
                            let sorted = res.data.results
                            sorted = sorted.sort((a, b) => new Date(b.data_criado) - new Date(a.data_criado))
                            this.setState({ data: sorted, pages: res.data.pages, selected: [], loading: false, enableDeletar: false, enableEditar: false })
                        } else
                            this.setState({ data: [], pages: res.data.pages, selected: [], loading: false, enableDeletar: false, enableEditar: false })

                    })
            }
        }
    }, 500)

    criarObservacao = () => {
        let { observacao, motivoSelecionado, erros } = this.state
        let monitorado = localStorage.getItem('monitorado_obs')
        if (observacao.length === 0 || motivoSelecionado.length === 0) {
            erros.push('nova-obs')
            this.setState({ erros })
            return
        } else {
            erros = erros.filter(item => item !== 'nova-obs')
            this.setState({ erros })
        }
        const userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()

        bodyFormData.append('observacao', observacao)
        bodyFormData.append('motivo_id', motivoSelecionado.value)
        bodyFormData.append('monitorado_id', monitorado)
        axios({
            method: 'POST',
            url: config.base_api_url + `${config.api_version}/${userInfo.empresa}/observacao/`,
            data: bodyFormData,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(() => { this.setState({ motivoSelecionado: '', observacao: '', tabIndex: 0 }) })
            .catch(err => console.log(err))
    }

    buscarDadosMapa = () => {
        let configUser = auth.getUserInfo()
        let url = config.base_api_url + config.api_version + "/" + configUser['empresa'] + "/dados-dashboard/"
        let headers = {
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        }

        axios.get(url, headers)
            .then((dados) => {
                let markers = []
                let atualizados = 0
                let alertas = 0
                let inativos = 0
                let semcomunicacao = 0
                dados.data.forEach((monitorado) => {

                    let status = "off"
                    let data_formatada = "Sem Equipamento"
                    let days = 0

                    if (monitorado.item__equipamento__imei !== null) {
                        let data_ultimo_reporte = moment.tz(monitorado.ultimo_evento.data_hora_dispositivo, 'UTC')
                        let data_timezone_user = data_ultimo_reporte.tz(auth.getUserInfo().timezone)
                        data_formatada = data_timezone_user.format('DD/MM/YYYY HH:mm:ss')

                        var now = moment(new Date());
                        var duration = moment.duration(now.diff(data_timezone_user));
                        days = duration.asDays();
                    } else {
                        inativos++
                    }

                    //if(monitorado.ultimo_evento.rele === "1"){
                    //    status = 'on'
                    //}

                    if (monitorado.item__equipamento__imei !== null) {
                        if (days >= 1) {
                            status = 'danger'
                            semcomunicacao++
                        } else {
                            atualizados++
                            status = 'on'
                        }
                    }

                    if (monitorado.tem_alerta) {
                        status = 'warning'
                        alertas++
                    }

                    let existe_monitorado_array = markers.filter((marker) => {
                        if (marker.id === monitorado.id) {
                            return true
                        }
                        return false
                    })

                    if (existe_monitorado_array.length === 0) {
                        markers.push({
                            id: monitorado.id,
                            id_equipamento: monitorado.item__equipamento_id,
                            latitude: monitorado.latitude,
                            longitude: monitorado.longitude,
                            identificacao: monitorado.nome + " - Última Comunicação: " + data_formatada,
                            icon: "waterfilter",
                            tem_alerta: monitorado.tem_alerta,
                            status: status,
                            nome: monitorado.nome,
                            cliente: monitorado.item__monitorado__cliente__nome_fantasia ?? "-",
                            equipamentos: [{
                                rele: (monitorado.ultimo_evento.rele === "1") ? true : false,
                                itens: monitorado.itens,
                                status: status,
                                codigo_produto: monitorado.ultimo_evento.codigo_produto,
                                ct_pulso: monitorado.ultimo_evento.ct_pulso,
                                corrente_trifasica: monitorado.ultimo_evento.corrente_trifasica,
                                tensao_trifasica: monitorado.ultimo_evento.tensao_trifasica,
                                equipamento: (monitorado.item__equipamento__imei) ? monitorado.item__equipamento__imei : "-",
                                ultima_comunicacao: data_formatada
                            }]
                        })
                    } else {
                        let markerExist = existe_monitorado_array[0]
                        delete markers[markerExist]
                        markerExist.equipamentos.push({
                            rele: (monitorado.ultimo_evento.rele === "1") ? true : false,
                            itens: monitorado.itens,
                            ct_pulso: monitorado.ultimo_evento.ct_pulso,
                            corrente_trifasica: monitorado.ultimo_evento.corrente_trifasica,
                            tensao_trifasica: monitorado.ultimo_evento.tensao_trifasica,
                            cliente: monitorado.item__monitorado__cliente__nome_fantasia ?? "-",
                            equipamento: (monitorado.item__equipamento__imei) ? monitorado.item__equipamento__imei : "-",
                            status: status,
                            ultima_comunicacao: data_formatada
                        })
                        markers.push(markerExist)
                    }
                })
                this.setState({ alertas: alertas, semcomunicacao: semcomunicacao, inativos: inativos, atualizados: atualizados, markers })
                this.createMarker(markers)
            })
            .catch((erro) => console.log(erro))
    }

    toggleModalEditar = (original) => {
        const { selected, motivos, modalEditar } = this.state
        if (original) {
            this.setState({
                modalEditar: modalEditar ? false : true,
                editarObservacao: original.observacao,
                editarMotivoSelecionado: motivos ? motivos.filter(m => m.value === original.motivo_id)[0] : '',
            })
        } else {
            this.setState({
                modalEditar: modalEditar ? false : true,
                editarObservacao: selected[0].observacao,
                editarMotivoSelecionado: motivos ? motivos.filter(m => m.value === selected[0].motivo_id)[0] : '',
            })
        }
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal,
            motivos: [],
            motivoSelecionado: '',
            descricaoMotivo: '',
            selected: [],
            editarObservacao: '',
            editarMotivoSelecionado: '',
            tabIndex: 0,
            observacao: '',
            data: [],
            erros: [],
            monitorado: '',
        })
    }

    createMarker = (markerList, active) => {
        this.destroyMarker()

        if (markerList) {
            let activeMarkers = []
            if (active) {
                for (let i = 0; i < active.length; i++) {
                    activeMarkers.push(markerList.filter(marker => marker.status === active[i]))
                }

                activeMarkers = _.flatten(activeMarkers)
            } else
                activeMarkers = markerList

            let arrayMarkers = this.state.markers_created
            activeMarkers.forEach(element => {
                let marker = new this.state.mapObject.google_map.maps.Marker({
                    position: { lat: parseFloat(element.latitude), lng: parseFloat(element.longitude) },
                    title: element.identificacao,
                    icon: "/map-icon/" + element.status + "/" + element.icon + ".png"
                });

                let contentHtml = `
                <div>
                    <h5>`+ element.nome + `</h5>
                    <div>&nbsp;</div>`

                if (element.equipamentos) {
                    element.equipamentos.forEach((equip) => {
                        // let releHtml = (equip.rele) ? '<span class="badge badge-success">Ligado</span>' : '<span class="badge badge-secondary">Desligado</span>'
                        let statusHtml = (equip.status === "danger") ? 'badge-danger' : 'badge-info'
                        let itensHtml = equip.itens.map((item) => {
                            if (item.tipo === 1 && item.entrada === 'digital' && equip.ct_pulso) {
                                let html = `
                                <div style="display: flex;">
                                    ${item.nome}:&nbsp;<b>${item.valor_atual.toFixed(2)}
                                    (${equip.ct_pulso[item.canal - 1] === 999 ? '0' : equip.ct_pulso[item.canal - 1].toFixed(2)} m³/h) </b>
                                    ${equip.ct_pulso[item.canal - 1] === 0 || equip.ct_pulso[item.canal - 1] === 999
                                        ?
                                        `<i class="hdv-badge badge-secondary" title="Desligado" style="height: 15px"></i></div>`
                                        :
                                        `<i class="hdv-badge badge-success" title="Ligado" style="height: 15px"></i></div>`}`
                                return html
                            } else
                                        
                                return `<div>${item.nome}: <b>${(item.tipo == 2) ? String(helper_fn.converte_decimal_to_hhmmss(item.valor_atual.toFixed(3))) : item.valor_atual.toFixed(2)}</b></div>`
                        })

                        let equipamentoHtml = (equip.equipamento) ? `<a href="/equipamento/` + equip.equipamento + `/status/` + element.id_equipamento + `">` + equip.equipamento + `</a></div>` : "-"
                        let atalhoReporte = `<br><a href="/virtual/` + equip.equipamento + `/lancamento/" style="background-color: #13afba;padding: 4px 10px; color: #fff; border-radius: 4px;">Reporte manual</a></div>`

                        contentHtml += `
                        <div>Cliente: `+ element.cliente + `</div>
                        <div>Equipamento: `+ equipamentoHtml + "" + itensHtml.join("") + `
                        <div>${equip.corrente_trifasica ? `Corrente Trifásica: <b>${Number(equip.corrente_trifasica).toFixed(0)} (A)` : ''} </b></div>
                        <div>${equip.tensao_trifasica ? `Tensão Trifásica: <b>${Number(equip.tensao_trifasica).toFixed(0)} (V)</b>` : ''}</div>
                        <div>Última Comunicação: <span class="badge `+ statusHtml + `">` + equip.ultima_comunicacao + `</span></div>
                        ${equip.codigo_produto === 8 ? atalhoReporte : ''}
                        <div>&nbsp;</div>`
                    })

                    contentHtml += `
                    <br /><br />
                    <div style="position: fixed !important; bottom: 3px; width: 100%; padding-bottom: 5px;">
                        ${element.tem_alerta ? `<button title="Possui alerta" style="border: none; padding: 5px; border-radius: 20px; cursor: pointer; color: #fff; background-color: #ff6d29"
                        onclick="window.dispatchEvent(new CustomEvent('showAlerta', {detail: '${element.id}'}))">
                        <i class="fa fa-bell fa-20px float-left"></i></button>` : ''}
                        <button
                            style="margin-right: 25px;"
                            class="btn btn-primary btn-sm float-right"
                            onclick="window.dispatchEvent(new CustomEvent('showModal', {detail: '${element.id}'}))">
                            Observações
                        </button>
                        <div>
                    </div>`

                    let infowindow = new this.state.mapObject.google_map.maps.InfoWindow({
                        content: contentHtml,
                        maxWidth: 400,
                    })

                    marker.addListener('click', () => infowindow.open(this.state.mapObject.map, marker))

                    arrayMarkers.push(marker)
                    marker.setMap(this.state.mapObject.map)
                }
            })

            this.setState({ markers_created: arrayMarkers })
            this.fixCenterMap()
        }
    }

    updateMarkers(key) {
        let { markersState } = this.state
        let activeMarkers = []
        markersState[key] = !markersState[key]
        this.setState({ markersState })
        Object.entries(markersState).map(marker => {
            if (marker[1])
                activeMarkers.push(marker[0])
            return null
        })
        this.createMarker(this.state.markers, activeMarkers)
    }

    destroyMarker = () => {
        this.state.markers_created.forEach(element => element.setMap(null))
        this.setState({ markers_created: [] })
    }

    fixCenterMap = () => {
        let bounds = new this.state.mapObject.google_map.maps.LatLngBounds()
        this.state.markers_created.forEach(element => bounds.extend(element.getPosition()))
        this.state.mapObject.map.fitBounds(bounds)
    }

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value })

    handleChangeSelect = (e, field) => {
        if (field === 'motivoSelecionado' && e.value === 'novo_motivo') {
            this.setState({ tabIndex: 2 })
        } else {
            this.setState({ [field]: e })
        }
    }

    toggleRow = (original) => {
        let selecionados = this.state.selected
        let editar = false
        let deletar = false

        if (selecionados.includes(original))
            selecionados = selecionados.filter(item => item !== original)
        else
            selecionados.push(original)

        if (selecionados.length === 1)
            editar = true

        if (selecionados.length >= 1)
            deletar = true


        this.setState({
            enableEditar: editar,
            enableDeletar: deletar,
            selected: selecionados,
            selectAll: 2
        })
    }

    toggleSelectAll = () => {
        let selecionados = []
        let editar = false
        let deletar = false

        if (this.state.selectAll === 0) {
            this.state.data.forEach(x => {
                selecionados.push(x)
            })
        }

        if (selecionados.length === 1)
            editar = true

        if (selecionados.length >= 1)
            deletar = true

        this.setState({
            enableEditar: editar,
            enableDeletar: deletar,
            selected: selecionados,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        })
    }

    filterMarkers = (e) => {
        let { markers, filter } = this.state
        if (e.target.value.length !== 0) {
            const filtered = markers.filter(marker => marker.nome?.toLowerCase()?.startsWith(e.target.value?.toLowerCase()))
            filter['filtered'] = filtered
        } else {
            filter['filtered'] = []
        }

        filter['value'] = e.target.value
        this.setState({ filter })
    }

    renderClientList() {
        let { filter } = this.state
        let markers = filter.filtered.length > 0 ? filter.filtered : this.state.markers
        let elements = []
        const status = {
            'on': '#2ad167',
            'off': '#424242',
            'warning': '#ffb142',
            'danger': '#ff5252'
        }

        markers?.map((marker, index) => {
            elements.push(
                <li
                    key={index}
                    onClick={() => {
                        let { mapFilter } = this.state
                        mapFilter = [marker?.latitude, marker?.longitude]
                        this.setState({ mapFilter: mapFilter })
                    }}>
                    <span className="badge" style={{ backgroundColor: status[marker.status] }}>&nbsp;</span>
                    <span>{marker?.nome}</span>
                </li>
            )
        })

        return elements
    }

    render() {
        const { motivos, markersState } = this.state
        let monitorado = localStorage.getItem('monitorado_obs') || ''

        const columns = [{
            Header: x => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={this.state.selectAll === 1}
                        ref={input => {
                            if (input)
                                input.indeterminate = this.state.selectAll === 2
                        }}
                        onChange={() => this.toggleSelectAll()}
                    />
                )
            },
            sortable: false,
            width: 55,
            accessor: "",
            filterable: false,
            Cell: ({ original }) => {
                return (
                    <div className="hdv-aligncenter-checkboxgrid">
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={(this.state.selected.includes(original))}
                            onChange={() => this.toggleRow(original)}
                        />
                    </div>
                )
            }
        }, {
            Header: 'Criado em ',
            accessor: 'data_criado',
            Filter: ({ filter, onChange }) =>
                <input
                    type="date"
                    value={filter ? filter.value : ""}
                    onChange={e => onChange(e.target.value)}
                />,
            Cell: ({ original }) => {
                let data_in_timezone_user = moment.tz(original.data_criado, 'UTC')
                let data_formatada = data_in_timezone_user.tz(auth.getUserInfo().timezone).format('DD/MM/YYYY HH:mm:ss')
                return (<span>{data_formatada}</span>)
            }
        }, {
            Header: 'Usuário',
            accessor: 'usuario__username',
        }, {
            Header: 'Observação',
            accessor: 'observacao',
        }, {
            Header: 'Motivo',
            accessor: 'motivo__descricao',
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}>
                    <option value="">Mostrar Todos</option>
                    {motivos.map(motivo => <option value={motivo.label} key={motivo.value}>{motivo.label}</option>)}
                </select>
        }]

        const { Option } = components
        const IconOption = (props) => (
            <Option {...props}>
                {props.data.custom ?
                    <b style={{ color: '#098f0b', display: 'flex', alignItems: 'center' }}>
                        <i style={{ fontSize: '22px' }} className="fa fa-plus"></i>
                        {props.data.label}
                    </b>
                    :
                    props.data.label}
            </Option>
        )

        return (
            <div className="hdv-render-div home-new-style">
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} className="hdv-observacao-modal">
                    <ModalHeader toggle={this.toggleModal}>Observação</ModalHeader>
                    <ModalBody style={{ padding: '12px' }}>
                        <Tabs
                            selectedIndex={this.state.tabIndex}
                            onSelect={tabIndex => this.setState({ tabIndex, selected: [], enableEditar: false, enableDeletar: false })}>
                            <TabList>
                                <Tab>Observações</Tab>
                                <Tab>Nova Observação</Tab>
                                <Tab>Motivos</Tab>
                            </TabList>
                            <TabPanel>
                                <div className="hdv-btn-group">
                                    <button onClick={() => this.setState({ tabIndex: 1 })} className="hdv-btn-forms hdv-btn-geen">Novo</button>
                                    <button onClick={() => this.toggleModalEditar()} className={"hdv-btn-forms hdv-btn-yellow " + (this.state.enableEditar ? "" : "hdv-btn-disable")} disabled={!this.state.enableEditar}>Visualizar</button>
                                    <button onClick={this.state.enableDeletar ? this.deletar : null} className={"hdv-btn-forms hdv-btn-red " + (this.state.enableDeletar ? "" : "hdv-btn-disable")}>Excluir</button>
                                </div>
                                {monitorado ? <ReactTable
                                    ref={(refReactTable) => { this.refReactTable = refReactTable }}
                                    manual
                                    filterable
                                    data={this.state.data}
                                    pages={this.state.pages}
                                    loading={this.state.loading}
                                    onFetchData={this.getData}
                                    columns={columns}
                                    defaultFiltered={[{ "id": "monitorado_id", "value": monitorado, "type": "int" }]}
                                    defaultPageSize={10}
                                    previousText={'Anterior'}
                                    nextText={'Próximo'}
                                    loadingText={'Carregando...'}
                                    noDataText={'Nenhum registro para exibir'}
                                    pageText={'Página'}
                                    ofText={'de'}
                                    rowsText={'linhas'}
                                /> : null}
                            </TabPanel>
                            <TabPanel>
                                <form className="hdv-form-default">
                                    <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className="col-md-12">
                                            <label htmlFor="observacao">Descrição</label>
                                            <textarea
                                                className="hdv-textarea"
                                                rows="7"
                                                type="text"
                                                name="observacao"
                                                id="observacao"
                                                value={this.state.observacao}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="motivo">Motivo</label>
                                            <Select
                                                id="motivo"
                                                components={{ Option: IconOption }}
                                                onChange={(e) => this.handleChangeSelect(e, 'motivoSelecionado')}
                                                value={this.state.motivoSelecionado}
                                                options={[...motivos, { label: 'Novo Motivo', value: 'novo_motivo', custom: true }]}
                                            />
                                        </div>
                                    </div>
                                    {this.state.erros.includes('nova-obs') ? <span id="validacao-nova-obs">Preencha todos os campos</span> : null}
                                </form>
                                <div style={{ marginTop: '25px', marginBottom: '15px' }} className="float-right">
                                    <Button color="primary" onClick={() => this.criarObservacao()}>Criar Observação</Button>{' '}
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <form className="hdv-form-default">
                                    <div style={{ minHeight: '520px' }}>
                                        <div className="col-md-12 hdv-zera-padding-left-right-boostrap" style={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <div style={{ flex: '8' }}>
                                                <label htmlFor="descricaoMotivo">Descrição do Motivo</label>
                                                <input type="text" name="descricaoMotivo" id="descricaoMotivo" value={this.state.descricaoMotivo} onChange={this.handleChange} />
                                            </div>
                                            <Button
                                                color="success"
                                                onClick={this.criarMotivo}
                                                style={{ flex: '2', height: '40px', margin: '3px 0 2px 12px' }}>
                                                Adicionar
                                            </Button>
                                        </div>
                                        {this.state.erros.includes('novomotivo') ? <span id="validacao-novomotivo">Esse campo não pode estar vazio</span> : null}
                                        {motivos.length > 0 ?
                                            <div style={{ maxHeight: '380px', overflow: 'auto' }}>
                                                <table className="table" style={{ marginTop: '20px' }}>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan="2">Descrição</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {motivos.map(motivo => (
                                                            <tr key={motivo.value}>
                                                                <td colSpan="2">{motivo.label}</td>
                                                                <td style={{ textAlign: 'right' }}>
                                                                    <button className="hdv-btn-forms hdv-btn-red" onClick={(e) => {
                                                                        e.preventDefault()
                                                                        this.excluirMotivo(motivo.value)
                                                                    }}>Excluir</button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div> : null}
                                    </div>
                                </form>
                            </TabPanel>
                        </Tabs>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.modalAlerta} toggle={() => this.setState({ modalAlerta: false })} className="hdv-observacao-modal">
                    <ModalHeader toggle={() => this.setState({ modalAlerta: false })}>
                        Alertas
                    </ModalHeader>
                    <ModalBody style={{ padding: '12px' }}>
                        <AlertaDashboard monitorado={this.state.alertaImei} />
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.modalEditar} toggle={() => this.setState({ modalEditar: false })}>
                    <ModalHeader toggle={() => this.setState({ modalEditar: false })}>Detalhes da Observação</ModalHeader>
                    <ModalBody style={{ padding: '12px' }}>
                        <form className="hdv-form-default">
                            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="col-md-12">
                                    <label htmlFor="editarObservacao">Descrição</label>
                                    <textarea className="hdv-textarea" rows="5" name="editarObservacao" id="editarObservacao" value={this.state.editarObservacao} onChange={this.handleChange} />
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="motivo">Motivo</label>
                                    <Select
                                        id="motivo"
                                        onChange={(e) => this.handleChangeSelect(e, 'editarMotivoSelecionado')}
                                        value={this.state.editarMotivoSelecionado}
                                        options={motivos}
                                    />
                                </div>
                            </div>
                            {this.state.erros.includes('editar-obs') ? <span id="validacao-editar-obs">Preencha todos os campos</span> : null}
                        </form>
                        {this.state.erro ? <span>Preencha todos os campos!</span> : null}
                    </ModalBody>
                    <ModalFooter>
                        <div className="hdv-btn-group">
                            <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-geen ">Alterar</button>
                            <button onClick={() => this.setState({ modalEditar: false })} className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                        </div>
                    </ModalFooter>
                </Modal>

                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                    <div className="top-map-degrade"></div>

                    <Mapa mapObjectFn={this.updateMapObject} filter={this.state.mapFilter} />

                    <div className="map-marker-toggle">
                        <div className={`div-sample marker-filter ${!markersState.on ? 'disabled' : ''}`} onClick={() => this.updateMarkers("on")}>
                            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                                <div className="hdv-info-bloco hdv-info-bloco-active">
                                    <div className="hdv-title-bloco"><i className="fa fa-check-circle hdv-icon-position-homebox"></i> <span className="hdv-icon-text-homebox">Atualizados</span></div>
                                    <div className="hdv-body-bloco">{this.state.atualizados}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`div-sample marker-filter ${!markersState.warning ? 'disabled' : ''}`} onClick={() => this.updateMarkers("warning")}>
                            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                                <div className="hdv-info-bloco hdv-info-bloco-alert">
                                    <div className="hdv-title-bloco"><i className="fa fa-exclamation-triangle hdv-icon-position-homebox"></i> <span className="hdv-icon-text-homebox">Alertas</span></div>
                                    <div className="hdv-body-bloco">{this.state.alertas}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`div-sample marker-filter ${!markersState.danger ? 'disabled' : ''}`} onClick={() => this.updateMarkers("danger")}>
                            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                                <div className="hdv-info-bloco hdv-info-bloco-sem-comunicacao">
                                    <div className="hdv-title-bloco"><i className="fa fa-wifi hdv-icon-position-homebox"></i> <span className="hdv-icon-text-homebox">Sem comunicação</span></div>
                                    <div className="hdv-body-bloco">{this.state.semcomunicacao}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`div-sample marker-filter ${!markersState.off ? 'disabled' : ''}`} onClick={() => this.updateMarkers("off")}>
                            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                                <div className="hdv-info-bloco hdv-info-bloco-off">
                                    <div className="hdv-title-bloco"><i className="fa fa-power-off hdv-icon-position-homebox"></i> <span className="hdv-icon-text-homebox">Inativos</span></div>
                                    <div className="hdv-body-bloco">{this.state.inativos}</div>
                                </div>
                            </div>
                        </div>

                        <button onClick={() => this.setState({ filter: { ...this.state.filter, toggle: !this.state.filter.toggle }, mapFilter: null })}>
                            {
                                this.state.filter.toggle
                                    ?
                                    <i className="fa fa-chevron-left fa-2x"></i>
                                    :
                                    <i className="fa fa-chevron-right fa-2x"></i>
                            }
                        </button>
                        {this.state.filter.toggle
                            ?
                            <div className="marker-toggle-list">
                                <input value={this.state.filter.value} type="text" placeholder="Pesquisar" onChange={this.filterMarkers} />
                                <ul>
                                    {this.renderClientList()}
                                </ul>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div >
        )
    }
}

export default Dashboard;
