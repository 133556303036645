import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Animated } from "react-animated-css";
import './style.css';
import waterdrop from "../../assets/waterdrop-login.png"
import logo from "../../assets/ghidro-logo-footer.png"
import facebook from "../../assets/facebook.png"
import linkedin from "../../assets/linkedin.png"
import youtube from "../../assets/youtube.png"
import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import AvisoPersonalizado from "../../components/AvisoPersonalizado";

class Login extends Component {

    state = {
        email: "",
        password: "",
        conexao_persistente: false,
        redirectToReferrer: false,
        showmessage: false,
        logomarca: logo,
        loadcomplete: false,
        aviso: { show: false, args: null, message: '' },
        config: { "titulo_sistema": "Hidroview v4", "descricao_sistema": "", "logomarca": "logomarcas/kronlogo.png", "descricao_horario_atendimento": "Seg. a Sex. das 8:00 às 18:00 ", "email_contato": "contato@ghidro.com.br ", "endereco": "Rua Carlos Ferrari, 18, Sala 610 – Centro – Garça/SP", "facebook": "https://www.facebook.com/ghidromonitoramento/", "link_criar_conta": "https://www.ghidro.com.br", "linkedin": "https://www.linkedin.com/company/g-hidro-monitoramento/", "telefone": "(14) 98161-0616", "youtube": "https://www.youtube.com/channel/UCj1BOaOQTuPMrTgPewSwgvw" },
        cores: { "degrade_top_primeira_cor": "#F44E3B", "degrade_top_segunda_cor": "#2f577b", "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7", "fonte_grupo_menu_lateral": "#c7c7c7" }
    }

    componentWillMount = () => {
        let requestArray = []
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/configuracao-login/?domain=' + window.location.hostname, config_me))

        axios.all(requestArray)
            .then(axios.spread((config) => {

                let cores = { "degrade_top_primeira_cor": "#F44E3B", "degrade_top_segunda_cor": "#2f577b", "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7", "fonte_grupo_menu_lateral": "#c7c7c7" }
                let logomarca = ''
                let config_nova = ''

                if (config.status === 200) {
                    cores = config.data.cores
                }

                if (config.status === 200) {
                    logomarca = config.data.config.logomarca
                    config_nova = config.data.config
                }

                this.setState({ cores: cores, opacity: 1, logomarca: logomarca, loadcomplete: true, 'config': config_nova })

                if (config.status === 200) {
                    document.title = (config.data.config.titulo_sistema) ? (config.data.config.titulo_sistema) : "Metric"
                } else {
                    document.title = "Metric"
                }

            }));
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const requestURL = cfg.base_api_url + 'o/token/'
        const config = { headers: { 'Content-Type': 'application/json' } }

        var bodyFormData = new FormData();
        bodyFormData.append("client_id", cfg.client_id)
        bodyFormData.append("client_secret", cfg.client_secret)
        bodyFormData.append("grant_type", cfg.grant_type)
        bodyFormData.append("username", this.state.email)
        bodyFormData.append("password", this.state.password)

        axios.post(requestURL, bodyFormData, config)
            .then((response) => {
                auth.setToken(response.data.access_token, true)
                auth.set(response.data.refresh_token, '__hidroview__refresh_token', true)
                auth.set({ "checked": this.state.conexao_persistente }, '__hidroview__persist_connect', true)

                const config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

                axios.get(cfg.base_api_url + cfg.api_version + '/me/', config_me)
                    .then((response) => {
                        response.data['pai'] = response.data.empresa
                        auth.setUserInfo(response.data, true)
                        this.setState({ redirectToReferrer: true })
                    })

            })
            .catch((error) => {
                this.setState({ showmessage: true })
                setTimeout(() => {
                    this.setState({ showmessage: false })
                }, 5000);
            });
    }

    handleOk = () => {
        this.setState({
            aviso: {
                ...this.state.aviso,
                show: false,
                args: null
            }
        });
    }

    toggleAviso = (e) => {

        e.preventDefault(); 
        const { aviso } = this.state;
        aviso['show'] = !aviso['show'];
      
        if (aviso['show']) {
          aviso['message'] = 'Para redefinir sua senha, entre em contato com o suporte.';
        }
      
        this.setState({ aviso });
        }
      

    render() {
        const { from } = this.props.location.state || { from: { pathname: "/" } }
        const { redirectToReferrer } = this.state

        if (redirectToReferrer) {
            return <Redirect to={from} />
        }

        if (auth.getToken() !== null) {
            return <Redirect to={from} />
        }
        return (
            <div className={(this.state.loadcomplete) ? "body-page" : "hdv-noshow-item"} style={{ 'background': 'linear-gradient(to bottom, ' + this.state.cores.login_degrade_primeira_cor_background + ' 0%, ' + this.state.cores.login_degrade_primeira_cor_background + ' 56%, ' + this.state.cores.login_degrade_segunda_cor_background + ' 100%)' }}>
                <Animated className="mensagem-erro" animationIn="fadeInDown" animationOut="fadeOutDown" animateOnMount={false} isVisible={this.state.showmessage}>
                    <div>
                        Falha na autenticação, por favor verifique seus dados e tente novamente. <br />
                        <i className="fa fa-exclamation mensagem-icone fa-white"></i>
                    </div>
                </Animated>
                <div className="center-login">
                    <div className="form-login" style={{ 'backgroundColor': this.state.cores.login_formulario_background }}>
                    <div className="logomarca">
                        <img className="form-image" src={this.state.logomarca} alt="" />
                        <div className="subtitulo-logo" style={{ 'color': this.state.cores.login_informacao_fonte_cor }}>{this.state.config.descricao_sistema}</div>
                    </div>
                        <form>
                            <div>
                                <label style={{ 'color': this.state.cores.login_formulario_fonte_cor }} className="labels">E-mail:</label>
                                <input type="text" id="email" onChange={e => this.setState({ email: e.target.value })} onKeyPress={this.handleKeyPress} className="campo-form" />
                                <label style={{ 'color': this.state.cores.login_formulario_fonte_cor }} className="labels">Senha:</label>
                                <input type="password" id="password" onChange={e => this.setState({ password: e.target.value })} onKeyPress={this.handleKeyPress} className="usuario" />
                            </div>
                            <div className="form-conectar">
                                <div className="permanecer-conectado">
                                    <span>
                                        <input onChange={e => this.setState({ conexao_persistente: e.target.checked })} onKeyPress={this.handleKeyPress} type="checkbox" name="permanecer" id="permanecer" />
                                        <span style={{ 'color': this.state.cores.login_formulario_fonte_cor }} className="permancer-label">Permanecer conectado?</span>
                                    </span>
                                    <div>
                                        <div className="esqueci links" style={{ 'color': this.state.cores.login_formulario_fonte_link }}><Link className="alogin" to="/login" onClick={(e) => this.toggleAviso(e)}>Esqueci minha senha</Link></div>
                                        <AvisoPersonalizado 
                                            toggle={this.toggleAviso}
                                            mensagem={this.state.aviso.message}
                                            onOk={this.handleOk}
                                            open={this.state.aviso.show}
                                        />
                                    </div>
                                </div>
                                <div className="btn-login">
                                    <button onClick={this.handleSubmit}>Acessar</button>
                                </div>
                            </div>
                        </form>
                        <div className="rodape">
                            <div className="sociais">
                                <a target="_blank" rel="noopener noreferrer" href={this.state.config.facebook}>
                                    <img srcSet={facebook} alt="" />
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href={this.state.config.linkedin}>
                                    <img srcSet={linkedin} alt="" />
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href={this.state.config.youtube}>
                                    <img srcSet={youtube} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;
