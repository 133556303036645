import React, { Component } from 'react';
import auth from '../utils/auth';
import cfg from '../utils/config';
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom'

import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap'
import Select from 'react-select'

class MenuBar extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            quantidadeAlerta: 0,
            intervalFn: false,
            redirectMinhaConta: false,
            redirectSearch: false,
            redirectSearchPath: false,
            empresaAnterior: JSON.parse(localStorage.getItem('__hidroview__me')).empresaAnterior || null,
            equipamentoSearch: [],
            modal: false,
            empresas: [],
            empresaSelecionada: false,
            empresaAtual: JSON.parse(localStorage.getItem('__hidroview__me')).empresa,
            empresaPai: JSON.parse(localStorage.getItem('__hidroview__me')).pai,
            historicoEmpresas: JSON.parse(localStorage.getItem('__hidroview__me')).historicoEmpresas || [],
            userInfo: auth.getUserInfo()
        };
        this.menuToggle = this.menuToggle.bind(this);
    }

    componentWillMount = () => {
        let userInfo = auth.getUserInfo()
        this.setState({ imagem_perfil: userInfo['imagem_perfil'] });
        
        this.checar_alertas()
       
        let intervalFn = setInterval(this.checar_alertas.bind(this), 30000);
        this.setState({ 'intervalFn': intervalFn })
    }

    componentWillUnmount = () => {
        clearInterval(this.state.intervalFn)
    }

    getEmpresas = () => {
        let empresaId = auth.getUserInfo().empresa
        let empresaAtual = JSON.parse(localStorage.getItem('__hidroview__me'))
        if (empresaAtual.empresa)
            empresaId = empresaAtual.empresa

        const config = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        axios.get(cfg.base_api_url + cfg.api_version + `/${empresaId}/empresa/`, config)
            .then(res => this.setState({ empresas: res.data }))
            .catch(res => console.log(res))

    }

    mudarEmpresa = () => {
        let { empresaSelecionada, empresaAtual, historicoEmpresas } = this.state
        if (!empresaSelecionada) return

        let mudanca = JSON.parse(localStorage.getItem('__hidroview__me'))
        mudanca.empresa = empresaSelecionada.id
        historicoEmpresas.push(empresaAtual)
        mudanca.historicoEmpresas = historicoEmpresas
        auth.setUserInfo(mudanca, true)
        window.location.reload()
    }

    retornarAoPai = () => {
        let mudanca = JSON.parse(localStorage.getItem('__hidroview__me'))
        let historicoEmpresas = mudanca.historicoEmpresas || []

        mudanca.empresa = historicoEmpresas.length > 0 ? historicoEmpresas.pop() : this.state.empresaPai

        mudanca.historicoEmpresas = historicoEmpresas
        auth.setUserInfo(mudanca, true)
        window.location.reload()
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleModal = () => {
        this.setState({ modal: !this.state.modal })
    }

    checar_alertas = () => {
        let userInfo = auth.getUserInfo()
        let requestCfg = {
            headers: {
                'Authorization': 'Bearer ' + auth.getToken()
            }
        }

        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo?.['empresa'] + '/checar-alerta/', requestCfg)
            .then(data => {
                this.setState({ quantidadeAlerta: data.data.total })
            })
            .catch(error => console.error(error))
    }

    buscar_equipamento = (e) => {
        if (e.target.value.length >= 2) {
            if (e.keyCode === 13) {
                let userInfo = auth.getUserInfo()
                let requestCfg = {
                    headers: {
                        'Authorization': 'Bearer ' + auth.getToken()
                    }
                }
                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo['empresa'] + '/buscar-equipamento/' + e.target.value + '/', requestCfg)
                    .then(data => {
                        if (data.data.length > 0) {
                            this.setState({ equipamentoSearch: data.data })
                        } else {
                            console.log("Nada Encontrado")
                        }
                    })
                    .catch(error => {

                    })
            }
        }
        if (e.target.value.length >= 0) {
            this.setState({ equipamentoSearch: [] })
        }
    }

    limpaPesquisa = () => {
        this.setState({ equipamentoSearch: [] })
    }

    menuToggle() {
        this.props.myMenu[0].classList.toggle("fixed-toggle")
        this.props.myBody[0].classList.toggle("fluid-toggle")
        window.dispatchEvent(new Event('resize'))
    }

    handleInput = (state, e) => {
        this.setState({ [state]: e.label })
    }

    render() {
        if (this.state.redirectSearch === true)
            return <Redirect to={this.state.redirectSearchPath} />

        const { empresaAtual, empresaPai } = this.state
        const colourStyles = {
            option: (styles) => {
                return {
                    ...styles,
                    color: '#000',
                    display: 'flex',
                    justifyContent: 'space-between'
                }
            },
        }

        let empresaOption = []
        if (this.state.empresas) {
            this.state.empresas.map((empresa, index) => {
                if (empresa.id !== empresaAtual) {
                    empresaOption.push(
                        {
                            value: index,
                            label: empresa.nome_fantasia,
                            empresa: empresa
                        }
                    )
                }
                return null
            })
        }

        return (
            <div className="hdv-top-menu-position">
                <Navbar className="hdv-menubar" color="" light expand="md">
                    <Nav>
                        <NavItem onClick={this.menuToggle}><i className="fa fa-bars fa-30px hdv-menu-image-top"></i></NavItem>
                        <NavItem>
                            <input style={{ backgroundColor: this.props.coresCfg.degrade_top_primeira_cor, border: '0px solid ' + this.props.coresCfg.degrade_top_primeira_cor, color: this.props.coresCfg.fonte_menu_lateral }} className="hdv-search-home-field" type="text" onKeyDown={this.buscar_equipamento} name="search" id="search" placeholder="Pesquise seus equipamentos..." />
                            <div className={(this.state.equipamentoSearch.length > 0) ? "hdv-search-results" : "hdv-noshow-item"} >
                                <ul>
                                    {
                                        this.state.equipamentoSearch.map((equip) => {
                                            return <li><Link onClick={this.limpaPesquisa} to={"/equipamento/" + equip.imei + "/status/"}>{equip.imei}</Link></li>
                                        })
                                    }
                                </ul>
                            </div>
                        </NavItem>
                    </Nav>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <Link to="/lista-alertas" className="nav-link">
                                    <i className="fa fa-bell fa-20px hdv-menu-image-top"></i> <span style={(this.state.quantidadeAlerta === 0) ? { 'backgroundColor': 'silver' } : { 'backgroundColor': 'red' }} className="hdv-alert-receive">{this.state.quantidadeAlerta}</span> 
                                </Link>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav>
                                    <span className="hdv-image-round" style={{
                                        backgroundImage: "url(" + this.state.imagem_perfil + ")",
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        width: '50px',
                                        height: '50px',
                                    }}>
                                        {/*<img className="hdv-user-image" alt="Minha Conta" srcSet={this.state.imagem_perfil} />*/}
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <Link to="/minha-conta" className="dropdown-item"><i className="fa fa-user"></i> Minha conta</Link>
                                    {
                                        this.state.userInfo?.tipo !== "cliente"
                                            ?
                                            <DropdownItem tag="a"
                                                onClick={() => {
                                                    this.toggleModal()
                                                    this.getEmpresas()
                                                }}>
                                                <p style={{ cursor: 'pointer', margin: 0 }}><i className="fa fa-industry"></i>Outras empresas</p>
                                            </DropdownItem>
                                            :
                                            null
                                    }
                                    <DropdownItem divider />
                                    <DropdownItem tag="a" onClick={() => { auth.clearAppStorage(); window.location.reload() }}>
                                        <i className="fa fa-close"></i> Sair
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Mudar empresa</ModalHeader>
                    <ModalBody>
                        <label>Empresas</label>
                        <Select
                            onChange={(e) => this.setState({ empresaSelecionada: e.empresa })}
                            options={empresaOption}
                            placeholder="Selecione"
                            styles={colourStyles}
                        />

                        <div className="float-right" style={{ marginTop: '25px' }}>
                            <Button
                                onClick={() => this.toggleModal()}>
                                Cancelar
                            </Button>
                            {' '}
                            <Button
                                color={empresaAtual === empresaPai ? "secondary" : "primary"}
                                style={{ color: empresaAtual === empresaPai ? '#000' : '#fff' }}
                                disabled={empresaAtual === empresaPai}
                                onClick={() => this.retornarAoPai()}>
                                Retornar à Empresa Principal
                            </Button>
                            {' '}
                            <Button
                                color="primary"
                                onClick={() => this.mudarEmpresa()}>
                                Mudar
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default MenuBar;