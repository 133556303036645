import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import HeaderReportDefault from '../../components/HeaderReportDefault'
import PdfDocument from "../../utils/PdfAnalogico";
import Select from 'react-select';
import ReactLoading from 'react-loading';
import moment from 'moment'
import CsvDownloader from 'react-csv-downloader';
import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';

/*import {
	LineChart, Line, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  } from 'recharts';*/

import {
	LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

import { PDFDownloadLink } from '@react-pdf/renderer';
const PNG = require('save-svg-as-png');


class Relatorio4a20 extends Component {

	state = {
		selectedOption: null,
		detalhesDefault: { "data": "", "cliente": "", "monitorado": "", "item": null, "cliente_nome": "", "rlt4a20": true },
		detalhesExtra: {},
		entradas4a20Option: [],
		linemax: 0,
		linemin: 0,
		configuracaoSelecionada: null,
		reporte: [],
		data: [],
		maiorvalor: { valor: 0 },
		menorvalor: { valor: 0 },
		mediavalor: { valor: 0 },
		tickFormatter: (tick) => moment(tick * 1000).format('HH:mm'),
		ticks: [
			moment("2019-04-02 00:00:00").format('X'),
			moment("2019-04-02 01:00:00").format('X'),
			moment("2019-04-02 02:00:00").format('X'),
			moment("2019-04-02 03:00:00").format('X'),
			moment("2019-04-02 04:00:00").format('X'),
			moment("2019-04-02 05:00:00").format('X'),
			moment("2019-04-02 06:00:00").format('X'),
			moment("2019-04-02 07:00:00").format('X'),
			moment("2019-04-02 08:00:00").format('X'),
			moment("2019-04-02 09:00:00").format('X'),
			moment("2019-04-02 10:00:00").format('X'),
			moment("2019-04-02 11:00:00").format('X'),
			moment("2019-04-02 12:00:00").format('X'),
			moment("2019-04-02 13:00:00").format('X'),
			moment("2019-04-02 14:00:00").format('X'),
			moment("2019-04-02 15:00:00").format('X'),
			moment("2019-04-02 16:00:00").format('X'),
			moment("2019-04-02 17:00:00").format('X'),
			moment("2019-04-02 18:00:00").format('X'),
			moment("2019-04-02 19:00:00").format('X'),
			moment("2019-04-02 20:00:00").format('X'),
			moment("2019-04-02 21:00:00").format('X'),
			moment("2019-04-02 22:00:00").format('X'),
			moment("2019-04-02 23:00:00").format('X')
		],
		tickDomain: { start: moment("2019-04-02 00:00:00").format('X'), end: moment("2019-04-02 23:00:00").format('X') },
		labelFormatter: (label) => moment.unix(label).format('HH:mm:ss'),
		unidade_selecionada: "",
		validacao_formulario: { data: true, cliente: true, monitorado: true, item: true, canal: true },
		carregando_dados: false,
		relatorio_gerado: false,
		gerando_pdf: false,
		pdfData: { chart_image: false },
		detalhes_da_empresa: { "logomarca": false, "endereco": "", "telefone": "", "email": "", "descricao_horario_atendimento": "" },
		abrir_pdf: false,
		tipo_canal: false,
		visualizando_varios_dias: false,
		dataAnalogica: [],
		Rlt4a20: true,
		create_pdf_link: false,
	}

	busca_canais4a20 = () => {

		if (this.state.detalhesDefault.item != null) {

			let userInfo = auth.getUserInfo();
			let bodyFormData = new FormData();

			bodyFormData.append('hidrohub__imei', this.state.detalhesDefault.item)

			axios({
				method: 'post',
				url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/filtro/',
				data: bodyFormData,
				headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
			})
				.then((response) => {
					let optionsCanais4a20 = []
					response.data.entrada4a20.forEach((c) => {
						optionsCanais4a20.push({ "value": c.canal, "label": "#" + c.canal + " - " + c.descricao })
					})

					this.setState({ "entradas4a20Option": optionsCanais4a20, configuracaoSelecionada: response.data, runupdate: false })
				})
				.catch((error) => console.log(error))
		}
	}

	buscar_dados_relatario = () => {

		this.setState({ carregando_dados: true, create_pdf_link: false })

		let not_validate = false
		let valiate_form = this.state.validacao_formulario
		for (let key in this.state.detalhesDefault) {
			if (this.state.detalhesDefault[key] === "" || this.state.detalhesDefault[key] === null) {
				valiate_form[key] = false
				not_validate = true
			} else {
				valiate_form[key] = true
			}

		}

		if (this.state.selectedOption === null) {
			valiate_form['canal'] = false
			not_validate = true
		} else {
			valiate_form['canal'] = true
		}

		if (not_validate === true) {
			this.setState({ validacao_formulario: valiate_form, carregando_dados: false })
			return
		}

		setTimeout(() => this.setState({ carregando_dados: false }), 3000)

		let userInfo = auth.getUserInfo();
		let bodyFormData = new FormData()
		let ticks = this.state.ticks
		let tickFormatter = this.state.tickFormatter
		let labelFormatter = this.state.labelFormatter
		let new_reporte = []
		let i = 0

		let data = this.state.detalhesDefault.data.split('-')
		let data_ini = data[0].split('/')
		let data_fim = data[1].split('/')

		let startDateChart = new Date(data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " 00:00:00");
		let endDateChart = new Date(data_fim[2] + "-" + data_fim[1] + "-" + data_fim[0] + " 23:00:00");

		let diffTime = Math.abs(endDateChart.getTime() - startDateChart.getTime());
		let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		let diffMonth = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
		diffMonth = diffMonth - 1
		if (diffDays > 1 && diffDays < 31) {
			ticks = []
			for (i; i < diffDays; i++) {
				let temp_date = new Date(data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " 00:00:00");
				ticks.push(temp_date.setDate(temp_date.getDate() + i) / 1000)
			}
			tickFormatter = (tick) => moment(tick * 1000).format('D')
			labelFormatter = (label) => moment.unix(label).format('D/M/YYYY')
		} else if (diffDays > 31) {
			ticks = []
			for (i; i < diffMonth; i++) {
				let temp_date = new Date(data_ini[2] + "-" + data_ini[1] + "-01 00:00:00");
				ticks.push(temp_date.setMonth(temp_date.getMonth() + i) / 1000)
			}
			tickFormatter = (tick) => moment(tick * 1000).format('M')
			labelFormatter = (label) => moment.unix(label).format('M/YYYY')
		} else if (moment.tz(moment(), userInfo.timezone).format('D') === data_ini[0]) {
			ticks = []
			for (i; i < parseInt(moment.tz(moment(), userInfo.timezone).format('HH')); i++) {
				let hour = String(i)
				if (i < 10) {
					hour = "0" + hour
				}
				ticks.push(moment(data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " " + hour + ":00:00").format('X'))
			}
			tickFormatter = (tick) => moment(tick * 1000).format('HH:mm')
			labelFormatter = (label) => moment.unix(label).format('HH:mm:ss')
		} else {
			ticks = []
			for (i; i < 24; i++) {
				let hour = String(i)
				if (i < 10) {
					hour = "0" + hour
				}
				ticks.push(moment(data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " " + hour + ":00:00").format('X'))
			}
			tickFormatter = (tick) => moment(tick * 1000).format('HH:mm')
			labelFormatter = (label) => moment.unix(label).format('HH:mm:ss')
		}

		bodyFormData.append('timestamp_dispositivo_ini', data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " 00:00:00");
		bodyFormData.append('timestamp_dispositivo_fim', data_fim[2] + "-" + data_fim[1] + "-" + data_fim[0] + " 23:59:00");
		bodyFormData.append('id_dispositivo', this.state.detalhesDefault.item);

		axios({
			method: 'post',
			url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reporte/4a20/',
			data: bodyFormData,
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
		})
			.then((response) => {

				let reporte = []
				let maior_valor = { valor: 0, data: "" }
				let menor_valor = { valor: 1000, data: "" }
				if (this.state.tipo_canal === 9) {
					maior_valor = { valor: -1000, data: "" }
					menor_valor = { valor: 0, data: "" }
				}
				let media_valor = 0
				let media_temporaria = 0
				let visualizando_varios_dias = false
				response.data.forEach((rp, idx) => {

					media_valor = media_valor + rp.entradas_4a20[this.state.selectedOption.value - 1]
					media_temporaria = -Math.abs((media_valor / idx))

					if (this.state.tipo_canal === 9) {
						if (maior_valor.valor < -Math.abs(rp.entradas_4a20[this.state.selectedOption.value - 1])) {
							maior_valor.valor = -Math.abs(rp.entradas_4a20[this.state.selectedOption.value - 1])
							maior_valor.data = moment(rp.timestamp_dispositivo * 1000).format("DD/MM/YYYY HH:mm:ss")
						}
						if (menor_valor.valor > -Math.abs(rp.entradas_4a20[this.state.selectedOption.value - 1]) && (media_temporaria * 2) < -Math.abs(rp.entradas_4a20[this.state.selectedOption.value - 1])) {
							menor_valor.valor = -Math.abs(rp.entradas_4a20[this.state.selectedOption.value - 1])
							menor_valor.data = moment(rp.timestamp_dispositivo * 1000).format("DD/MM/YYYY HH:mm:ss")
						}
					} else {
						if (maior_valor.valor < rp.entradas_4a20[this.state.selectedOption.value - 1]) {
							maior_valor.valor = rp.entradas_4a20[this.state.selectedOption.value - 1]
							maior_valor.data = moment(rp.timestamp_dispositivo * 1000).format("DD/MM/YYYY HH:mm:ss")
						}
						if (menor_valor.valor > rp.entradas_4a20[this.state.selectedOption.value - 1]) {
							if (rp.entradas_4a20[this.state.selectedOption.value - 1] === 0) {
								console.log(moment(rp.timestamp_dispositivo * 1000).format("DD/MM/YYYY HH:mm:ss"))
							}
							menor_valor.valor = rp.entradas_4a20[this.state.selectedOption.value - 1]
							menor_valor.data = moment(rp.timestamp_dispositivo * 1000).format("DD/MM/YYYY HH:mm:ss")
						}
					}

					if (this.state.tipo_canal === 9) {
						if ((media_temporaria * 2) < -Math.abs(rp.entradas_4a20[this.state.selectedOption.value - 1])) {
							reporte.push({ "t": rp.timestamp_dispositivo, "v": -Math.abs(rp.entradas_4a20[this.state.selectedOption.value - 1]) })
						}
					} else {
						reporte.push({ "t": rp.timestamp_dispositivo, "v": rp.entradas_4a20[this.state.selectedOption.value - 1] })
					}
				})
				console.log(menor_valor)
				if (this.state.tipo_canal === 9) {
					let temp = Object.assign({}, maior_valor);
					maior_valor = menor_valor
					menor_valor = temp
				}

				//SE ESTIVER VENDO MAIS E 1 DIA IREI TRAZER O MAIOR VALOR DO DIA
				if (diffDays > 1) {
					let temp_reporte = []
					for (i = 0; i < reporte.length; i++) {
						if (temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] === undefined) {
							temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')] = { maior: reporte[i]['v'], menor: reporte[i]['v'] }
						} else if (temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')]['maior'] < reporte[i]['v']) {
							temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')]['maior'] = reporte[i]['v']
						} else if (temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')]['menor'] > reporte[i]['v']) {
							temp_reporte[moment(reporte[i]['t'] * 1000).format('YYYY-MM-DD')]['menor'] = reporte[i]['v']
						}
					}
					//console.log(temp_reporte)
					for (let key in temp_reporte) {
						new_reporte.push({ t: moment(key + " 00:00:00").format('X'), maior: temp_reporte[key]['maior'], menor: temp_reporte[key]['menor'] })
					}
					visualizando_varios_dias = true
				}

				let data_to_csv = []
				data_to_csv = response.data.map(dt => {
					return {
						c1: String(this.convertToDate(dt.timestamp_dispositivo)).substring(5, 0),
						c2: String(this.convertToDate(dt.timestamp_dispositivo)).slice(6, 14),
						c3: Number(dt.entradas_4a20[0]).toFixed(1),
						c4: Number(dt.entradas_4a20[1]).toFixed(1),
						c5: Number(dt.entradas_4a20[2]).toFixed(1),
						c6: Number(dt.entradas_4a20[3]).toFixed(1),
						c7: Number(dt.entradas_4a20[4]).toFixed(1),
						c8: Number(dt.entradas_4a20[5]).toFixed(1),
						c9: Number(dt.entradas_4a20[6]).toFixed(1),
					}
				})

				this.setState({
					reporte: (diffDays > 1) ? new_reporte : reporte.reverse(),
					data: response.data,
					maiorvalor: maior_valor,
					menorvalor: menor_valor,
					mediavalor: (media_valor / reporte.length),
					ticks: ticks,
					tickFormatter: tickFormatter,
					//tickDomain: {start: parseInt(moment(data_ini[2]+"-"+data_ini[1]+"-"+data_ini[0] + " 00:00:00").format('X')), end: parseInt(moment(data_fim[2]+"-"+data_fim[1]+"-"+data_fim[0]+ " 23:00:00").format('X'))},
					tickDomain: (diffDays > 1) ? { start: parseInt(new_reporte[0].t), end: parseInt(new_reporte[new_reporte.length - 1].t) } : { start: parseInt(reporte.reverse()[0].t), end: parseInt(reporte.reverse()[reporte.reverse().length - 1].t) },
					labelFormatter: labelFormatter,
					carregando_dados: false,
					relatorio_gerado: true,
					visualizando_varios_dias: visualizando_varios_dias,
					dataAnalogica: data_to_csv
				})

				/*this.setState({
					reporte: (diffDays > 1) ? new_reporte : reporte,
					data: response.data,
					maiorvalor: maior_valor,
					menorvalor: menor_valor,
					linemin: -10,
					linemax: -25,
					mediavalor: ( media_valor / reporte.length ),
					ticks: ticks,
					tickFormatter: tickFormatter,
					tickDomain: {start: parseInt(moment(data_ini[2]+"-"+data_ini[1]+"-"+data_ini[0] + " 00:00:00").format('X')), end: parseInt(moment(data_fim[2]+"-"+data_fim[1]+"-"+data_fim[0]+ " 23:00:00").format('X'))},
					labelFormatter: labelFormatter,
					carregando_dados: false
				})*/
				//this.generateData()
			})
			.catch((error) => console.log(error))
	}

	convertToDate(stamp) {
		return moment(stamp * 1000).format('DD/MM H:mm:ss')
	}

	generateData = () => {
		if (this.state.relatorio_gerado) {
			let data = false
			data = this.state.data.map(dt => {
				return {
					c1: String(this.convertToDate(dt.timestamp_dispositivo)).substring(5, 0),
					c2: String(this.convertToDate(dt.timestamp_dispositivo)).slice(6, 14),
					c3: Number(dt.entradas_4a20[0]).toFixed(3),
					c4: Number(dt.entradas_4a20[1]).toFixed(3),
					c5: Number(dt.entradas_4a20[2]).toFixed(3),
					c6: Number(dt.entradas_4a20[3]).toFixed(3),
					c7: Number(dt.entradas_4a20[4]).toFixed(3),
					c8: Number(dt.entradas_4a20[5]).toFixed(3),
					c9: Number(dt.entradas_4a20[6]).toFixed(3),
				}
			})
			this.setState({ dataAnalogica: data })
		}
	}

	zoom_chart = (e) => {
		this.setState({ tickDomain: { start: this.state.data[e.startIndex].timestamp_dispositivo, end: this.state.data[e.endIndex].timestamp_dispositivo } })
	}

	handleChange4a20Select = (selectedOption, field) => {
		let configuracaoSelecionada = this.state.configuracaoSelecionada
		let lmin = 0
		let lmax = 0
		let unidade = ""
		let tipo_canal = false
		configuracaoSelecionada.entrada4a20.forEach((v) => {

			if (v.canal === selectedOption.value) {
				lmin = v.valor_minimo
				lmax = v.valor_maximo
				unidade = v.unidade__descricao
				tipo_canal = v.sinal_id
			}
		})

		if (this.state.data) {
			let reporte = []
			let maior_valor = { valor: -1000, data: "" }
			let menor_valor = { valor: 100, data: "" }
			let media_valor = 0
			this.state.data.forEach((rp) => {

				if (tipo_canal === 9) {
					if (maior_valor.valor < -Math.abs(rp.entradas_4a20[selectedOption.value - 1])) {
						maior_valor.valor = -Math.abs(rp.entradas_4a20[selectedOption.value - 1])
						maior_valor.data = moment(rp.timestamp_dispositivo * 1000).format("DD/MM/YYYY HH:mm:ss")
					}
					if (menor_valor.valor > -Math.abs(rp.entradas_4a20[selectedOption.value - 1])) {
						menor_valor.valor = -Math.abs(rp.entradas_4a20[selectedOption.value - 1])
						menor_valor.data = moment(rp.timestamp_dispositivo * 1000).format("DD/MM/YYYY HH:mm:ss")
					}
				} else {
					if (maior_valor.valor < rp.entradas_4a20[selectedOption.value - 1]) {
						maior_valor.valor = rp.entradas_4a20[selectedOption.value - 1]
						maior_valor.data = moment(rp.timestamp_dispositivo * 1000).format("DD/MM/YYYY HH:mm:ss")
					}
					if (menor_valor.valor > rp.entradas_4a20[selectedOption.value - 1]) {
						menor_valor.valor = rp.entradas_4a20[selectedOption.value - 1]
						menor_valor.data = moment(rp.timestamp_dispositivo * 1000).format("DD/MM/YYYY HH:mm:ss")
					}
				}

				media_valor = media_valor + rp.entradas_4a20[selectedOption.value - 1]
				if (tipo_canal === 9) {
					reporte.push({ "t": rp.timestamp_dispositivo, "v": -Math.abs(rp.entradas_4a20[selectedOption.value - 1]) })
				} else {
					reporte.push({ "t": rp.timestamp_dispositivo, "v": rp.entradas_4a20[selectedOption.value - 1] })
				}

				reporte.push({ "t": rp.timestamp_dispositivo, "v": rp.entradas_4a20[selectedOption.value - 1] })
			})
			this.setState({ selectedOption, linemax: lmax, linemin: lmin, unidade_selecionada: unidade, reporte: reporte, maiorvalor: maior_valor, menorvalor: menor_valor, mediavalor: (media_valor / reporte.length), tipo_canal: tipo_canal });
		} else {
			this.setState({ selectedOption, linemax: lmax, linemin: lmin, unidade_selecionada: unidade });
		}

		return null
	}

	getBase64FromImageUrl = (url) => {

		let img = document.getElementById('logomarca')

		let canvas = document.createElement("canvas");
		canvas.width = img.width;
		canvas.height = img.height;

		let ctx = canvas.getContext("2d");
		ctx.drawImage(img, 0, 0);

		let dataURL = canvas.toDataURL("image/png");

		return dataURL;
	}

	generateImage = () => {


		this.setState({ relatorio_gerado: false, gerando_pdf: true })

		/*let chartSVGVaz = ReactDOM.findDOMNode(this.vazChart).children[0];
		let svgURLVaz = new XMLSerializer().serializeToString(chartSVGVaz);
		let svgBlobVaz = new Blob([svgURLVaz], {type: "image/svg+xml;charset=utf-8"});

		let chartSVGVol = ReactDOM.findDOMNode(this.volChart).children[0];
		let svgURLVol = new XMLSerializer().serializeToString(chartSVGVol);
		let svgBlobVol = new Blob([svgURLVol], {type: "image/svg+xml;charset=utf-8"});

		let url_vazao = URL.createObjectURL(svgBlobVaz);
		let url_volume = URL.createObjectURL(svgBlobVol);*/

		let pdfData = this.state.pdfData

		PNG.svgAsPngUri(ReactDOM.findDOMNode(this.chart).children[0], {}, (pngBase64Vaz) => {
			pdfData.chart_image = pngBase64Vaz
			this.setState(pdfData)
		});

		let requestArray = []
		let userInfo = auth.getUserInfo();
		let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

		requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/configuracao-sistema/', config_me))

		axios.all(requestArray)
			.then(axios.spread((config) => {
				console.log(config)
				let detalhes_da_empresa = this.state.detalhes_da_empresa

				if (config.status === 200) {
					detalhes_da_empresa.logomarca = cfg.base_api_url + 'media/' + config.data.data.logomarca
					detalhes_da_empresa.endereco = config.data.data.endereco
					detalhes_da_empresa.telefone = config.data.data.telefone
					detalhes_da_empresa.email = config.data.data.email
					detalhes_da_empresa.descricao_horario_atendimento = config.data.data.descricao_horario_atendimento
				}

				detalhes_da_empresa.logomarca = this.getBase64FromImageUrl(detalhes_da_empresa.logomarca)

				this.setState({ detalhes_da_empresa, create_pdf_link: true })

				setTimeout(() => {

					let elm = document.getElementById('download-pdf').firstChild
					console.log(elm.href)
					window.open(elm.href, "_blank")

					this.setState({ relatorio_gerado: true, gerando_pdf: false })
				}, 5000);

			}));
	}

	retorna_pdf_link = () => {
		return (
			<PDFDownloadLink document={<PdfDocument data={{ "parametros": { "cliente_nome": this.state.detalhesDefault.cliente_nome, "data": this.state.detalhesDefault.data }, "descricao_chart": this.state.selectedOption.label, "charts": this.state.pdfData, "resumo": { minimo: this.state.menorvalor.valor, maximo: this.state.maiorvalor.valor }, "detalhes_da_empresa": this.state.detalhes_da_empresa, "unidade_selecionada": this.state.unidade_selecionada }} />} fileName="test.pdf">
			</PDFDownloadLink>
		)
	}

	renderColorfulLegendText = (value, entry) => {
		//const { color } = entry;
		//console.log(value, entry)
		const color = entry.payload.dot.stroke
		return <span style={{ color }}><span style={{ fontSize: '20px' }}>&#9679;</span> {value}</span>;
	}

	reset_canal_and_entrada = () => {
		this.setState({ selectedOption: null})
	}

	render() {
		const { selectedOption } = this.state;
		console.log(this.state)

		const columns = [
			{ id: 'c1', displayName: 'Data' },
			{ id: 'c2', displayName: 'Hora' },
			{ id: 'c3', displayName: "Canal 1" },
			{ id: 'c4', displayName: "Canal 2" },
			{ id: 'c5', displayName: "Canal 3" },
			{ id: 'c6', displayName: "Canal 4" },
			{ id: 'c7', displayName: "Canal 5" },
			{ id: 'c8', displayName: "Canal 6" },
			{ id: 'c9', displayName: "Canal 7" }
		]

		const { dataAnalogica } = this.state

		return (
			<div className="hdv-render-div">
				<div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
					<h3>Relatório de Entradas Analógicas</h3>
					<div id="download-pdf">
						{(this.state.create_pdf_link === true) ? this.retorna_pdf_link() : ""}
					</div>
					<div className="row hdv-header-filters">
						<HeaderReportDefault DataChange={this.state.detalhesDefault} reset_canal_and_entrada={this.reset_canal_and_entrada} fn={this.busca_canais4a20} validacao={this.state.validacao_formulario} />
						<div className="col-md-12 hdv-bloco-top-space">
							<label>Canal</label>
							<Select
								value={selectedOption}
								onChange={this.handleChange4a20Select}
								options={this.state.entradas4a20Option}
							/>
							<span className={(this.state.validacao_formulario.canal === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
						</div>
						<div className="col-md-12">
							<button
								disabled={this.state.carregando_dados}
								className={(this.state.carregando_dados === true) ? "hdv-header-filters-button hdv-generate-report hdv-block-button" : "hdv-header-filters-button hdv-generate-report"}
								onClick={this.buscar_dados_relatario} >Gerar Relatório</button>
							<button
								disabled={!this.state.relatorio_gerado}
								className={(this.state.relatorio_gerado === false) ? "hdv-header-filters-button hdv-generate-pdf hdv-block-button" : "hdv-header-filters-button hdv-generate-pdf hdv-block-pdf-border"}
								onClick={this.generateImage} > <i className="fa fa-file-pdf-o fa-20px"></i> Exportar PDF</button>

							<button
								style={{ backgroundColor: '#4da845', width: 'fit-content', height: '42px', margin: '15px', borderRadius: '4px', border: 'none', cursor: 'pointer' }}
								className={(this.state.relatorio_gerado ? "" : "hdv-block-button")}
								disabled={!this.state.relatorio_gerado}>
								{this.state.relatorio_gerado
									?
									<CsvDownloader
										columns={columns}
										datas={dataAnalogica}
										filename="rel_entradas_analogicas.csv"
										separator=";">
										<p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '8px 10px' }}>
											<i className="fa fa-file fa-20px"></i> Exportar CSV</p>
									</CsvDownloader>
									:
									<p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '8px 10px' }}>
										<i className="fa fa-file fa-20px"></i>Exportar CSV
									</p>
								}
							</button>

							<ReactLoading className={(this.state.gerando_pdf === true) ? "hdv-report-loading-left" : "hdv-noshow-item"} type="cylon" color="skyblue" height={15} width={50} />
							<ReactLoading className={(this.state.carregando_dados === true) ? "hdv-report-loading" : "hdv-noshow-item"} type="cylon" color="#589bd4" height={15} width={50} />
						</div>
					</div>
					<div className={(this.state.data.length > 0) ? "row" : "row hdv-noshow-item"}>
						<div className="col-md-2" style={{ padding: '0 0' }}>
							<div className="hdv-resumo-relatorio">Resumo</div>
							<div className="hdv-resumo-content">
								<div className="col-md-12 hdv-resumo-titulo">Máximo Registrado: <strong> {this.state.maiorvalor.valor.toFixed(1)}</strong> {this.state.unidade_selecionada} </div>
								<div className="col-md-12 hdv-resumo-titulo">Mínimo  Registrado: <strong> {this.state.menorvalor.valor.toFixed(1)}</strong> {this.state.unidade_selecionada}</div>
							</div>
						</div>
						<div className="col-md-10" style={{ padding: '0 0' }}>
							<div style={{ width: '100%', height: 500 }}>
								<ResponsiveContainer>
									<LineChart
										ref={(chart) => this.chart = chart}
										width={500}
										height={300}
										data={this.state.reporte}
										margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
										syncId="Chart00">
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="t"
											type="number"
											tickFormatter={this.state.tickFormatter}
											domain={[this.state.tickDomain.start, this.state.tickDomain.end]}
											ticks={this.state.ticks}
											padding={{ left: 20, right: 20 }}
										/>
										<YAxis />
										<Tooltip
											labelFormatter={this.state.labelFormatter}
											formatter={(value) => parseFloat(value).toFixed(1).toString() + " " + this.state.unidade_selecionada}
										/>
										<Legend formatter={this.renderColorfulLegendText} />
										{/*<ReferenceLine y={this.state.linemax} label="Max" stroke="#ff0000" />
										<ReferenceLine y={this.state.linemin} label="Min" stroke="#1fff00" />*/}
										{(this.state.visualizando_varios_dias === true && this.state.tipo_canal === 9)
											?
											<Line type="linear" name="Máximo do dia" strokeWidth={4} dataKey="menor" stroke="none" dot={{ stroke: 'green' }} legendType="circle" />
											: ""}

										{(this.state.visualizando_varios_dias === true && this.state.tipo_canal === 9)
											?
											<Line type="linear" name="Mínimo do dia" strokeWidth={4} dataKey="maior" stroke="none" dot={{ stroke: 'blue' }} legendType="circle" />
											: ""}

										{(this.state.visualizando_varios_dias === true && this.state.tipo_canal !== 9)
											?
											<Line type="linear" name="Mínimo do dia" strokeWidth={4} dataKey="menor" stroke="none" dot={{ stroke: 'blue' }} legendType="circle" />
											: ""}

										{(this.state.visualizando_varios_dias === true && this.state.tipo_canal !== 9)
											?
											<Line type="linear" name="Máximo do dia" strokeWidth={4} dataKey="maior" stroke="none" dot={{ stroke: 'green' }} legendType="circle" />
											: ""}


										{(this.state.visualizando_varios_dias === false)
											?
											<Line type="linear" name="Valor" strokeWidth={4} dataKey="v" dot={false} />
											: ""}
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Relatorio4a20;