import  React, { Component } from 'react';
import helper_fn from '../../../utils/helper'

class VolumeDiaMes extends Component {

    state = {
        params: {
            descricao: "",
            comunicacao: false,
            value: 150,
            metric: "m",
            params: false,
            update: false,
            type_el: false,
        }
    }

    componentWillMount = () => {
        if(this.props.datawgt)
            this.setState({params: this.props.datawgt})
    }

    componentWillReceiveProps = (nextProps) => {
        if(this.props.datawgt.value !== nextProps.datawgt.value)
            this.setState({params: nextProps.datawgt})
    }

    render() {

        let value_show = parseFloat(this.state.params.value).toFixed(2)
        if(this.state.params.type_el === 2){
            value_show = String(helper_fn.converte_decimal_to_hhmmss(this.state.params.value))
        }

        return (
            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                <div className="hdv-top-bar-bloco">
                    {(this.state.params.comunicacao === false)
                    ?
                    <i style={{color: "limegreen", zIndex: 999999, position: "relative"}} alt="Informação Atualizada"  title="Informação Atualizada" className="fa fa-square-full"></i>
                    :
                    <i style={{color: "tomato", zIndex: 999999, position: "relative"}} alt="Informação com Atraso"  title="Informação com Atraso" className="fa fa-square-full"></i>
                    }
                    {decodeURI(this.state.params.descricao)}
                </div>
                <div className="box-simple-value">
                    <div className="bar-value">
                        <div className="layout-inlineflex"><strong className={"value-super"}>{value_show}</strong></div>
                        <div className="hdv-metric-text">{this.state.params.metric}</div>
                    </div>
                </div>
            </div>
        )

    }

}

export default VolumeDiaMes;