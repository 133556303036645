import React, { Component } from 'react';
import { Redirect } from "react-router-dom"
import { WidthProvider, Responsive } from "react-grid-layout";
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select'
import { v4 as uuidv4 } from 'uuid';

import moment from 'moment';
import 'moment-timezone';

import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';

import Reservatorio from './Elementos/Reservatorio'
import GraficoBarra from './Elementos/GraficoBarra'
import GraficoLinha from './Elementos/GraficoLinha'
import Imagem from './Elementos/Imagem'
import MaximoAtual from './Elementos/MaximoAtual'
import Poco from './Elementos/Poco'
import ValorAtual from './Elementos/ValorAtual'
import ValorAtualV2 from './Elementos/ValorAtualV2'
import Status from './Elementos/Status'
import VolumeDia from './Elementos/VolumeDia'
import VolumeDiaMes from './Elementos/VolumeDiaMes'

import './style.css'

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class Supervisorio extends Component {

    state = {
        cores_cfg: { "degrade_top_primeira_cor": "#F44E3B", "degrade_top_segunda_cor": "#2f577b", "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7", "fonte_grupo_menu_lateral": "#c7c7c7" },
        opacity: 0,
        logomarca: '',
        layouts: JSON.parse(localStorage.getItem('layouts')),
        gaugeRef: { h: 150 },
        modal: false,
        modal_widget: false,
        modal_delete: false,
        modal_apresentacao: false,
        intervalo_apresentacao: { label: '1 Minuto', value: 1 },
        apresentacao_index: 0,
        apresentacao_fn: null,
        novo_supervisorio: { descricao: false, tipo: false, cliente_id: false },
        supervisorios: [],
        supervisorio_selecionado: false,
        clientes: [],
        novo_widget: { id_widget: false, descricao_widget: false, equipamento: false, campo: false, calcular_volume: false, parametros: false },
        widgets: [],
        widgets_disponiveis: [
            {
                id: 1, descricao: "Reservatório (%)", parametros: [
                    { field: "valor_maximo", label: "Valor Máximo", type: "float", optional: false },
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                ]
            },
            {
                id: 2, descricao: "Valor Atual", parametros: [
                    { field: "valor_normal", label: "Valor Normal", type: "float", optional: true },
                    { field: "valor_alerta", label: "Valor Alerta", type: "float", optional: true },
                    { field: "valor_perigo", label: "Valor Perigoso", type: "float", optional: true },
                    { field: "icone", label: "Icone", type: "text", optional: true, fn: () => this.showSelectedIcons() },
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                ]
            },
            /*{id: 3, descricao: "Imagem", parametros: [
                {field: "image", label: "Selecionar Imagem", type: "file", optional: false}
            ]},*/
            {
                id: 4, descricao: "Valor Máximo x Atual", parametros: [
                    { field: "valor_maximo", label: "Valor Máximo", type: "float", optional: false },
                    { field: "valor_alerta", label: "Valor Alerta", type: "float", optional: false },
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                    /*{field: "referencias", label: "Referências", type: "array", array_fields: [{field: "descricao", label: "Descrição", type: "text"}, {field: "valor", label: "Valor", type: "float"}], optional: false}*/
                ]
            },
            {
                id: 5, descricao: "Poço", parametros: [
                    { field: "instalacao_bomba", label: "Instalação da Bomba (metros)", type: "float", optional: false },
                    { field: "instalacao_sensor", label: "Instalação do Sensor (metros)", type: "float", optional: false },
                    { field: "nivel_estatico", label: "Nível Estático (metros)", type: "float", optional: false },
                    { field: "campo_vazao", label: "Vazão", type: "select_field", optional: false },
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                ]
            },
            {id: 6, descricao: "Gráfico de Linhas", parametros: [
                {field: "valor_minimo", label: "Valor Minimo", type: "float", optional: true},
                {field: "valor_maximo", label: "Valor Máximo", type: "float", optional: true},
                { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
            ]},
            {id: 7, descricao: "Gráfico de Barras", parametros: [
                { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
            ]},
            {id: 8, descricao: "Status", parametros: [
                {field: "on", label: "Descrição Ligado", type: "text", optional: false},
                {field: "off", label: "Descrição Desligado", type: "text", optional: false},
                { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
            ]},
            {
                id: 9, descricao: "Volume Diário", parametros: [
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                ]
            },
            {
                id: 10, descricao: "Valor Atual v2", parametros: [
                    { field: "valor_normal", label: "Valor Normal", type: "float_between", optional: true },
                    { field: "valor_alerta", label: "Valor Alerta", type: "float_between", optional: true },
                    { field: "valor_perigo", label: "Valor Perigoso", type: "float_between", optional: true },
                    { field: "icone", label: "Icone", type: "text", optional: true, fn: () => this.showSelectedIcons() },
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                ]
            },
            {
                id: 11, descricao: "Volume Mensal", parametros: [
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                ]
            },
        ],
        equipamentos: [{ id: 10, equipamento__imei: "125485646", monitorado: "monitorado__nome" }],
        equipamento_vinculados: [],
        equipamentos_data: {},
        campos_disponiveis_equipamento_selecionado: [],
        html_campos: [],
        redirect_home: false,
        need_update: false,
        block_screen: false,
        show_icons: false,
        intervalFn: false,
    }

    converte_data_timezone = (data_utc) => {
        let data_in_timezone_user = moment.tz(data_utc, 'UTC')
        let data_formatada = data_in_timezone_user.tz(auth.getUserInfo().timezone)
        return data_formatada
    }

    apresentacao_slide = () => {
        let { supervisorios, apresentacao_index } = this.state
        let proximo = apresentacao_index + 1
        if (proximo === supervisorios.length)
            proximo = 0

        let e = { target: '' }
        e['target'] = { value: supervisorios[proximo].id }
        this.select_supervisory(e)
        this.setState({ apresentacao_index: proximo })
    }

    start_apresentacao = () => {
        const { intervalo_apresentacao } = this.state
        this.setState({ apresentacao_fn: setInterval(this.apresentacao_slide, intervalo_apresentacao.value * 60000), })
    }

    stop_apresentacao = () => {
        window.clearInterval(this.state.apresentacao_fn)
        this.setState({ apresentacao_fn: null })
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    toggle_delete = () => {
        this.setState(prevState => ({
            modal_delete: !prevState.modal_delete
        }));
    }

    toggle_apresetacao = () => this.setState({ modal_apresentacao: !this.state.modal_apresentacao })

    toggleAddNovoWidget = () => {
        this.setState(prevState => ({
            modal_widget: !prevState.modal_widget
        }));
    }

    showSelectedIcons = () => {
        this.setState({ block_screen: true, show_icons: true })
    }

    componentWillMount = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/configuracao-cores/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/configuracao-sistema/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/supervisorio/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/', config_me))

        axios.all(requestArray)
            .then(axios.spread((cores_cfg, config, supervisorios, clientes) => {

                let cores = { "degrade_top_primeira_cor": "#F44E3B", "degrade_top_segunda_cor": "#2f577b", "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7", "fonte_grupo_menu_lateral": "#c7c7c7" }
                let logomarca = ''
                let supervisorios_lista = []
                let clientes_lista = []

                if (cores_cfg.status === 200) {
                    cores = cores_cfg.data
                }

                if (config.status === 200) {
                    logomarca = config.data.data.logomarca
                }

                if (supervisorios.status === 200) {
                    supervisorios_lista = supervisorios.data
                }

                if (clientes.status === 200) {
                    clientes_lista = clientes.data
                }

                this.setState({ cores_cfg: cores, opacity: 1, logomarca: logomarca, supervisorios: supervisorios_lista, clientes: clientes_lista })
            }));
        let intervalFn = setInterval(this.auto_update_data.bind(this), 60000);
        this.setState({ 'intervalFn': intervalFn })
    }

    componentWillUnmount = () => {
        clearInterval(this.state.intervalFn)
        clearInterval(this.state.apresentacao_fn)
    }

    new_supervisory = (e) => {
        let new_obj = this.state.novo_supervisorio
        if (e.target.value === "") {
            new_obj[e.target.id] = false
        } else {
            new_obj[e.target.id] = e.target.value
        }
        this.setState({ novo_supervisorio: new_obj })
    }

    save_supervisory = () => {

        let userInfo = auth.getUserInfo();
        let bodyFormData = new FormData();

        for (var key in this.state.novo_supervisorio) {
            if (this.state.novo_supervisorio[key] === false) {
                bodyFormData.append(key, "");
            } else {
                bodyFormData.append(key, this.state.novo_supervisorio[key]);
            }
        }

        axios({
            method: 'post',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/supervisorio/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (response.status === 201) {
                    axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/supervisorio/', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() } })
                        .then((response) => {
                            if (response.status === 200)
                                this.setState({ supervisorios: response.data, supervisorio_selecionado: false, widgets: [] });
                        })
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    /*getData = () => {
        let resource = "aplicacao"
        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
        .then((response)=> {
            delete response.data.id
            this.setState({detalhes: response.data, selectedOption: {value: response.data.cliente_id, label: response.data.cliente__nome_fantasia}});
        })
    }*/

    filter_equipament = (e) => {

        if (e.target.value === "") {
            return
        }

        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        let cond = [{ "id": "cliente_id", "value": e.target.value, "type": "int" }]
        let requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/equipamento-monitorado/?filter=' + encodeURI(JSON.stringify(cond));

        axios.get(requestURL, config_me)
            .then((equipamentos_disponiveis) => {
                if (equipamentos_disponiveis.data.length > 0) {
                    this.setState({ equipamentos: equipamentos_disponiveis.data })
                } else {
                    this.setState({ equipamentos: [] })
                }
            })
    }

    select_supervisory = (e) => {
        let supervisory = false

        if (e.target.value) {
            supervisory = this.state.supervisorios.filter((sup) => {
                return sup.id === parseInt(e.target.value)
            })[0]
        }

        if (!supervisory) {
            this.setState({ supervisorio_selecionado: false, widgets: [], })
            return
        }

        this.setState({ block_screen: true })

        let userInfo = auth.getUserInfo();
        let requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/equipamento-monitorado/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        if (supervisory.tipo === "cliente") {
            let cond = [{ "id": "cliente_id", "value": supervisory.cliente_id, "type": "int" }]
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/equipamento-monitorado/?filter=' + encodeURI(JSON.stringify(cond));
        }

        axios.get(requestURL, config_me)
            .then((equipamentos_disponiveis) => {
                let widgets = (supervisory.widgets) ? JSON.parse(supervisory.widgets) : []
                let equipamento_vinculados = []
                if (widgets.length > 0) {
                    for (let index = 0; index < widgets.length; index++) {
                        if (!equipamento_vinculados.includes(widgets[index].equipamento) && widgets[index].equipamento !== false)
                            equipamento_vinculados.push(widgets[index].equipamento)
                    }
                }

                if (equipamento_vinculados.length > 0) {
                    let data_send = new FormData()
                    data_send.append("equipamentos", JSON.stringify(equipamento_vinculados))

                    const apresentacao_index = this.state.supervisorios.indexOf(supervisory)
                    axios.post(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reporte/supervisorio/', data_send, config_me)
                        .then((reportes) => {
                            this.setState({ block_screen: false, equipamentos_data: reportes.data, equipamento_vinculados: equipamento_vinculados, equipamentos: equipamentos_disponiveis.data, need_update: false, supervisorio_selecionado: supervisory, apresentacao_index, widgets: widgets, layouts: (supervisory.layout) ? JSON.parse(supervisory.layout) : [] })
                        })

                } else {
                    this.setState({ block_screen: false, equipamento_vinculados: equipamento_vinculados, equipamentos: equipamentos_disponiveis.data, need_update: false, supervisorio_selecionado: supervisory, widgets: widgets, layouts: (supervisory.layout) ? JSON.parse(supervisory.layout) : [] })
                }
            })

    }

    auto_update_data = () => {
        if (this.state.supervisorio_selecionado !== false) {
            //console.log("Atualiza AUTOMATICO")
            //this.setState({block_screen: true})
            let userInfo = auth.getUserInfo();
            let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
            let data_send = new FormData()
            data_send.append("equipamentos", JSON.stringify(this.state.equipamento_vinculados))

            axios.post(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reporte/supervisorio/', data_send, config_me)
                .then((reportes) => {
                    let data_ok = true
                    for (let item in reportes.data) {
                        if (reportes.data[item] === false)
                            data_ok = false
                    }
                    if(data_ok === false){
                        console.log("Atualização Ignorada")
                    }else{
                        this.setState({ block_screen: false, equipamentos_data: reportes.data })
                    }
                })
        }
    }

    update_supervisory = () => {
        console.log('Atualização disponível')
        this.setState({ need_update: true })
    }

    action_update_supervisory = () => {

        let supervisorio_selecionado = this.state.supervisorio_selecionado
        let userInfo = auth.getUserInfo();
        var bodyFormData = new FormData();

        for (var key in supervisorio_selecionado) {
            if (supervisorio_selecionado[key] !== 'cliente__nome_fantasia' && supervisorio_selecionado[key] !== 'id')
                bodyFormData.append(key, supervisorio_selecionado[key]);
        }

        axios({
            method: 'patch',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/supervisorio/' + supervisorio_selecionado.id + '/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                console.log('Atualizado com sucesso!')
                this.setState({ need_update: false })
            })
            .catch((error) => {
                console.log(error);
            })

    }

    delete_supervisory = () => {
        console.log('DELETANDO SUPERVISORIO')
        let userInfo = auth.getUserInfo();
        let bodyFormData = new FormData();

        axios({
            method: 'delete',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/supervisorio/' + this.state.supervisorio_selecionado.id + '/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                console.log('Removido com sucesso!')
                if (response.status === 200) {
                    axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/supervisorio/', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() } })
                        .then((response) => {
                            if (response.status === 200)
                                this.setState({ supervisorios: response.data, supervisorio_selecionado: false, widgets: [] });
                        })
                }

            })
            .catch((error) => {
                console.log(error);
            })

    }

    new_widget = (e) => {
        let new_obj = this.state.novo_widget
        if (e.target.value === "") {
            new_obj[e.target.id] = false
        } else {
            let value = e.target.value
            if (e.target.id === "descricao_widget")
                value = encodeURI(value)
            new_obj[e.target.id] = value
        }
        this.setState({ novo_widget: new_obj })
    }

    delete_widget = (id) => {
        console.log('Deletando widget')
        let widgets = this.state.widgets
        let supervisorio_selecionado = this.state.supervisorio_selecionado
        let widgets_novo = widgets.filter((w) => {
            return w.id !== id
        })
        supervisorio_selecionado['widgets'] = JSON.stringify(widgets_novo)
        this.setState({ widgets: widgets_novo, supervisorio_selecionado: supervisorio_selecionado })
    }

    get_equipament_field = (e) => {

        let new_obj = this.state.novo_widget
        if (e.target.value === "") {
            new_obj[e.target.id] = false
        } else {
            new_obj[e.target.id] = e.target.value
        }

        let userInfo = auth.getUserInfo();
        let requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/buscar-entradas-hidrohub/' + e.target.value + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };


        axios.get(requestURL, config_me)
            .then((campos_equipamento) => {

                let entradasCampos = []
                if (campos_equipamento.status === 200) {
                    campos_equipamento.data.entrada4a20.forEach((value) => {
                        entradasCampos.push({ label: "#" + value.canal + ' ' + value.descricao, value: 'entrada4a20_' + value.canal, dataType: "text" })
                    })
                    campos_equipamento.data.entradalogica.forEach((value) => {
                        if (value.tipo_entrada_logica_id === 3) {
                            entradasCampos.push({ label: "#" + value.canal + ' ' + value.tipo_entrada_logica__descricao, value: 'status_' + value.canal, dataType: "text" })
                        } else {
                            entradasCampos.push({ label: "#" + value.canal + ' ' + value.tipo_entrada_logica__descricao, value: 'entradalogica_' + value.canal, dataType: "text" })
                            entradasCampos.push({ label: "#" + value.canal + ' Vazão', value: 'vazao_' + value.canal, dataType: "text" })
                        }
                    })
                    campos_equipamento.data.outros_campos.forEach((value) => {
                        entradasCampos.push({ label: value.label, value: value.value })
                    })
                }

                this.setState({ campos_disponiveis_equipamento_selecionado: entradasCampos, novo_widget: new_obj })
            })

    }

    show_widget_params = (e) => {

        let new_obj = this.state.novo_widget
        if (e.target.value === "") {
            new_obj[e.target.id] = false
        } else {
            new_obj[e.target.id] = e.target.value
        }

        let select_widget = this.state.widgets_disponiveis.filter((widget) => {
            return widget.id === parseInt(e.target.value)
        })[0]

        let fields = select_widget.parametros.map((field, key) => {
            //console.log(field)
            switch (field.type) {
                case 'file':
                    return <div key={key}>
                        <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>{field.label}</label>
                        <input onChange={this.save_subparam_field} type="file" className="hdv-barsuper-input" id={field.field} name={field.field} />
                    </div>

                case 'select_field':
                    return <div key={key}>
                        <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>{field.label}</label>
                        <select onChange={this.save_subparam_field} id={field.field} name={field.field} className="hdv-barsuper-select">
                            <option value="">-- Campos Disponíveis --</option>
                            {
                                this.state.campos_disponiveis_equipamento_selecionado.map((campo, key) => {
                                    return <option key={key} value={campo.value}>{campo.label}</option>
                                })
                            }
                        </select>
                    </div>
                case 'float_between':
                    return <div key={key}>
                        <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>{field.label}</label>
                        <input onChange={(e) => this.save_subparam_between(field.field, "min", e.target.value)} type="text" className="hdv-barsuper-input" id={field.field} name={field.field} />
                        &nbsp; Até &nbsp;
                        <input onChange={(e) => this.save_subparam_between(field.field, "max", e.target.value)} type="text" className="hdv-barsuper-input" id={field.field} name={field.field} />
                    </div>

                default:
                    return <div key={key}>
                        <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>{field.label}</label>
                        {
                            ("fn" in field)
                                ?
                                <input onClick={field.fn} onChange={this.save_subparam_field} type="text" className="hdv-barsuper-input" id={field.field} name={field.field} />
                                :
                                <input onChange={this.save_subparam_field} type="text" className="hdv-barsuper-input" id={field.field} name={field.field} />
                        }

                    </div>
            }
        })

        new_obj['parametros'] = {}
        select_widget.parametros.forEach((field, key) => {
            new_obj['parametros'][field.field] = false
        })

        this.setState({ novo_widget: new_obj, html_campos: fields })
    }

    save_subparam_field = (e) => {

        let new_obj = this.state.novo_widget
        if (e.target.value === "") {
            new_obj.parametros[e.target.id] = false
        } else {
            new_obj.parametros[e.target.id] = e.target.value
        }

        this.setState({ novo_widget: new_obj })

    }

    save_subparam_between = (field, ref, value) => {

        let new_obj = this.state.novo_widget

        if (typeof(new_obj.parametros[field]) !== 'object')
            new_obj.parametros[field] = {}

        if (value === "") {
            new_obj.parametros[field][ref] = false
        } else {
            new_obj.parametros[field][ref] = value
        }

        this.setState({ novo_widget: new_obj })

    }

    save_subparam_icone = (e) => {
        document.getElementById('icone').value = e.target.id
        let new_obj = this.state.novo_widget
        if (e.target.id === "") {
            new_obj.parametros['icone'] = false
        } else {
            new_obj.parametros['icone'] = e.target.id
        }
        this.setState({ block_screen: false, show_icons: false, novo_widget: new_obj })
    }

    save_new_widget = () => {
        this.setState({ block_screen: true })
        let widget = this.state.novo_widget
        let widgets = this.state.widgets
        let objWidget = { id: uuidv4(), type: widget.id_widget, descricao: widget.descricao_widget, equipamento: widget.equipamento, campo: widget.campo, calcular_volume: widget.calcular_volume, parametros: widget.parametros }
        widgets.push(objWidget)

        let supervisorio = this.state.supervisorio_selecionado
        supervisorio['widgets'] = JSON.stringify(widgets)

        let equipamento_vinculados = []
        if (widgets.length > 0) {
            for (let index = 0; index < widgets.length; index++) {
                if (!equipamento_vinculados.includes(widgets[index].equipamento) && widgets[index].equipamento !== false)
                    equipamento_vinculados.push(widgets[index].equipamento)
            }
        }

        if (equipamento_vinculados.length > 0) {
            let userInfo = auth.getUserInfo();
            let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

            let data_send = new FormData()
            data_send.append("equipamentos", JSON.stringify(equipamento_vinculados))
            axios.post(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reporte/supervisorio/', data_send, config_me)
                .then((reportes) => {
                    this.setState({ block_screen: false, equipamentos_data: reportes.data, equipamento_vinculados: equipamento_vinculados, widgets: widgets, novo_widget: { id_widget: false, descricao_widget: false, equipamento: false, campo: false, calcular_volume: false, parametros: false }, need_update: true, supervisorio_selecionado: supervisorio, layouts: (supervisorio.layout) ? JSON.parse(supervisorio.layout) : [] })
                    this.update_supervisory(supervisorio)
                })

        }
        //this.setState({widgets: widgets, novo_widget: {id_widget: false, descricao_widget: false, equipamento: false, campo: false, calcular_volume: false, parametros: false}, supervisorio_selecionado: supervisorio})
        //this.update_supervisory(supervisorio)
    }

    handleIntevalo = (e) => this.setState({ intervalo_apresentacao: e })

    converte_data_timezone_format = (data_utc, format) => {
        let data_in_timezone_user = moment.tz(data_utc, 'UTC')
        let data_formatada = data_in_timezone_user.tz(auth.getUserInfo().timezone).format(format)
        return data_formatada
    }

    render() {
        console.log(this.state)

        if (this.state.redirect_home)
            return <Redirect to={'/'} />

        return (
            <div style={{ opacity: this.state.opacity, backgroundColor: "#f2f2f2" }}>
                <Modal isOpen={this.state.modal_apresentacao} toggle={this.toggle_apresetacao} className={this.props.className}>
                    <ModalHeader toggle={this.toggle_apresetacao}>Modo Apresentação</ModalHeader>
                    <ModalBody>
                        <div style={{ padding: '25px 10px' }}>
                            <label className="required">Selecione o intervalo</label>
                            <Select
                                value={this.state.intervalo_apresentacao}
                                onChange={(e) => this.handleIntevalo(e)}
                                options={[
                                    { label: '1 Minuto', value: 1 },
                                    { label: '2 Minutos', value: 2 },
                                    { label: '3 Minutos', value: 3 },
                                    { label: '5 Minutos', value: 5 },
                                    { label: '10 Minutos', value: 10 },
                                    { label: '15 Minutos', value: 15 },
                                ]}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.apresentacao_fn === null ?
                            <Button color="success" onClick={this.start_apresentacao}>Começar</Button>
                            : <Button color="danger" onClick={this.stop_apresentacao}>Parar</Button>}{' '}
                        <Button color="default" onClick={this.toggle_apresetacao}>Fechar</Button>
                    </ModalFooter>
                </Modal>

                <Modal size="lg" isOpen={this.state.modal_delete} toggle={this.toggle_delete} className={this.props.className}>
                    <ModalHeader toggle={this.toggle_delete}>Deletar Supervisório</ModalHeader>
                    <ModalBody>
                        <div>
                            Deseja realmente deletar o Supervisório?
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => { this.delete_supervisory(); this.toggle_delete() }}>Deletar</Button>{' '}
                        <Button color="default" onClick={this.toggle_delete}>Sair</Button>{' '}
                    </ModalFooter>
                </Modal>

                <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Criar novo Supervisório</ModalHeader>
                    <ModalBody>
                        <div>
                            <label className="hdv-barsuper-label" htmlFor="descricao">Descrição</label>
                            <input onChange={this.new_supervisory} className="hdv-barsuper-input" type="text" id="descricao" name="descricao" />
                            <label className="hdv-barsuper-label hdv-barsuper-label-margin" htmlFor="tipo">Tipo</label>
                            Cliente <input onChange={this.new_supervisory} type="radio" id="tipo" name="tipo" value="cliente" />
                            Empresa <input onChange={this.new_supervisory} type="radio" id="tipo" name="tipo" value="empresa" />
                            <label style={{ display: (this.state.novo_supervisorio['tipo'] === "cliente") ? "block" : "none" }} className="hdv-barsuper-label hdv-barsuper-label-margin" htmlFor="cliente_id">Selecione o Cliente</label>
                            <select style={{ display: (this.state.novo_supervisorio['tipo'] === "cliente") ? "block" : "none" }} onChange={this.new_supervisory} id="cliente_id" name="cliente_id" className="hdv-barsuper-select">
                                <option value="">-- Selecione o Cliente --</option>
                                {
                                    this.state.clientes.map((cliente, key) => {
                                        return <option key={key} value={cliente.id}>{cliente.nome_fantasia}</option>
                                    })
                                }
                            </select>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => { this.save_supervisory(); this.toggle() }}>Criar</Button>{' '}
                        <Button color="danger" onClick={this.toggle}>Sair</Button>{' '}
                    </ModalFooter>
                </Modal>

                <Modal size="lg" isOpen={this.state.modal_widget} toggle={this.toggleAddNovoWidget} className={this.props.className}>
                    <ModalHeader toggle={this.toggleAddNovoWidget}>Novo Widget</ModalHeader>
                    <ModalBody>
                        <div>
                            <label className="hdv-barsuper-label" htmlFor="descricao_widget">Descrição</label>
                            <input onChange={this.new_widget} className="hdv-barsuper-input" type="text" id="descricao_widget" name="descricao_widget" />

                            <label style={{ display: (this.state.supervisorio_selecionado['tipo'] === "empresa") ? "block" : "none" }} className="hdv-barsuper-label hdv-barsuper-label-margin" htmlFor="cliente_id_filter">Selecione o Cliente</label>
                            <select style={{ display: (this.state.supervisorio_selecionado['tipo'] === "empresa") ? "block" : "none" }} onChange={this.filter_equipament} id="cliente_id_filter" name="cliente_id_filter" className="hdv-barsuper-select">
                                <option value="">-- Selecione o Cliente --</option>
                                {
                                    this.state.clientes.map((cliente, key) => {
                                        return <option key={key} value={cliente.id}>{cliente.nome_fantasia}</option>
                                    })
                                }
                            </select>

                            <label className="hdv-barsuper-label hdv-barsuper-label-margin" htmlFor="equipamento">Selecione o Equipamento</label>
                            <select onChange={this.get_equipament_field} id="equipamento" name="equipamento" className="hdv-barsuper-select">
                                <option value="">-- Equipamentos --</option>
                                {
                                    (this.state.equipamentos)
                                    ? this.state.equipamentos.map((equipamento, key) => {
                                        return <option key={key} value={equipamento.equipamento__imei}>{equipamento.monitorado__nome} ({equipamento.equipamento__imei})</option>
                                    })
                                    : null
                                }
                            </select>
                            <label className="hdv-barsuper-label hdv-barsuper-label-margin" htmlFor="campo">Parâmetros do Equipamento</label>
                            <select onChange={this.new_widget} id="campo" name="campo" className="hdv-barsuper-select">
                                <option value="">-- Campos Disponíveis --</option>
                                {
                                    this.state.campos_disponiveis_equipamento_selecionado.map((campo, key) => {
                                        return <option key={key} value={campo.value}>{campo.label}</option>
                                    })
                                }
                            </select>
                            <label className="hdv-barsuper-label hdv-barsuper-label-margin" htmlFor="id_widget">Selecione o tipo do Widget</label>
                            <select onChange={this.show_widget_params} id="id_widget" name="id_widget" className="hdv-barsuper-select">
                                <option value="">-- Widgets --</option>
                                {
                                    this.state.widgets_disponiveis.map((widget, key) => {
                                        return <option key={key} value={widget.id}>{widget.descricao}</option>
                                    })
                                }
                            </select>
                            {
                                (this.state.html_campos.length > 0)
                                    ?
                                    <fieldset style={{ display: (this.state.novo_widget['id_widget'] !== false) ? "block" : "none" }} className="hdv-widget-config-fieldset">
                                        <legend className="hdv-widget-config-fieldset-legend">Configurações do Widget:</legend>
                                        {this.state.html_campos}
                                    </fieldset>
                                    :
                                    null
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => { this.toggleAddNovoWidget(); this.save_new_widget() }}>Adicionar</Button>{' '}
                        <Button color="danger" onClick={this.toggleAddNovoWidget}>Sair</Button>{' '}
                    </ModalFooter>
                </Modal>

                <div className="row hdv-barsuper">
                    <div className="col-md-2">
                        <button onClick={() => this.setState({ redirect_home: true })} className="hdv-barsuper-button hdv-barsuper-back">Voltar ao Sistema</button>
                    </div>
                    <div className="col-md-8">
                        <button style={{ display: (auth.getUserInfo()['tipo'] === "cliente") ? "none" : "inline" }} className="hdv-barsuper-button hdv-barsuper-new" onClick={this.toggle}>Novo</button>
                        <select value={(this.state.supervisorio_selecionado) ? this.state.supervisorio_selecionado.id : ""} onChange={this.select_supervisory} className="hdv-barsuper-select">
                            <option value="">-- Lista de Supervisórios --</option>
                            {
                                this.state.supervisorios.map((Supervisorio, key) => {
                                    return <option key={key} value={Supervisorio.id}>{Supervisorio.descricao}</option>
                                })
                            }
                        </select>
                        <button style={{ display: (auth.getUserInfo()['tipo'] === "cliente") ? "none" : "inline" }} className="hdv-barsuper-button hdv-barsuper-update" disabled={(this.state.need_update) ? false : true} onClick={this.action_update_supervisory}>Atualizar</button>
                        <button style={{ display: (auth.getUserInfo()['tipo'] === "cliente") ? "none" : "inline" }} className="hdv-barsuper-button hdv-barsuper-slide" onClick={this.toggle_apresetacao}>Apresentação</button>
                    </div>
                    <div className="col-md-2">
                        <button style={{ display: (auth.getUserInfo()['tipo'] === "cliente") ? "none" : "inline" }} disabled={(this.state.supervisorio_selecionado) ? false : true} onClick={this.toggle_delete} className="hdv-barsuper-button hdv-barsuper-delete">Deletar Supervisório</button>
                    </div>
                </div>
                <div className="hdv-bodysuper">

                    <ResponsiveReactGridLayout
                        className="layout set-background-color"
                        cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                        rowHeight={30}
                        isDraggable={(auth.getUserInfo()['tipo'] === "cliente") ? false : true}
                        isResizable={(auth.getUserInfo()['tipo'] === "cliente") ? false : true}
                        layouts={(this.state.layouts) ? this.state.layouts : {}}
                        onLayoutChange={(layout, layouts) => {
                            //console.log(layout)
                            //console.log(layouts)
                            /*let selecth = layout.filter((e) => e.i === "h" )[0]
                            let gauges = this.state.gaugeRef
                            let valeuDiff = (selecth.h - selecth.w) + (6 - selecth.w)
                            gauges[selecth.i] = selecth.h * (30 - valeuDiff)*/
                            let supervisorio = this.state.supervisorio_selecionado
                            if (supervisorio) {
                                if (supervisorio['layout'] !== JSON.stringify(layouts)) {
                                    supervisorio['layout'] = JSON.stringify(layouts)
                                    this.setState({ supervisorio_selecionado: supervisorio, layouts: layouts })
                                    this.update_supervisory(supervisorio)
                                }
                            }
                            //this.setState({'layouts': layouts, gaugeRef: gauges})
                        }}
                    >
                        {
                            this.state.widgets.map((widget, key) => {

                                let value = 0
                                let metrica = ""
                                let type_el = false

                                if (widget.campo !== false && Object.keys(this.state.equipamentos_data).length > 0) {
                                    if (typeof (this.state.equipamentos_data[widget.equipamento]) !== "undefined") {
                                        let splitField = widget.campo.split('_')
                                        if (splitField.length > 1 && (splitField[0] === "entrada4a20" || splitField[0] === "entradalogica" || splitField[0] === "vazao")) {
                                            if (splitField[0] === "entrada4a20") {
                                                if(this.state.equipamentos_data[widget.equipamento][0]['entradas_4a20'] != undefined){
                                                    value = this.state.equipamentos_data[widget.equipamento][0]['entradas_4a20'][parseInt(splitField[1]) - 1]  
                                                    metrica = this.state.equipamentos_data[widget.equipamento][0]['entrada4a20_metrica'][parseInt(splitField[1]) - 1]
                                                }
                                            } else if (splitField[0] === "entradalogica") {
                                                if((widget.type === "4") || (widget.type === "9")){
                                                    value = this.state.equipamentos_data[widget.equipamento][0]['pulso'][parseInt(splitField[1]) - 1] - this.state.equipamentos_data[widget.equipamento][0]['primeiro_pulso_dia'][parseInt(splitField[1]) - 1]
                                                }else if(widget.type === "11"){
                                                    value = this.state.equipamentos_data[widget.equipamento][0]['pulso'][parseInt(splitField[1]) - 1] - this.state.equipamentos_data[widget.equipamento][0]['primeiro_pulso_dia_mes'][parseInt(splitField[1]) - 1]
                                                }else{
                                                    value = this.state.equipamentos_data[widget.equipamento][0]['pulso'][parseInt(splitField[1]) - 1]
                                                }
                                                metrica = this.state.equipamentos_data[widget.equipamento][0]['pulso_metrica'][parseInt(splitField[1]) - 1]
                                                type_el = this.state.equipamentos_data[widget.equipamento][0]['pulso_tipo'][parseInt(splitField[1]) - 1]
                                            } else if (splitField[0] === "vazao") {
                                                value = this.state.equipamentos_data[widget.equipamento][0]['ct_pulso'][parseInt(splitField[1]) - 1]
                                                metrica = "m3/h"
                                            }
                                        } else {
                                            try {
                                                value = parseFloat(this.state.equipamentos_data[widget.equipamento][0][widget.campo])

                                                if (isNaN(value))
                                                    value = parseFloat(this.state.equipamentos_data[widget.equipamento][0][widget.campo.split("_")[0]][parseInt(widget.campo.split("_")[1]) - 1])
                                            } catch(e){
                                                value = 0
                                            }
                                        }
                                    }
                                }

                                let evento = (typeof (this.state.equipamentos_data[widget.equipamento]) !== "undefined") ? this.state.equipamentos_data[widget.equipamento][0] : false
                                //console.log(evento['data_hora_dispositivo'])
                                let atrasado = false
                                if ("minutos_alerta_semcomunicacao" in widget.parametros) {
                                    if (moment.duration(moment().diff(this.converte_data_timezone(evento.data_hora_dispositivo))).asMinutes() > parseFloat(widget.parametros.minutos_alerta_semcomunicacao)) {
                                        atrasado = true
                                    }
                                }


                                switch (widget.type) {
                                    case "1":
                                        return <div className="div-sample" key={widget.id} data-grid={{ w: 6, h: 12, x: 0, y: 6, minW: 2, minH: 6, maxH: 14 }}>
                                            <i className="fa fa-times-circle hdv-close-widget" onClick={() => this.delete_widget(widget.id)}></i>
                                            <Reservatorio datawgt={{ id: key, comunicacao: atrasado, descricao: widget.descricao, value: value, params: widget.parametros }} key={key} />
                                        </div>
                                    case "2":
                                        return <div className="div-sample" key={widget.id} data-grid={{ w: 3, h: 4, x: 0, y: 6, minW: 3, minH: 4 }}>
                                            <i className="fa fa-times-circle hdv-close-widget" onClick={() => this.delete_widget(widget.id)}></i>
                                            <ValorAtual datawgt={{ descricao: widget.descricao, comunicacao: atrasado, value: value, metric: metrica, type_el: type_el, params: widget.parametros }} key={key} />
                                        </div>
                                    case "3":
                                        return <div className="div-sample" key={widget.id} data-grid={{ w: 2, h: 4, x: 0, y: 6, minW: 2, minH: 4 }}>
                                            <i className="fa fa-times-circle hdv-close-widget" onClick={() => this.delete_widget(widget.id)}></i>
                                            <Imagem key={key} />
                                        </div>
                                    case "4":
                                        return <div className="div-sample" key={widget.id} data-grid={{ w: 6, h: 5, x: 0, y: 6, minW: 4, minH: 5 }}>
                                            <i className="fa fa-times-circle hdv-close-widget" onClick={() => this.delete_widget(widget.id)}></i>
                                            <MaximoAtual datawgt={{ descricao: widget.descricao, comunicacao: atrasado, value: value, params: widget.parametros }} key={key} />
                                        </div>
                                    case "5":
                                        return <div className="div-sample" key={widget.id} data-grid={{ w: 2, h: 12, x: 0, y: 6, minW: 2, minH: 12 }}>
                                            <i className="fa fa-times-circle hdv-close-widget" onClick={() => this.delete_widget(widget.id)}></i>
                                            <Poco datawgt={{ id: key, comunicacao: atrasado, forceupdate: 0, descricao: widget.descricao, value: value, event: evento, params: widget.parametros }} key={key} />
                                        </div>
                                    case "6":
                                        return <div className="div-sample" key={widget.id} data-grid={{ w: 6, h: 12, x: 18, y: 6, minW: 4, minH: 6 }}>
                                            <i className="fa fa-times-circle hdv-close-widget" onClick={() => this.delete_widget(widget.id)}></i>
                                            <GraficoLinha datawgt={{ descricao: widget.descricao, comunicacao: atrasado, value: { name: this.converte_data_timezone_format(evento['data_hora_dispositivo'], 'YYYY-MM-DD HH:mm:ss'), uv: value }, data_value: 'uv', data_label: 'name', params: widget.parametros }} key={key} />
                                        </div>
                                    case "7":
                                        return <div className="div-sample" key={widget.id} data-grid={{ w: 6, h: 12, x: 0, y: 6, minW: 4, minH: 6 }}>
                                            <i className="fa fa-times-circle hdv-close-widget" onClick={() => this.delete_widget(widget.id)}></i>
                                            <GraficoBarra datawgt={{ descricao: widget.descricao, comunicacao: atrasado, value: { name: String(this.converte_data_timezone_format(evento['data_hora_dispositivo'], 'HH')), Volume: value }, data_value: 'Volume', data_label: 'name', params: widget.parametros }} key={key} />
                                        </div>
                                    case "8":
                                        return <div className="div-sample" key={widget.id} data-grid={{ w: 3, h: 4, x: 0, y: 6, minW: 3, minH: 4 }}>
                                            <i className="fa fa-times-circle hdv-close-widget" onClick={() => this.delete_widget(widget.id)}></i>
                                            <Status datawgt={{ descricao: widget.descricao, comunicacao: atrasado, value: value, params: widget.parametros }} key={key} />
                                        </div>
                                    case "9":
                                        return <div className="div-sample" key={widget.id} data-grid={{ w: 3, h: 4, x: 0, y: 6, minW: 3, minH: 4 }}>
                                            <i className="fa fa-times-circle hdv-close-widget" onClick={() => this.delete_widget(widget.id)}></i>
                                            <VolumeDia datawgt={{ descricao: widget.descricao, comunicacao: atrasado, value: value, metric: metrica, type_el: type_el, params: widget.parametros }} key={key} />
                                        </div>
                                    case "10":
                                        return <div className="div-sample" key={widget.id} data-grid={{ w: 3, h: 4, x: 0, y: 6, minW: 3, minH: 4 }}>
                                            <i className="fa fa-times-circle hdv-close-widget" onClick={() => this.delete_widget(widget.id)}></i>
                                            <ValorAtualV2 datawgt={{ descricao: widget.descricao, comunicacao: atrasado, value: value, metric: metrica, type_el: type_el, params: widget.parametros }} key={key} />
                                        </div>
                                    case "11":
                                        return <div className="div-sample" key={widget.id} data-grid={{ w: 3, h: 4, x: 0, y: 6, minW: 3, minH: 4 }}>
                                            <i className="fa fa-times-circle hdv-close-widget" onClick={() => this.delete_widget(widget.id)}></i>
                                            <VolumeDiaMes datawgt={{ descricao: widget.descricao, comunicacao: atrasado, value: value, metric: metrica, type_el: type_el, params: widget.parametros }} key={key} />
                                        </div>
                                    default:
                                        return null
                                }
                            })
                        }

                    </ResponsiveReactGridLayout>

                    <div style={{ display: (auth.getUserInfo()['tipo'] === "cliente") ? "none" : "block" }} className="hdv-barsuper-bottom">
                        <button disabled={(this.state.supervisorio_selecionado) ? false : true} className="hdv-barsuper-button hdv-barsuper-new" onClick={this.toggleAddNovoWidget}>Adicionar novo Widget ao Supervisório</button>
                    </div>

                </div>
                <div className="hdv-overlay" style={{ "visibility": (this.state.block_screen) ? "visible" : "hidden" }}>
                </div>

                <div className="hdv-icons-list" style={{ "display": (this.state.show_icons) ? "block" : "none" }}>
                    <div><h2>Selecione um Ícone</h2></div>
                    <ul>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-radiation-alt" id="fa-radiation-alt"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-oil-can" id="fa-oil-can"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-tachometer-alt" id="fa-tachometer-alt"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-gas-pump" id="fa-gas-pump"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-charging-station" id="fa-charging-station"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-cloud-sun" id="fa-cloud-sun"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-flask" id="fa-flask"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-chart-area" id="fa-chart-area"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-fire-alt" id="fa-fire-alt"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-filter" id="fa-filter"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-broadcast-tower" id="fa-broadcast-tower"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-wifi" id="fa-wifi"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-ruler-vertical" id="fa-ruler-vertical"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-tint" id="fa-tint"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-solar-panel" id="fa-solar-panel"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-water" id="fa-water"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-lightbulb" id="fa-lightbulb"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-sun" id="fa-sun"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-wind" id="fa-wind"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-heartbeat" id="fa-heartbeat"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-swimming-pool" id="fa-swimming-pool"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-temperature-low" id="fa-temperature-low"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-cloud-rain" id="fa-cloud-rain"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-bolt" id="fa-bolt"></i></li>
                        <li><i onClick={this.save_subparam_icone} className="fa fa-stopwatch" id="fa-stopwatch"></i></li>
                    </ul>
                </div>

            </div>
        );
    }
}

export default Supervisorio;