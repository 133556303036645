import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom'
import "react-tabs/style/react-tabs.css";
import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import Select from 'react-select';
import Aviso from '../../components/Aviso';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';

class Form extends Component {

    state = {
        detalhes: { "nome": "", tipo: "", perfil_id: "", email: "", senha: "", status: "" },
        formerrors: [],
        redirect: false,
        path: "",
        permissoes: [],
        open_group_block: {},
        selected_permission: [],
        tipos: [{ value: "admin", label: "Administrador" }, { value: "normal", label: "Normal" }],
        selects: { tipo: {}, perfil_id: {} },
        perfis: [],
        validacao: { email: true, senha: true },
        informacoes_usuario: false,
        aviso: { show: false, args: null, message: '' },
        cancelModal: null,
        warningModal: null
    }

    componentWillMount = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/usuario/perfil/', config_me))

        axios.all(requestArray)
            .then(axios.spread((perfis) => {
                let perfilSelect = []
                if (perfis.data.length > 0) {
                    perfis.data.forEach((value) => {
                        perfilSelect.push({ label: value.nome, value: value.id })
                    })
                }
                this.setState({ "perfis": perfilSelect, informacoes_usuario: userInfo })
            }))
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }
    }

    getData = () => {
        let resource = "usuario"
        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };


        axios.get(requestURL, config_me)
            .then((response) => {
                let objReturn = response.data
                delete objReturn.id
                objReturn['email'] = objReturn.usuario__username
                delete objReturn.usuario__username

                let selects = this.state.selects
                selects['tipo'] = { value: objReturn.tipo, label: objReturn.tipo.charAt(0).toUpperCase() + objReturn.tipo.slice(1) }
                selects['perfil_id'] = { value: objReturn.perfil_id, label: objReturn.perfil__nome }

                delete objReturn.perfil__nome

                objReturn['senha'] = ""

                this.setState({ selects: selects, detalhes: objReturn })
            })
    }

    handleChange = (field, e) => {
        let obj = this.state.detalhes

        if (e.target.type === "checkbox") {
            obj[field] = (e.target.checked === true) ? true : false
        } else {
            obj[field] = e.target.value
        }

        this.setState({ "detalhes": obj })
    }

    openModalCancel = () => {
        this.setState({cancelModal: true})
    }

    closeModal = () => {
        this.setState({cancelModal: false})
    }

    confirmModal = () => {
        window.location = "/usuario"
    }

    saveAction = (novo_cadastro) => {
        let { validacao } = this.state
        let userInfo = auth.getUserInfo();
        let resource = "usuario"
        let requestURL = ''
        let verb = ''
        let emptyField = false

        if (!validacao.email || !validacao.senha) return

        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/';
            verb = 'post'
        }

        let bodyFormData = new FormData()

        for (let key in this.state.detalhes) {
            bodyFormData.append(key, this.state.detalhes[key]);
            if (!this.state.detalhes[key] && key != 'senha'){
                emptyField = true

                if(key == 'tipo' && userInfo.tipo == 'cliente'){
                    emptyField = false
                }
            }
        }

        if (emptyField){
            this.setState({warningModal: true})
            return
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (novo_cadastro === true) {
                    if (this.props.match.params.id) {
                        const aviso = { show: true, message: `Usuário ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`, args: { redirect: true, path: "/usuario/novo" } }
                        this.setState({ aviso })
                    }
                    else
                        window.location.reload()

                } else {
                    const aviso = { show: true, message: `Usuário ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`, args: { redirect: true, path: "/usuario" } }
                    this.setState({ aviso })
                }

            })
            .catch((error) => {
                console.log(error)
                let erros = error.response.data

                for (let i = 0; i < erros.length; i++) {
                    let span = document.querySelector(`#validacao-${erros[i][0]}`)
                    if (span)
                        span.innerHTML = erros[i][1]
                }
            })
    }

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    handleChangeSelect = (selectedOption, field) => {
        console.log(selectedOption, field)
        let obj = this.state.detalhes
        obj[field] = selectedOption.value

        let selects = this.state.selects
        selects[field] = selectedOption
        if (document.querySelector(`#validacao-${field}`))
            document.querySelector(`#validacao-${field}`).innerHTML = ""
        this.setState({ "selects": selects, "detalhes": obj });
    }

    render() {
        const { tipo, perfil_id } = this.state.selects;
        const { validacao } = this.state

        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        return (
            <div>

                <Modal isOpen={this.state.cancelModal}>
                    <ModalHeader>
                        Cancelar
                    </ModalHeader>
                    <ModalBody>
                        {this.props.match.params. id ? <h6>Você tem certeza de que deseja cancelar a edição do usuário?</h6> : <h6>Você tem certeza de que deseja cancelar a criação do usuário?</h6>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>
                            Não
                        </Button>
                        <Button color="danger" onClick={this.confirmModal}>
                            Sim
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.warningModal}>
                    <ModalHeader>
                        Aviso
                    </ModalHeader>
                    <ModalBody>
                        <h6>Preencha todos os campos para continuar!</h6>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => {this.setState({warningModal: false})}}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>

                {this.props.match.params.id ? <h4>Editar Usuário</h4> : <h4>Novo Usuário</h4>}
                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.setState(this.state.aviso.args)}
                    open={this.state.aviso.show}
                />
                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Detalhes do Usuário</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <label htmlFor="nome">Nome</label>
                            <input type="text" name="nome" id="nome" value={this.state.detalhes.nome} onChange={(e) => this.handleChange('nome', e)} />
                            <span id="validacao-nome"></span>
                            {
                                (this.state.informacoes_usuario.tipo === "cliente")
                                    ?
                                    ""
                                    :
                                    <>
                                        <label htmlFor="nome">Tipo</label>
                                        <Select
                                            value={tipo}
                                            onChange={(e) => this.handleChangeSelect(e, 'tipo')}
                                            options={this.state.tipos}
                                        />
                                        <span id="validacao-tipo"></span>
                                    </>
                            }

                            <label htmlFor="perfil">Perfil</label>
                            <Select
                                value={perfil_id}
                                onChange={(e) => this.handleChangeSelect(e, 'perfil_id')}
                                options={this.state.perfis}
                            />

                            <div className="row">
                                <div className="col-md-6">
                                    <label>E-mail</label>
                                    <input type="text" autoComplete="nope" value={this.state.detalhes.email} onChange={(e) => {
                                        this.handleChange('email', e)
                                        let reg = /\S+@\S+\.\S+/
                                        let { validacao } = this.state
                                        validacao['email'] = reg.exec(e.target.value) !== null
                                        this.setState({ validacao })
                                    }} />
                                    {!validacao.email ? <span id="validacao-email">E-mail inválido</span> : null}
                                    <span id="validacao-username"></span>
                                </div>
                                <div className="col-md-6">
                                    <label>Senha</label>
                                    <input type="password" className="hdv-form-system-password-field" value={this.state.detalhes.senha} onChange={(e) => {
                                        this.handleChange('senha', e)
                                        let { validacao } = this.state
                                        validacao['senha'] = e.target.value.length >= 5
                                        this.setState({ validacao })
                                    }} />
                                    {!validacao.senha ? <span id="validacao-senha">Senha inválida (Deve conter pelo menos 5 caracteres)</span> : null}
                                </div>
                            </div>

                            <label htmlFor="nome">Status</label>
                            <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'ativo'} value="ativo" onChange={(e) => this.handleChange('status', e)} /> Ativo
                            <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'bloqueado'} value="bloqueado" onChange={(e) => this.handleChange('status', e)} /> Bloqueado
                            <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'cancelado'} value="cancelado" onChange={(e) => this.handleChange('status', e)} /> Cancelado
                            <br />
                            <span id="validacao-status"></span>
                        </form>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-geen" disabled={!(validacao.email && validacao.senha)}>Salvar</button>
                    <button onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-geenlight " disabled={!(validacao.email && validacao.senha)}>Salvar e Cadastrar um Novo</button>
                   <button onClick={() => this.openModalCancel()} className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                </div>

            </div>
        );
    }
}

export default Form;