import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom'
import "react-tabs/style/react-tabs.css";
import axios from 'axios'
import Select, { components } from 'react-select';
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import { Modal, Button, ModalHeader, ModalBody } from 'reactstrap'
import Aviso from '../../components/Aviso'


class Form extends Component {

    state = {
        detalhes: { "frequencia_reporte": "", "timezone": "", "intervalo_csq": "", "tempo_alerta_sem_comunicacao": null, "frequencia_reporte_konect": '', "aplica_calculo": true },
        formerrors: [],
        redirect: false,
        path: "",
        reportes: [{ value: "1", "label": "1 minuto" }, { value: "2", "label": "5 minutos" }, { value: "3", "label": "10 minutos" }, { value: "4", "label": "30 minutos" }, { value: "5", "label": "1 hora" }, { value: "6", "label": "2 horas" }, { value: "7", "label": "1 dia" }],
        intervalos_csq: [{ value: "1", label: "200 ms" }, { value: "2", label: "20 ms" }],
        selectedOption: null,
        selectedOptionTimezone: null,
        selectedOptionCSQ: null,
        entrada4a20: { "sinal_id": "", "canal": "", "descricao": "", "valor_minimo": "0", "valor_maximo": "0", "inverter": "0", "unidade_id": "" },
        entradalogica: { "canal": "", "tipo_entrada_logica_id": "", "pulso": "0", "ajuste": "0", "descricao": "", "custo_unidade": "0", "primeiro_dia_mes": "0" },
        totalizadorentradalogica: 0,
        id_editando_entrada4a20: false,
        id_editando_entradalogica: false,
        configuracaoentradas4a20: [],
        configuracaoentradaslogicas: [],
        entradas4a20: [],
        entradaslogicas: [],
        unidades: [],
        optionSelected: { "sinal_id": {}, "tipo_entrada_logica_id": {}, "unidade_id": {} },
        ultimo_reporte_equipamento: false,
        timezone: [],
        releModal: false,
        releOptions: [],
        releSelecionado: null,
        aviso: { show: false, args: null, message: '' },
    }

    componentWillMount = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/lista-sinal-4a20/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/lista-entrada-logica/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/configuracao-timezone/', config_me))

        axios.all(requestArray)
            .then(axios.spread((entradas4a20, entradaslogicas, timezones) => {
                let optionsEntradas4a20 = []
                if (entradas4a20.status === 200) {
                    entradas4a20.data.forEach((a) => {
                        optionsEntradas4a20.push({ "value": a.id, "label": a.descricao })
                    })
                }
                let optionsEntradasLogicas = []
                if (entradaslogicas.status === 200) {
                    entradaslogicas.data.forEach((a) => {
                        optionsEntradasLogicas.push({ "value": a.id, "label": a.descricao })
                    })
                }

                let optionsTimezones = []
                if (timezones.status === 200) {
                    timezones.data.forEach((a) => {
                        optionsTimezones.push({ "value": a.codigo, "label": a.codigo })
                    })
                }
                this.setState({ "configuracaoentradas4a20": optionsEntradas4a20, "configuracaoentradaslogicas": optionsEntradasLogicas, "timezone": optionsTimezones })
            }));
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }
    }

    contabilizaTotalEntradaLogica = () => {
        let total = 0
        if (this.state.ultimo_reporte_equipamento !== false) {
            if (this.state.entradalogica.ajuste >= 0) {
                total = ((this.state.ultimo_reporte_equipamento[this.state.entradalogica.canal - 1] * this.state.entradalogica.pulso) / 1000) + this.state.entradalogica.ajuste
            } else {
                total = ((this.state.ultimo_reporte_equipamento[this.state.entradalogica.canal - 1] * this.state.entradalogica.pulso) / 1000) - this.state.entradalogica.ajuste
            }
        }
        //let objEntradaLogica = this.state.entradalogica
        //objEntradaLogica['totalizador'] = total
        //this.setState({entradalogica: objEntradaLogica})
        return total
    }

    getData = () => {
        /*let resource = "parametrizacao"
        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
        .then((response) => {
            delete response.data.id

            let selected_opt = null
            this.state.reportes.forEach((v) => {
                if(v.value === response.data.frequencia_reporte){
                    selected_opt = {"value": response.data.frequencia_reporte, "label": v.label}
                }
            })

            this.setState({ detalhes: response.data, selectedOption: selected_opt });
        })*/

        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        let requestArray = []

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/' + this.props.match.params.id + '/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/' + this.props.match.params.id + '/4a20/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/' + this.props.match.params.id + '/entrada-logica/', config_me))

        axios.all(requestArray)
            .then(axios.spread((detalhes, entradas4a20, entradaslogicas) => {
                console.log(detalhes)
                delete detalhes.data.id

                let selected_opt = null
                this.state.reportes.forEach((v) => {
                    if (v.value === detalhes.data.frequencia_reporte) {
                        selected_opt = { "value": detalhes.data.frequencia_reporte, "label": v.label }
                    }
                })

                let selected_csq = null
                this.state.intervalos_csq.forEach((v) => {
                    if (v.value === detalhes.data.intervalo_csq) {
                        selected_csq = { "value": detalhes.data.intervalo_csq, "label": v.label }
                    }
                })

                if (entradas4a20.status === 204)
                    entradas4a20.data = []
                if (entradaslogicas.status === 204)
                    entradaslogicas.data = []

                this.setState({ detalhes: detalhes.data, selectedOption: selected_opt, selectedOptionCSQ: selected_csq, selectedOptionTimezone: { "value": detalhes.data.timezone, "label": detalhes.data.timezone }, entradas4a20: entradas4a20.data, entradaslogicas: entradaslogicas.data });
                this.atualizaUltimoEvento()

                if (this.state.detalhes.hidrohub__modelo_id == 8){
                    var virtual_equipment_entry = []

                    this.state.configuracaoentradaslogicas.map((entry) => {
                        if([1,3,5,7].includes(entry.value)){
                            virtual_equipment_entry.push(entry)
                        }
                    })
                    this.setState({"configuracaoentradaslogicas": virtual_equipment_entry})
                }
            }));
    }

    handleChange = (e) => {
        let obj = this.state.detalhes
        obj[e.target.id] = e.target.value
        this.setState({ "detalhes": obj })
    }

    handleChangeCheckbox = (e) => {
        let obj = this.state.detalhes
        obj[e.target.id] = (e.target.checked) ? true : false;
        this.setState({ "detalhes": obj })
    }

    handle4a20Change = (e) => {
        let obj = this.state.entrada4a20
        if (e.target.id === "descricao") {
            obj[e.target.id] = e.target.value
        } else {
            let value = e.target.value.replace(/[^0-9./-]/g, '');
            if (value === "") {
                value = 0
            }
            obj[e.target.id] = value
        }

        if (document.querySelector(`#validacao-${e.target.name}-4a20`))
            document.querySelector(`#validacao-${e.target.name}-4a20`).innerHTML = ""

        this.setState({ "entrada4a20": obj })
    }

    formatar = (e) => {
        let v = e.target.value.replace(/\D/g, '')
        v = (v / 100).toFixed(2) + ''
        v = v.replace(" ", ",")
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2,$3,")
        v = v.replace(/(\d)(\d{3}),/g, "$1.$2,")
        let obj = this.state.entradalogica
        obj[e.target.id] = v
        this.setState({ entradalogica: obj })
    }

    handleEntradaLogicaChange = (e) => {
        let obj = this.state.entradalogica
        let total = 0

        if (e.target.id === "primeiro_dia_mes" && ((e.target.value === "0") || (e.target.value === "") || (e.target.value === null))) {
            e.target.value = ""
        }

        if (e.target.id === "pulso" || e.target.id === "ajuste" || e.target.id === "custo_unidade") {
            let value = e.target.value.replace(/[^0-9./-]/g, '');
            if (value === "") {
                value = 0
            }
            obj[e.target.id] = value
        } else {
            obj[e.target.id] = e.target.value
        }

        if (this.state.ultimo_reporte_equipamento !== false) {
            if (e.target.id === "pulso" || e.target.id === "ajuste") {
                if (parseFloat(this.state.entradalogica.ajuste) >= 0) {
                    total = (parseInt(this.state.ultimo_reporte_equipamento.pulso[obj.canal - 1]) * (parseFloat(obj.pulso) / 1000)) + parseFloat(obj.ajuste)
                } else {
                    let calc = Math.abs(parseFloat(this.state.entradalogica.ajuste))
                    total = (parseInt(this.state.ultimo_reporte_equipamento.pulso[obj.canal - 1]) * (parseFloat(obj.pulso) / 1000)) - calc
                }
                //console.log((parseInt(this.state.ultimo_reporte_equipamento.pulso[obj.canal - 1])))
                //console.log(parseFloat(obj.pulso))
                //console.log(parseFloat(obj.ajuste))
                //obj['totalizador'] = total.toFixed(3)
            }
        }


        if (document.querySelector(`#validacao-${e.target.name}-logica`))
            document.querySelector(`#validacao-${e.target.name}-logica`).innerHTML = ""

        this.setState({ "entradalogica": obj, totalizadorentradalogica: total.toFixed(3) })
    }

    handleChange4a20Select = (selectedOption, field) => {
        console.log(selectedOption, field)
        let obj = this.state.entrada4a20
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        this.setState({ "optionSelected": optionObj, "entrada4a20": obj });
    }

    atualizaUltimoEvento = () => {

        let userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reporte-ultimo-evento/'

        bodyFormData.append("id_dispositivo", this.state.detalhes.hidrohub__imei)

        axios({
            method: 'post',
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (response.data !== false) {
                    this.setState({ "ultimo_reporte_equipamento": response.data })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    handleChangeEntradaLogicaSelect = (selectedOption, field) => {

        let obj = this.state.entradalogica
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        this.setState({ "optionSelected": optionObj, "entradalogica": obj })
        //this.atualizaUltimoEvento()
        if (document.querySelector(`#validacao-${field.slice(0, -3)}-logica`))
            document.querySelector(`#validacao-${field.slice(0, -3)}-logica`).innerHTML = ""
    }

    handleChangeSelect = (selectedOption, field) => {
        console.log(selectedOption, field)
        let obj = this.state.detalhes
        obj[field] = selectedOption.value
        this.setState({ selectedOption, "detalhes": obj });
    }

    handleChangeSelectTimezone = (selectedOptionTimezone, field) => {
        console.log(selectedOptionTimezone, field)
        let obj = this.state.detalhes
        obj[field] = selectedOptionTimezone.value
        this.setState({ selectedOptionTimezone, "detalhes": obj });
    }

    handleChangeSelectCSQ = (selectedOptionCSQ, field) => {
        console.log(selectedOptionCSQ, field)
        let obj = this.state.detalhes
        obj[field] = selectedOptionCSQ.value
        this.setState({ selectedOptionCSQ, "detalhes": obj });
    }

    handleChange4a20SelectSinal = (selectedOption, field) => {
        console.log(selectedOption, field)
        let obj = this.state.entrada4a20
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/lista-sinal-4a20/' + selectedOption.value + '/unidades/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((response) => {

                let optionsUnidades4a20 = []
                if (response.status === 200) {
                    response.data.forEach((a) => {
                        optionsUnidades4a20.push({ "value": a.id, "label": a.descricao })
                    })
                }

                this.setState({ "optionSelected": optionObj, "unidades": optionsUnidades4a20, "entrada4a20": obj });
            })

        document.querySelector('#validacao-sinal-4a20').innerHTML = ""
    }

    saveAction = (enviarComando = false) => {
        let userInfo = auth.getUserInfo();
        let resource = "parametrizacao"
        let requestURL = ''
        let verb = ''

        requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
        verb = 'patch'

        let bodyFormData = new FormData();
        // frequencia_reporte_konect
        for (let key in this.state.detalhes) {
            if (key !== "hidrohub__imei" && key !== "hidrohub__modelo_id") {
                bodyFormData.append(key, this.state.detalhes[key]);
            }
        }

        if (enviarComando)
            bodyFormData.append('enviar_comando', "1")

        bodyFormData.forEach((value, key) => console.log(key, value))
        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                let aviso
                if (enviarComando) {
                    aviso = { show: true, message: `Comando enviado com sucesso.`, args: { redirect: true, path: "/parametrizacao" } }
                }
                else {
                    aviso = { show: true, message: `Dados atualizados com sucesso.`, args: { redirect: true, path: "/parametrizacao" } }
                }
                this.setState({ aviso })
            })
            .catch((error) => console.log(error))
    }

    handleSelectRele = (e) => this.setState({ releSelecionado: e })

    generateOption = (source, value, desc) => {
        let options = source.map((obj, idx) =>
            <option key={idx} value={obj[value]}>{obj[desc]}</option>
        );
        return options;
    }

    checkErrorField = (field) => {
        return this.state.formerrors.includes(field) ? "hdv-form-error" : ""
    }

    createRows = (campoativo, source, campo_principal, campo_secundario) => {
        return this.state[source].map((campo, i) =>
            <tr key={i}>
                <td>
                    {campo[campo_principal]}
                </td>
                <td>
                    {campo[campo_secundario]} #{campo['canal']} - {campo['unidade__descricao']}
                </td>
                <td>
                    {parseFloat(campo['valor_minimo']).toFixed(2)}
                </td>
                <td>
                    {parseFloat(campo['valor_maximo']).toFixed(2)}
                </td>
                <td className="hdv-subtable-action-td">

                    <button disabled={(parseInt(this.state["id_editando_" + campoativo]) === campo['id']) ? true : false}
                        onClick={() => {
                            let data = this.state[source];
                            let newdata = {};
                            newdata[campoativo] = data[i];
                            newdata["id_editando_" + campoativo] = campo['id'];
                            let objSelected = this.state.optionSelected
                            objSelected['sinal_id'] = { value: data[i]['sinal_id'], label: data[i]['sinal__descricao'] }
                            objSelected['unidade_id'] = { value: data[i]['unidade_id'], label: data[i]['unidade__descricao'] }
                            newdata['optionSelected'] = objSelected
                            this.setState(newdata)
                        }}
                        className="hdv-btn-forms hdv-btn-yellow">Editar</button>
                    <button disabled={(parseInt(this.state["id_editando_" + campoativo]) === campo['id']) ? true : false}
                        onClick={() => { this.removeItem(i, source) }}
                        className="hdv-btn-forms hdv-btn-red">Deletar</button>
                </td>
            </tr>
        );
    }

    createRowsEntradaLogica = (campoativo, source, descricao, campo_principal, campo_secundario) => {
        return this.state[source].map((campo, i) =>
            <tr key={i}>
                <td>
                    {campo[descricao]}
                </td>
                <td>
                    {campo[campo_principal]}
                </td>
                <td>
                    {campo[campo_secundario]}
                </td>
                <td>
                    {(campo['pulso']) ? parseFloat(campo['pulso']).toFixed(3) : "Não se aplica"}
                </td>
                <td>
                    {(campo['ajuste']) ? parseFloat(campo['ajuste']).toFixed(3) : "Não se aplica"}
                </td>
                <td>
                    {(campo['primeiro_dia_mes'])}
                </td>
                <td className="hdv-subtable-action-td">

                    <button disabled={(parseInt(this.state["id_editando_" + campoativo]) === i) ? true : false}
                        onClick={() => {
                            let data = this.state[source];
                            let newdata = {};
                            newdata[campoativo] = data[i];
                            newdata["id_editando_" + campoativo] = i;
                            let objSelected = this.state.optionSelected
                            objSelected['tipo_entrada_logica_id'] = { value: data[i]['tipo_entrada_logica_id'], label: data[i]['tipo_entrada_logica__descricao'] }
                            newdata['optionSelected'] = objSelected
                            if (newdata.entradalogica && newdata.entradalogica.primeiro_dia_mes === null) {
                                newdata.entradalogica.primeiro_dia_mes = 0
                            }
                            this.setState(newdata)
                            //this.atualizaUltimoEvento()
                        }}
                        className="hdv-btn-forms hdv-btn-yellow">Editar</button>
                    <button disabled={(parseInt(this.state["id_editando_" + campoativo]) === i) ? true : false}
                        onClick={() => { this.removeItem(i, source) }}
                        className="hdv-btn-forms hdv-btn-red">Deletar</button>
                </td>
            </tr>
        );
    }

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }


    atualizarRele = () => {
        const { releSelecionado } = this.state
        const { detalhes } = this.state
        const userInfo = auth.getUserInfo()

        let url
        let path
        switch (detalhes['hidrohub__modelo_id']) {
            default:
                path = 'comando_konnect'
                break
            case 5:
                path = 'comando_konnect'
                break
        }

        let data = new FormData()
        data.append('rele', releSelecionado.value)
        data.append('status', releSelecionado.estado ? "0" : "1")

        releSelecionado['estado'] = !releSelecionado.estado
        this.setState({ releSelecionado })

        if (!path) return
        url = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + `/${path}/${detalhes['hidrohub__imei']}/`

        axios({
            method: 'POST',
            url: url,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(() => this.getReleData())
            .catch(err => console.error(err))
    }

    getReleData = () => {
        let path
        const modelo = this.state.detalhes.hidrohub__modelo_id
        const equipamentoId = this.state.detalhes.hidrohub__imei

        switch (modelo) {
            default:
                path = 'comando_konnect'
                break
            case 5:
                path = 'comando_konnect'
                break
            case 19:
                path = 'comando_mestria'
                break
        }

        let userInfo = auth.getUserInfo()
        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + `/${path}/${equipamentoId}/`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(async res => {
            let options = []

            await res.data.status.map((rele, i) => {
                options.push({
                    value: i + 1,
                    label: `Rele ${i + 1}`,
                    estado: rele
                })

                return null
            })
            this.setState({ releOptions: options })
        }).catch(err => console.error(err))
    }

    changeFrequencia = (e, operacao) => {
        e.preventDefault()
        let { detalhes } = this.state
        let frequenciaReporte = detalhes.frequencia_reporte_konect

        if (operacao === '+') {
            if (frequenciaReporte < 1440)
                Number(frequenciaReporte += 1)

        } else {
            if (frequenciaReporte > 1)
                Number(frequenciaReporte -= 1)
        }

        detalhes['frequencia_reporte_konect'] = frequenciaReporte
        this.setState({ detalhes })
    }

    addNovo4a20 = () => {

        let userInfo = auth.getUserInfo();
        let bodyFormData = new FormData();
        let verb = ""
        let id_update = ""
        let urlAction = ""

        for (let key in this.state.entrada4a20) {
            if (key !== "sinal__descricao" && key !== "unidade__descricao") {
                bodyFormData.append(key, this.state.entrada4a20[key]);
            }
        }
        console.log(this.state.id_editando_entrada4a20)
        if (Number.isInteger(this.state.id_editando_entrada4a20)) {
            verb = "patch"
            id_update = this.state.id_editando_entrada4a20
            urlAction = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/' + this.props.match.params.id + '/4a20/' + id_update + '/'
        } else {
            verb = "post"
            urlAction = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/' + this.props.match.params.id + '/4a20/'
        }

        axios({
            method: verb,
            url: urlAction,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/' + this.props.match.params.id + '/4a20/', config_me)
                    .then((resp) => {
                        let entrada4a20 = { "sinal_id": "", "canal": "", "descricao": "", "valor_minimo": "0", "valor_maximo": "0", "inverter": "0", "unidade_id": "" }
                        let objSelected = this.state.optionSelected
                        objSelected['sinal_id'] = {}
                        objSelected['unidade_id'] = {}
                        this.setState({ "entrada4a20": entrada4a20, "entradas4a20": resp.data, "id_editando_entrada4a20": false, optionSelected: objSelected, unidade: [] })
                    })
            })
            .catch((error) => {
                console.log(error)
                let erros = error.response.data
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}-4a20`)
                        if (span)
                            span.innerHTML = erros[i][1]
                    }
                }
            })

    }

    cancelEditEntrada4a20 = () => {
        let entrada4a20 = { "sinal_id": "", "canal": "", "descricao": "", "valor_minimo": "", "valor_maximo": "", "inverter": "", "unidade_id": "" }
        let objSelected = this.state.optionSelected
        objSelected['sinal_id'] = {}
        objSelected['unidade_id'] = {}
        this.setState({ "entrada4a20": entrada4a20, "id_editando_entrada4a20": false, optionSelected: objSelected, unidade: [] })
    }

    addNovoEntradaLogica = () => {
        let userInfo = auth.getUserInfo();
        let bodyFormData = new FormData();
        let verb = ""
        let id_update = ""
        let urlAction = ""

        for (let key in this.state.entradalogica) {
            if (key !== "tipo_entrada_logica__descricao") {
                bodyFormData.append(key, this.state.entradalogica[key]);
            }
        }

        if (Number.isInteger(this.state.id_editando_entradalogica) === true) {
            verb = "patch"
            id_update = this.state.entradaslogicas[this.state.id_editando_entradalogica]['id']
            urlAction = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/' + this.props.match.params.id + '/entrada-logica/' + id_update + '/'
        } else {
            verb = "post"
            urlAction = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/' + this.props.match.params.id + '/entrada-logica/'
        }

        axios({
            method: verb,
            url: urlAction,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/' + this.props.match.params.id + '/entrada-logica/', config_me)
                    .then((resp) => {
                        let entradalogica = { "canal": "", "tipo_entrada_logica_id": "", "pulso": "0", "ajuste": "0", "descricao": "", "custo_unidade": "0", "primeiro_dia_mes": "0" }
                        let objSelected = this.state.optionSelected
                        objSelected['tipo_entrada_logica_id'] = {}
                        this.setState({ "entradalogica": entradalogica, "entradaslogicas": resp.data, "id_editando_entradalogica": false, optionSelected: objSelected, totalizadorentradalogica: 0 })
                    })
            })
            .catch((error) => {
                console.log(error)
                let erros = error.response.data
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}-logica`)
                        if (span)
                            span.innerHTML = erros[i][1]
                    }
                }
            })

    }

    cancelEditEntradaLogica = () => {
        let entradalogica = { "canal": "", "tipo_entrada_logica_id": "", "pulso": "", "ajuste": "", "descricao": "", "primeiro_dia_mes": "" }
        let objSelected = this.state.optionSelected
        objSelected['tipo_entrada_logica_id'] = {}
        this.setState({ "entradalogica": entradalogica, "id_editando_entradalogica": false, optionSelected: objSelected, totalizadorentradalogica: 0 })
    }

    removeItem = (i, source) => {
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        if (this.state[source][i]['id']) {
            if (source === "entradas4a20") {
                axios.delete(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/' + this.props.match.params.id + '/4a20/' + this.state[source][i]['id'] + '/', config_me)
                    .then((response) => {
                        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/' + this.props.match.params.id + '/4a20/', config_me)
                            .then((resp) => {
                                let entrada4a20 = { "sinal_id": "", "canal": "", "descricao": "", "valor_minimo": "", "valor_maximo": "", "inverter": "", "unidade_id": "" }
                                let objSelected = this.state.optionSelected
                                let entradas4a20 = resp.data
                                objSelected['sinal_id'] = {}
                                objSelected['unidade_id'] = {}
                                if (resp.status === 204) {
                                    entradas4a20 = []
                                }
                                this.setState({ "entrada4a20": entrada4a20, "entradas4a20": entradas4a20, "id_editando_entrada4a20": false, optionSelected: objSelected, unidade: [] })
                            })
                    })
            } else {
                axios.delete(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/' + this.props.match.params.id + '/entrada-logica/' + this.state[source][i]['id'] + '/', config_me)
                    .then((response) => {
                        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/' + this.props.match.params.id + '/entrada-logica/', config_me)
                            .then((resp) => {
                                let entradalogica = { "canal": "", "tipo_entrada_logica_id": "", "pulso": "", "ajuste": "", "primeiro_dia_mes": "" }
                                let objSelected = this.state.optionSelected
                                let entradaslogica = resp.data
                                objSelected['tipo_entrada_logica_id'] = {}
                                if (resp.status === 204) {
                                    entradaslogica = []
                                }
                                this.setState({ "entradalogica": entradalogica, "entradaslogicas": entradaslogica, "id_editando_entradalogica": false, optionSelected: objSelected, unidade: [], totalizadorentradalogica: 0 })
                            })
                    })
            }
        } else {
            let data = this.state[source];
            delete data[i];
            let newdata = {};
            newdata[source] = data;
            this.setState(newdata)
        }
    }

    render() {
        const { releSelecionado } = this.state
        const idEntradaLogica = this.state.entradalogica.tipo_entrada_logica_id
        const { selectedOption } = this.state;
        const selected4a20OptionSinal = this.state.optionSelected.sinal_id;
        const selectedUnidade = this.state.optionSelected.unidade_id;
        const selectedEntradaLogica = this.state.optionSelected.tipo_entrada_logica_id;
        const timezone = this.state.selectedOptionTimezone;
        const intervalo_csq = this.state.selectedOptionCSQ;

        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        const { Option } = components
        const IconOption = (props) => (
            <Option {...props}>
                <b id="font">{props.data.label}</b>
                <i style={{ fontSize: '16px' }} className="fa fa-square"></i>
            </Option>
        )

        const colourStyles = {
            option: (styles, { data }) => {
                return {
                    ...styles,
                    color: data.estado ? '#42c949' : "#d93f3f",
                    display: 'flex',
                    justifyContent: 'space-between'
                }
            },
        }

        return (
            <div>

                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.setState(this.state.aviso.args)}
                    open={this.state.aviso.show}
                />

                <h4>Configuração do Equipamento: {this.state.detalhes.hidrohub__imei}</h4>

                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Configuração</Tab>
                        <Tab>Entradas 4a20</Tab>
                        <Tab>Entradas Lógicas</Tab>
                    </TabList>

                    <TabPanel>
                        {selectedOption ? <Modal
                            isOpen={this.state.releModal}
                            onClosed={() => this.setState({ releModal: false })}
                            toggle={() => this.setState({ releModal: false })} >
                            <ModalHeader toggle={() => this.setState({ releModal: false })}>
                                Situação do equipamento: {this.state.detalhes.hidrohub__imei}
                            </ModalHeader>
                            <ModalBody>
                                {selectedOption ?
                                    <>
                                        <strong>Frequência do reporte (Minutos): </strong>{this.state.detalhes.frequencia_reporte_konect}
                                        {(this.state.detalhes.hidrohub__modelo_id === 5)
                                        ?
                                        <>
                                            <br />
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <label>Selecione o Relê</label>
                                                    <Select
                                                        components={{ Option: IconOption }}
                                                        placeholder=""
                                                        value={this.state.releSelecionado}
                                                        options={this.state.releOptions}
                                                        onChange={(e) => this.handleSelectRele(e)}
                                                        styles={colourStyles}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    {releSelecionado ?
                                                        <label>Estado<br />
                                                            <input
                                                                style={{ marginTop: '8px' }}
                                                                type="checkbox"
                                                                checked={releSelecionado.estado}
                                                                onChange={this.atualizarRele}
                                                            /><br /> {releSelecionado.estado ? <small>ligado</small> : <small>desligado</small>}
                                                        </label>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null}
                                    </>
                                    : null}
                                <div className="float-right" style={{ marginTop: '35px' }}>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.saveAction(true)
                                            this.setState({ releModal: false })
                                        }}>
                                        Concluído
                                    </Button>
                                </div>
                            </ModalBody>
                        </Modal>
                            : null}
                        <form className="hdv-form-default">
                            {(this.state.detalhes.hidrohub__modelo_id === 5 || this.state.detalhes.hidrohub__modelo_id === 19)
                                ?
                                <>
                                    <label className="required" htmlFor="frequencia_reporte">Frequência de Reporte (Minutos)</label>
                                    <div className="row">
                                        <div className="col-md-9" style={{ display: 'flex', alignItems: 'center' }}>
                                            <input type="number" className="hdv-param-number" readOnly value={this.state.detalhes.frequencia_reporte_konect} onChange={(e) => this.handleChange(e)} id="frequencia_reporte_konect" name="frequencia_reporte_konect" />
                                            <div className="hdv-param-buttons">
                                                <button onClick={(e) => this.changeFrequencia(e, '+')} >▲</button>
                                                <button onClick={(e) => this.changeFrequencia(e, '-')}>▼</button>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <button
                                                style={{ width: '100%', height: '100%' }}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.setState({ releModal: true })
                                                    this.getReleData()
                                                }} className="hdv-btn-forms hdv-btn-blue">Enviar Comando</button>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <label className="required" htmlFor="frequencia_reporte">Frequência de Reporte</label>

                                    <Select
                                        value={selectedOption}
                                        onChange={(e) => this.handleChangeSelect(e, 'frequencia_reporte')}
                                        options={this.state.reportes}
                                    />
                                </>
                            }
                            <label htmlFor="timezone">Timezone do Equipamento</label>
                            <Select
                                value={timezone}
                                onChange={(e) => this.handleChangeSelectTimezone(e, 'timezone')}
                                options={this.state.timezone}
                            />
                            <label>Tempo para criação de Alerta sem comunicação (Minutos)</label>
                            <div className="row">
                                <div className="col-md-12">
                                    <input
                                        type="number"
                                        value={this.state.detalhes.tempo_alerta_sem_comunicacao}
                                        onChange={(e) => {
                                            let { detalhes } = this.state
                                            if (e.target.value?.length === 7) return

                                            detalhes['tempo_alerta_sem_comunicacao'] = e.target.value
                                            this.setState({ detalhes })
                                        }}

                                    />
                                </div>
                            </div>
                            {
                                (this.state.ultimo_reporte_equipamento !== false)
                                    ?
                                    (this.state.ultimo_reporte_equipamento.versao === "04")
                                        ?
                                        <span>
                                            <label htmlFor="timezone">Velocidade de Captura de Dados</label>
                                            <Select
                                                value={intervalo_csq}
                                                onChange={(e) => this.handleChangeSelectCSQ(e, 'intervalo_csq')}
                                                options={this.state.intervalos_csq}
                                            />
                                        </span>
                                        :
                                        ""
                                    :
                                    ""
                            }
                            <label htmlFor="timezone">Aplica Cálculo nas Entradas Digitais e Analógicas</label>
                            <input type="checkbox" checked={(this.state.detalhes.aplica_calculo) ? true : false} id="aplica_calculo" name="aplica_calculo" onChange={(e) => this.handleChangeCheckbox(e)} />
                        </form>
                    </TabPanel>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="required" htmlFor="descricao">Descrição</label>
                                    <input
                                        className={this.checkErrorField("descricao")}
                                        type="text" name="descricao" id="descricao" value={this.state.entrada4a20.descricao} onChange={this.handle4a20Change} />
                                    <span id="validacao-descricao-4a20"></span>
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="sinal_id">Sinal 4a20</label>
                                    <Select
                                        value={selected4a20OptionSinal}
                                        onChange={(e) => this.handleChange4a20SelectSinal(e, 'sinal_id')}
                                        options={this.state.configuracaoentradas4a20}
                                    />
                                    <span id="validacao-sinal-4a20"></span>
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="unidade_id">Unidade</label>
                                    <Select
                                        value={selectedUnidade}
                                        onChange={(e) => this.handleChange4a20Select(e, 'unidade_id')}
                                        options={this.state.unidades}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="canal">Canal</label>
                                    <input
                                        className={this.checkErrorField("canal")}
                                        type="number" onKeyPress={(e) => e.preventDefault()} min="1" max="10" name="canal" id="canal" value={this.state.entrada4a20.canal} onChange={this.handle4a20Change} />
                                    <span id="validacao-canal-4a20"></span>
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="valor_minimo">Valor Minimo</label>
                                    <input
                                        className={this.checkErrorField("valor_minimo")}
                                        type="text" name="valor_minimo" id="valor_minimo" value={this.state.entrada4a20.valor_minimo} onChange={this.handle4a20Change} />
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="valor_maximo">Valor Máximo</label>
                                    <input
                                        className={this.checkErrorField("valor_maximo")}
                                        type="text" name="valor_maximo" id="valor_maximo" value={this.state.entrada4a20.valor_maximo} onChange={this.handle4a20Change} />
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="inverter">Inverter</label>
                                    <input
                                        className={this.checkErrorField("inverter")}
                                        type="text" name="inverter" id="inverter" value={this.state.entrada4a20.inverter} onChange={this.handle4a20Change} />
                                </div>
                            </div>
                        </form>
                        <button className={(Number.isInteger(this.state.id_editando_entrada4a20)) ? "hdv-btn-forms hdv-top15 hdv-btn-yellow" : "hdv-btn-forms hdv-top15 hdv-btn-blue"}
                            onClick={this.addNovo4a20} >
                            {(Number.isInteger(this.state.id_editando_entrada4a20)) ? <i className="fa fa-refresh"></i> : <i className="fa fa-plus"></i>}
                            {(Number.isInteger(this.state.id_editando_entrada4a20)) ? "Atualizar Configuração" : "Adicionar Configuração"}
                        </button>
                        <button className={(Number.isInteger(this.state.id_editando_entrada4a20)) ? "hdv-btn-forms hdv-btn-orange" : "hdv-btn-forms hdv-btn-orange hdv-noshow-item"}
                            onClick={this.cancelEditEntrada4a20} > <i className="fa fa-cancel"></i> Cancelar Configuração
                        </button>
                        <h6 className="hdv-subtable-title">Lista de Entradas Configuradas</h6>
                        <table className="hdv-subtable">
                            <thead>
                                <tr>
                                    <td>Descrição</td>
                                    <td>Sinal</td>
                                    <td>Valor Mínimo</td>
                                    <td>Valor Máximo</td>
                                    <td>Ações</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.createRows("entrada4a20", "entradas4a20", "descricao", "sinal__descricao")}
                            </tbody>
                        </table>
                    </TabPanel>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="required" htmlFor="descricao">Descrição</label>
                                    <input
                                        className={this.checkErrorField("descricao")}
                                        type="text" name="descricao" id="descricao" value={this.state.entradalogica.descricao} onChange={this.handleEntradaLogicaChange} />
                                    <span id="validacao-descricao-logica"></span>
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="tipo_entrada_logica_id">Entrada Lógica</label>
                                    <Select
                                        value={selectedEntradaLogica}
                                        onChange={(e) => this.handleChangeEntradaLogicaSelect(e, 'tipo_entrada_logica_id')}
                                        options={this.state.configuracaoentradaslogicas}
                                    />
                                    <span id="validacao-tipo_entrada_logica-logica"></span>
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="custo_unidade">Custo por Unidade R$</label>
                                    <input
                                        type="text"
                                        name="custo_unidade"
                                        id="custo_unidade"
                                        value={this.state.entradalogica.custo_unidade}
                                        onChange={(e) => this.formatar(e)}
                                    />

                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="canal">Canal</label>
                                    <input
                                        className={this.checkErrorField("canal")}
                                        type="number" onKeyPress={(e) => e.preventDefault()} min="1" max="16" name="canal" id="canal" value={this.state.entradalogica.canal} onChange={this.handleEntradaLogicaChange} />
                                    <span id="validacao-canal-logica"></span>
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="primeiro_dia_mes">Primeiro dia do mês</label>
                                    <input
                                        className={this.checkErrorField("primeiro_dia_mes")}
                                        type="number" onKeyPress={(e) => e.preventDefault()} min="0" max="31" name="primeiro_dia_mes" id="primeiro_dia_mes" value={this.state.entradalogica.primeiro_dia_mes} onChange={this.handleEntradaLogicaChange} />
                                </div>
                                <div className={(idEntradaLogica === 3 || idEntradaLogica === 4) ? "display-none" : "col-md-4"}>
                                    <label className="required" htmlFor="pulso">Valor Pulso</label>
                                    <input
                                        className={this.checkErrorField("pulso")}
                                        type="text" name="pulso" id="pulso" value={this.state.entradalogica.pulso} onChange={this.handleEntradaLogicaChange} />
                                </div>
                                <div className={(idEntradaLogica === 3 || idEntradaLogica === 4) ? "display-none" : "col-md-4"}>
                                    <label className="required" htmlFor="ajuste">Ajuste</label>
                                    <input
                                        className={this.checkErrorField("ajuste")}
                                        type="text" name="ajuste" id="ajuste" value={this.state.entradalogica.ajuste} onChange={this.handleEntradaLogicaChange} />
                                </div>
                                <div className={(idEntradaLogica === 3 || idEntradaLogica === 4) ? "display-none" : "col-md-4"}>
                                    <label className="required" htmlFor="totalizador">Valor Totalizador</label>
                                    <input disabled
                                        className={this.checkErrorField("totalizador")}
                                        type="text" name="totalizador" id="totalizador" value={this.state.totalizadorentradalogica} onChange={this.handleEntradaLogicaChange} />
                                </div>
                            </div>
                        </form>
                        <button className={(Number.isInteger(this.state.id_editando_entradalogica)) ? "hdv-btn-forms hdv-top15 hdv-btn-yellow" : "hdv-btn-forms hdv-top15 hdv-btn-blue"}
                            onClick={this.addNovoEntradaLogica} >
                            {(Number.isInteger(this.state.id_editando_entradalogica)) ? <i className="fa fa-refresh"></i> : <i className="fa fa-plus"></i>}
                            {(Number.isInteger(this.state.id_editando_entradalogica)) ? "Atualizar Entrada Lógica" : "Adicionar Entrada Lógica"}
                        </button>
                        <button className={(Number.isInteger(this.state.id_editando_entradalogica)) ? "hdv-btn-forms hdv-btn-orange" : "hdv-btn-forms hdv-btn-orange hdv-noshow-item"}
                            onClick={this.cancelEditEntradaLogica} > <i className="fa fa-cancel"></i> Cancelar Entrada Lógica
                        </button>
                        <h6 className="hdv-subtable-title">Lista de Entradas Lógicas</h6>
                        <table className="hdv-subtable">
                            <thead>
                                <tr>
                                    <td>Descrição</td>
                                    <td>Entrada Lógica</td>
                                    <td>Canal</td>
                                    <td>Valor Pulso</td>
                                    <td>Ajuste</td>
                                    <td>Primeiro dia do mês</td>
                                    <td>Ações</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.createRowsEntradaLogica("entradalogica", "entradaslogicas", "descricao", "tipo_entrada_logica__descricao", "canal")}
                            </tbody>
                        </table>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <button onClick={() => this.saveAction()} className="hdv-btn-forms hdv-btn-geen">Salvar</button>
                    <Link to="/equipamento">
                        <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>

            </div>
        );
    }
}

export default Form;
