import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom'
import "react-tabs/style/react-tabs.css";
import axios from 'axios'
import Select from 'react-select';
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import Mapa from '../../components/Mapa'
import Aviso from '../../components/Aviso'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class Form extends Component {

    state = {
        mapObject: false,
        address: "",
        marker: false,
        location: false,
        detalhes: {"nome": "", "tipo_id": "", "latitude": 0, "longitude": 0, "point": "", "cliente_id": "" },
        formerrors: [],
        redirect: false,
        path: "",
        setores: [],
        tipos: [],
        clientes: [],
        item: { "equipamento_id": "", "entrada_tipo": "", "entrada_id": "" },
        optionSelected: { "tipo_id": {}, "cliente_id": {}, "equipamento_id": {}, "entrada_tipo": {}, "entrada_id": {} },
        tipos_entradas: [{ value: "entrada_analogica", label: "Entradas Analógicas" }, { value: "entrada_digital", label: "Entradas Digitais" }],
        array_entrada_selecionada: [],
        equipamentos: [],
        itens_ligados: [],
        aviso: { show: false, args: null, message: '' },
        cooldown: false,
        cancelModal: null
    }

    updateMapObject = (mapObj) => {
        mapObj.google_map.maps.event.addListener(mapObj.map, 'click', (event) => {
            this.placeMarker(event.latLng)
        })

        this.setState({ mapObject: mapObj })

        if (this.props.match.params.id) {
            this.getData(mapObj)
        }
    }

    updatePoint = (location) => {
        this.setState({ location: location, address: location.toJSON().lat.toFixed(6) + ", " + location.toJSON().lng.toFixed(6) })
    }

    placeMarker = (location) => {
        if (this.state.marker) {
            this.state.marker.setMap(null);
        }
        let marker = new this.state.mapObject.google_map.maps.Marker({
            map: this.state.mapObject.map,
            position: location,
            draggable: true
        });

        marker.addListener('dragend', (event) => this.updatePoint(event.latLng))

        this.setState({ marker: marker, location: location, address: location.toJSON().lat.toFixed(6) + ", " + location.toJSON().lng.toFixed(6) })
    }

    componentWillMount = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado-tipo/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/equipamento/', config_me))
        if (this.props.match.params.id) {
            requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado/' + this.props.match.params.id + "/item/", config_me))
        }

        axios.all(requestArray)
            .then(axios.spread((clientes, tipos, equipamentos, itensligados) => {
                let optionsCliente = []
                if (clientes.status === 200) {
                    clientes.data.forEach((a) => {
                        optionsCliente.push({ "value": a.id, "label": a.nome_fantasia })
                    })
                }
                let optionsTipos = []
                if (tipos.status === 200) {
                    tipos.data.forEach((a) => {
                        optionsTipos.push({ "value": a.id, "label": a.nome })
                    })
                }
                let optionsEquipamentos = []
                if (equipamentos.status === 200) {
                    equipamentos.data.forEach((a) => {
                        optionsEquipamentos.push({ "value": a.id, "label": a.imei })
                    })
                }

                let optionsItensLigados = []
                if (itensligados) {
                    if (itensligados.status === 200) {
                        optionsItensLigados = itensligados.data
                    }
                }
                this.setState({ "clientes": optionsCliente, "tipos": optionsTipos, 'equipamentos': optionsEquipamentos, 'itens_ligados': optionsItensLigados })
            }));
    }

    /*componentDidMount = () => {
        if(this.props.match.params.id){
            this.getData()
        }
    }*/

    getData = (mapObj) => {
        let resource = "monitorado"
        let userInfo = auth.getUserInfo();
        let requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        let self = this

        axios.get(requestURL, config_me)
            .then((response_id) => {
                delete response_id.data.id

                var optionObj = self.state.optionSelected
                optionObj["tipo_id"] = { "value": response_id.data.tipo_id, "label": response_id.data.tipo__nome }
                optionObj["cliente_id"] = { "value": response_id.data.cliente_id, "label": response_id.data.cliente__nome_fantasia }

                let point = new mapObj.google_map.maps.LatLng(response_id.data.latitude, response_id.data.longitude);

                let bounds = new mapObj.google_map.maps.LatLngBounds()
                bounds.extend(point)
                mapObj.map.fitBounds(bounds)

                mapObj.map.setCenter(point);
                let marker = new mapObj.google_map.maps.Marker({
                    map: mapObj.map,
                    position: point,
                    draggable: true
                });
                marker.addListener('dragend', function (event) {
                    self.updatePoint(event.latLng);
                });
                self.setState({ marker: marker, location: point, detalhes: response_id.data, optionSelected: optionObj, address: response_id.data.point })

            })
    }


    handleChangeSelectCliente = (selectedOption, field) => {

        let obj = this.state.detalhes
        obj[field] = selectedOption.value

        var optionObj = this.state.optionSelected
        optionObj["cliente_id"] = { "value": selectedOption.value, "label": selectedOption.label }

        this.setState({detalhes: obj, selectedOption: optionObj });

        document.querySelector('#validacao-cliente').innerHTML = ''
    }

    handleChange = (e) => {
        let obj = this.state.detalhes
        obj[e.target.id] = e.target.value
        if (document.querySelector(`#validacao-${e.target.name}`))
            document.querySelector(`#validacao-${e.target.name}`).innerHTML = ''
        this.setState({ "detalhes": obj })
    }

    handleChangeSelect = (selectedOption, field) => {
        console.log(selectedOption, field)
        let obj = this.state.detalhes
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        if (document.querySelector(`#validacao-${field.slice(0, -3)}-logica`))
            document.querySelector(`#validacao-${field.slice(0, -3)}-logica`).innerHTML = ""
        this.setState({ "optionSelected": optionObj, "detalhes": obj });
    }

    openModalCancel = () => {
        this.setState({cancelModal: true})
    }

    closeModalCancel = () => {
        this.setState({cancelModal: false})
    }

    confirmModalCancel = () => {
        window.location = "/monitorado"
    }

    saveAction = (novo_cadastro, grid = false) => {
        this.setState({ cooldown: true })
        let userInfo = auth.getUserInfo();
        let resource = "monitorado"
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/';
            verb = 'post'
        }
        let bodyFormData = new FormData();

        for (let key in this.state.detalhes) {
            bodyFormData.append(key, this.state.detalhes[key]);
        }

        if (this.state.location) {
            bodyFormData.append('latitude', this.state.location.toJSON().lat)
            bodyFormData.append('longitude', this.state.location.toJSON().lng)
        } else {
            document.querySelector(`#validacao-location`).innerHTML = "Este campo não pode estar vazio."
        }

        bodyFormData.append('point', this.state.address)

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (novo_cadastro === true) {
                    this.setState({ redirect: true, path: "/monitorado/novo" })
                } else {
                    if (grid === true) {
                        const aviso = { show: true, message: `Monitorado ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`, args: { redirect: true, path: "/monitorado" } }
                        this.setState({ aviso })
                    } else {
                        const aviso = { show: true, message: `Monitorado ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`, args: { redirect: true, path: "/monitorado/" + response.data.id + "/editar/" } }
                        this.setState({ aviso })
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                let erros = error.response.data
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span)
                            span.innerHTML = erros[i][1]
                    }
                }
            })
        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    generateOption = (source, value, desc) => {
        let options = source.map((obj, idx) =>
            <option key={idx} value={obj[value]}>{obj[desc]}</option>
        );
        return options;
    }

    searchPointInMap = () => {
        let geocoder = new this.state.mapObject.google_map.maps.Geocoder()
        if (this.state.marker) {
            this.state.marker.setMap(null);
        }

        geocoder.geocode({ 'address': this.state.address }, (results, status) => {
            if (status === 'OK') {
                this.state.mapObject.map.setCenter(results[0].geometry.location);
                let marker = new this.state.mapObject.google_map.maps.Marker({
                    map: this.state.mapObject.map,
                    position: results[0].geometry.location,
                    draggable: true
                });
                marker.addListener('dragend', (event) => this.updatePoint(event.latLng))
                this.setState({ marker: marker, location: results[0].geometry.location, address: results[0].geometry.location.toJSON().lat.toFixed(6) + ", " + results[0].geometry.location.toJSON().lng.toFixed(6) })
            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    handleAddress = (e) => {
        this.setState({ "address": e.target.value })
        document.querySelector('#validacao-location').innerHTML = ""
    }

    keyPressSearchAddress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            this.searchPointInMap()
        }
    }

    handleChangeSelectEntradaId = (selectedOption, field) => {
        console.log(selectedOption, field)
        var obj = this.state.item
        obj[field] = selectedOption.value
        var optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        this.setState({ "optionSelected": optionObj, "item": obj });
    }

    handleChangeSelectEquipamento = (selectedOption, field) => {
        console.log(selectedOption, field)
        var obj = this.state.item
        obj[field] = selectedOption.value
        var optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/buscar-entradas-hidrohub-disponiveis/' + selectedOption.label + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((response) => {
                this.setState({ "optionSelected": optionObj, "item": obj, "entradas_configuradas": response.data });
            })

        if (document.querySelector(`#validacao-${field.slice(0, -3)}`))
            document.querySelector(`#validacao-${field.slice(0, -3)}`).innerHTML = ""

    }

    handleChangeSelectTipo = (selectedOption, field) => {
        var obj = this.state.item
        obj[field] = selectedOption.value
        var optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        let arrayEntradaSelecionada = []

        if (selectedOption.value === "entrada_analogica") {
            this.state.entradas_configuradas.entrada4a20.forEach((value) => {
                arrayEntradaSelecionada.push({ "value": value.id, "label": "#" + value.canal + " " + value.descricao })
            })
        } else if (selectedOption.value === "entrada_digital") {
            this.state.entradas_configuradas.entradalogica.forEach((value) => {
                arrayEntradaSelecionada.push({ "value": value.id, "label": "#" + value.canal + " " + value.tipo_entrada_logica__descricao })
            })
        }

        this.setState({ "optionSelected": optionObj, "item": obj, "array_entrada_selecionada": arrayEntradaSelecionada });

    }

    createRows = (source, campo_principal, campo_secundario) => {
        return this.state[source].map((campo, i) =>
            <tr key={i}>
                <td>
                    {campo[campo_principal]}
                </td>
                <td>
                    {"#" + campo['entrada_canal']} {campo[campo_secundario]} {(campo['entrada_tipo'] === 'entrada_digital') ? "(Entrada Digital)" : "(Entrada Analógica)"}
                </td>
                <td className="hdv-subtable-action-td">
                    <button
                        onClick={() => { this.removeItem(campo['id']) }}
                        className="hdv-btn-forms hdv-btn-red">Deletar</button>
                </td>
            </tr>
        );
    }

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    addNovoItem = () => {
        if (this.props.match.params.id) {

            let userInfo = auth.getUserInfo();
            var bodyFormData = new FormData();

            for (var key in this.state.item) {
                bodyFormData.append(key, this.state.item[key]);
            }

            bodyFormData.append('cliente_id', this.state.detalhes.cliente_id)
            bodyFormData.append('monitorado_id', this.props.match.params.id)
            bodyFormData.append('nome', "")

            axios({
                method: "post",
                url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/item/',
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then((response) => {
                    let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                    axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado/' + this.props.match.params.id + "/item/", config_me)
                        .then((resp) => {
                            let item = { "equipamento_id": "", "entrada_tipo": "", "entrada_id": "" }
                            let selecionados = this.state.optionSelected
                            selecionados['equipamento_id'] = {}
                            selecionados['entrada_tipo'] = {}
                            selecionados['entrada_id'] = {}
                            this.setState({ "item": item, "itens_ligados": resp.data, "optionSelected": selecionados })
                        })
                })
                .catch((error) => {
                    console.log(error)
                    let erros = error.response.data
                    if (Array.isArray(erros)) {
                        for (let i = 0; i < erros.length; i++) {
                            let span = document.querySelector(`#validacao-${erros[i][0]}`)
                            if (span)
                                span.innerHTML = erros[i][1]
                        }
                    }
                })

        }
    }

    removeItem = (id) => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.delete(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/item/' + id + '/', config_me))

        axios.all(requestArray)
            .then(axios.spread((acct, perms) => {

                const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado/' + this.props.match.params.id + "/item/"
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

                axios.get(requestURL, config_me)
                    .then((response) => this.setState({ "itens_ligados": response.data }))
            }))
    }

    render() {
        console.log(this.state)

        const selectedTipo = this.state.optionSelected.tipo_id;
        const selectedCliente = this.state.optionSelected.cliente_id;
        const selectedEquipamento = this.state.optionSelected.equipamento_id;
        const selectedTipoEntrada = this.state.optionSelected.entrada_tipo;
        const selectedArrayEntradaSelecionada = this.state.optionSelected.entrada_id;

        if (this.state.location && document.querySelector(`#validacao-location`) !== null)
            document.querySelector(`#validacao-location`).innerHTML = ""

        if (this.state.redirect === true) {
            return <Redirect to={{
                pathname: '/redirect',
                state: { pathto: this.state.path }
            }}
            />
        }

        return (
            <div>
                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.setState(this.state.aviso.args)}
                    open={this.state.aviso.show}
                />

                <Modal isOpen={this.state.cancelModal}>
                    <ModalHeader>
                        Cancelar
                    </ModalHeader>
                    <ModalBody>
                        {this.props.match.params.id ? <h6>Você tem certezade que deseja cancelar a edição do cadastro de monitorados?</h6> : <h6>Você tem certezade que deseja cancelar a criação do cadastro de monitorados?</h6>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModalCancel}>
                            Não
                        </Button>
                        <Button color="danger" onClick={this.confirmModalCancel}>
                            Sim
                        </Button>
                    </ModalFooter>
                </Modal>

                {this.props.match.params.id ? <h4>Editar Monitorado</h4> : <h4>Novo Monitorado</h4>}


                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Detalhes do Monitorado</Tab>
                        <Tab disabled={(this.props.match.params.id) ? false : true} onClick={this.state.busca_itensligado}>Itens</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <label className="required" htmlFor="cliente_id">Cliente</label>
                            <Select
                                value={selectedCliente}
                                onChange={(e) => this.handleChangeSelectCliente(e, 'cliente_id')}
                                options={this.state.clientes}
                            />
                            <span id="validacao-cliente"></span>
                            <label className="required" htmlFor="nome">Nome do  Monitorado</label>
                            <input type="text" name="nome" id="nome" value={this.state.detalhes.nome} onChange={this.handleChange} />
                            <span id="validacao-nome"></span>
                            <label className="required" htmlFor="tipo">Tipo</label>
                            <Select
                                value={selectedTipo}
                                onChange={(e) => this.handleChangeSelect(e, 'tipo_id')}
                                options={this.state.tipos}
                            />
                            <span id="validacao-tipo"></span>
                            <label className="required" htmlFor="nome">Selecionar Ponto no Mapa</label>
                            <input type="text" name="address" id="address" value={this.state.address} onKeyPress={this.keyPressSearchAddress} onChange={this.handleAddress} />
                            <span id="validacao-location"></span>
                            <div className="hdv-mapform">
                                <Mapa mapObjectFn={this.updateMapObject} />
                            </div>
                        </form>
                    </TabPanel>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="required" htmlFor="equipamento">Equipamento</label>
                                    <Select
                                        value={selectedEquipamento}
                                        onChange={(e) => this.handleChangeSelectEquipamento(e, 'equipamento_id')}
                                        options={this.state.equipamentos}
                                    />
                                    <span id="validacao-equipamento"></span>
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="entrada_tipo">Tipo Entrada</label>
                                    <Select
                                        value={selectedTipoEntrada}
                                        onChange={(e) => this.handleChangeSelectTipo(e, 'entrada_tipo')}
                                        options={this.state.tipos_entradas}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="entrada_id">Entrada</label>
                                    <Select
                                        value={selectedArrayEntradaSelecionada}
                                        onChange={(e) => this.handleChangeSelectEntradaId(e, 'entrada_id')}
                                        options={this.state.array_entrada_selecionada}
                                    />
                                </div>
                            </div>
                        </form>
                        <button className="hdv-btn-forms hdv-top15 hdv-btn-blue"
                            onClick={this.addNovoItem} >
                            <i className="fa fa-plus"></i>
                            Adicionar Item
                        </button>


                        <h6 className="hdv-subtable-title">Lista de itens cadastrados</h6>
                        <table className="hdv-subtable">
                            <thead>
                                <tr>
                                    <td>Equipamento</td>
                                    <td>Descrição Entrada</td>
                                    <td>Ações</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.createRows("itens_ligados", "equipamento__imei", "entrada_descricao")}
                            </tbody>
                        </table>
                    </TabPanel>

                </Tabs>

                <div className="hdv-btn-group">
                    {(this.props.match.params.id) ? "" : <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>Salvar e continuar</button>}
                    {(this.props.match.params.id) ? <button onClick={() => this.saveAction(false, true)} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>Salvar</button> : ""}
                    {(this.props.match.params.id) ? <button onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-geenlight" disabled={this.state.cooldown}>Salvar e Cadastrar um Novo</button> : ""}
                        <button onClick={() => this.openModalCancel()} className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                </div>

            </div>
        );
    }
}

export default Form;