import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom'
import "react-tabs/style/react-tabs.css";
import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import Aviso from '../../components/Aviso'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class Form extends Component {

    state = {
        detalhes: { "nome": "" },
        formerrors: [],
        redirect: false,
        path: "",
        permissoes: [],
        open_group_block: {},
        selected_permission: [],
        aviso: { show: false, args: null, message: '' },
        cooldown: false,
        cancelModal: null
    }

    componentWillMount = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/permissoes-cliente/', config_me))

        axios.all(requestArray)
            .then(axios.spread((permissoes) => {
                let open_close_group_block = {}
                permissoes.data.forEach(grupo => {
                    open_close_group_block[grupo.grupo.toLowerCase()] = true
                });
                this.setState({ "permissoes": permissoes.data, open_group_block: open_close_group_block })
            }))
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }
    }

    getData = () => {
        let resource = "cliente/perfil"
        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((response) => {
                let permissoes = response.data.permissoes
                let selected_permission = []
                delete response.data.id
                delete response.data.permissoes
                permissoes.forEach((v) => {
                    selected_permission.push(v.id)
                })
                this.setState({ detalhes: response.data, selected_permission: selected_permission });
            })
    }

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    handleChange = (e) => {
        let obj = this.state.detalhes

        if (e.target.type === "checkbox") {
            obj[e.target.id] = (e.target.checked === true) ? true : false
        } else {
            obj[e.target.id] = e.target.value
        }

        if (document.querySelector(`#validacao-${e.target.name}`))
            document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""
        this.setState({ "detalhes": obj })
    }

    openModalCancel = () => {
        this.setState({cancelModal: true})
    }

    closeModalCancel = () => {
        this.setState({cancelModal:false})
    }

    confirmModalCancel = () => {
        window.location = "/perfil/cliente"
    }

    saveAction = (novo_cadastro) => {
        this.setState({ cooldown: true })
        let userInfo = auth.getUserInfo();
        let resource = "cliente/perfil"
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/';
            verb = 'post'
        }
        let bodyFormData = new FormData();

        for (let key in this.state.detalhes) {
            bodyFormData.append(key, this.state.detalhes[key]);
        }

        bodyFormData.append('permissoes', this.state.selected_permission);

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (novo_cadastro === true) {
                    if (this.props.match.params.id) {
                        const aviso = { show: true, message: `Perfil ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`, args: { redirect: true, path: "/perfil/cliente" } }
                        this.setState({ aviso })
                    } else
                        window.location.reload()

                } else {
                    const aviso = { show: true, message: `Perfil ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`, args: { redirect: true, path: "/perfil/cliente" } }
                    this.setState({ aviso })
                }
            })
            .catch(error => {
                console.log(error)
                let erros = error.response.data
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span) {
                            span.innerHTML = erros[i][1]
                            window.scrollTo({ top: 50, behavior: 'smooth' })
                        }
                    }
                }
            })
        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    change_block_view = (group) => {
        let open_block_group = this.state.open_group_block
        open_block_group[group] = !open_block_group[group]
        this.setState({ open_group_block: open_block_group })
    }

    change_permission_option = (id) => {
        let selected_permission = this.state.selected_permission
        if (selected_permission.includes(id)) {
            selected_permission = selected_permission.filter(item => item !== id)
        } else {
            selected_permission.push(id)
        }
        this.setState({ selected_permission: selected_permission })
    }

    change_permission_group = (event, group) => {
        let selected_permission = this.state.selected_permission
        if (event.target.checked) {
            this.state.permissoes[group].permissoes.forEach((permissao) => {
                selected_permission.push(permissao.id)
            })
        } else {
            this.state.permissoes[group].permissoes.forEach((permissao) => {
                selected_permission = selected_permission.filter(item => item !== permissao.id)
            })
        }
        this.setState({ selected_permission: selected_permission })
    }

    create_group_permission = () => {
        return this.state.permissoes.map((v, i) => {
            return (
                <div key={i} className="hdv-block-group">
                    <div className="hdv-title-create-profile">
                        <input onChange={(e) => this.change_permission_group(e, i)} type="checkbox" /> {v.grupo}
                        <span className="hdv-open-close-block-group"><i onClick={() => this.change_block_view(v.grupo.toLowerCase())} className={(this.state.open_group_block[v.grupo.toLowerCase()]) ? "fa fa-arrow-circle-up" : "fa fa-arrow-circle-down"} ></i></span>
                    </div>
                    {
                        v.permissoes.map((p, i) => {
                            return (
                                <div key={i} className={(this.state.open_group_block[v.grupo.toLowerCase()]) ? "hdv-permission-block" : "hdv-noshow-item"} >
                                    <input checked={(this.state.selected_permission.includes(p.id)) ? true : false} onChange={() => this.change_permission_option(p.id)} type="checkbox" />
                                    <span className="hdv-permission-description">{p.descricao}</span>
                                </div>
                            )
                        })
                    }
                </div>
            )
        })
    }

    render() {
        console.log(this.state)

        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        return (
            <div>
                <Modal isOpen={this.state.cancelModal}>
                    <ModalHeader>
                        Cancelar
                    </ModalHeader>
                    <ModalBody>
                        {this.props.match.params. id ? <h6>Você tem certeza de que deseja cancelar a edição do perfil do cliente?</h6> : <h6>Você tem certeza de que deseja cancelar a criação do perfil do cliente?</h6>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModalCancel}>
                            Não
                        </Button>
                        <Button color="danger" onClick={this.confirmModalCancel}>
                            Sim
                        </Button>
                    </ModalFooter>
                </Modal>

                {this.props.match.params.id ? <h4>Editar Perfil</h4> : <h4>Novo Perfil</h4>}


                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.setState(this.state.aviso.args)}
                    open={this.state.aviso.show}
                />
                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Detalhes do Perfil</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <label htmlFor="nome">Nome do Perfil</label>
                            <input type="text" name="nome" id="nome" value={this.state.detalhes.nome} onChange={this.handleChange} />
                            <span id="validacao-nome"></span>
                            {/*<label htmlFor="tipo">Administrador</label>
                            <input type="checkbox" name="tipo" id="tipo" checked={this.state.detalhes.tipo} onChange={this.handleChange} />*/}
                            <label htmlFor="">Selecionar Recursos</label>
                            {this.create_group_permission()}
                        </form>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>Salvar</button>
                    <button onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-geenlight" disabled={this.state.cooldown}>Salvar e Cadastrar um Novo</button>
                        <button onClick={() => this.openModalCancel()} className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                </div>

            </div>
        );
    }
}

export default Form;