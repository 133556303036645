import React from "react";
import { Button } from "reactstrap";

export default class AvisoPersonalizado extends React.Component {

  handleOk = () => {
    if(this.props.onOk) {
      this.props.onOk();
    }
    if(typeof this.props.action === 'function') {
      this.props.action();
    } else {
      console.error('props.action is not a function');
    }
  }

  render() {
    return (
      <div className="aviso-backdrop" hidden={!this.props.open}>
        <div className="aviso-feedback">
          <h5><i className="fa fa-exclamation fa-20px"></i> Aviso</h5>
          {this.props.mensagem}
          <br/>
          <hr/>
          <div className="aviso-buttons">
            {this.props.continue ? <>
                <Button color="secondary" onClick={this.props.continue}>Continuar</Button>&nbsp;
              </>
              :
              null
            }
            <Button color="success" onClick={this.handleOk}>OK</Button>&nbsp;
          </div>
        </div>
      </div>
    );
  }
}
