import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom'
import "react-tabs/style/react-tabs.css";
import axios from 'axios'
import Select from 'react-select';
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import Aviso from '../../components/Aviso'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'


class Form extends Component {

    state = {
        detalhes: { "imei": "", "fabricante_id": "", "modelo_id": "", "operadora_id": "", "sim_card": "", "status": "" },
        formerrors: [],
        fabricantes: [],
        modelos: [],
        operadoras: [],
        redirect: false,
        path: "",
        modalParam: false,
        idConfiguracao: '',
        optionSelected: { "fabricante_id": {}, "modelo_id": {}, "operadora_id": {} },
        aviso: { show: false, args: null, message: '' },
        cooldown: false,
        cancelModal: null,
    }

    handleChange = (e) => {
        var obj = this.state.detalhes
        obj[e.target.id] = e.target.value
        document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""
        this.setState({ "detalhes": obj })
    }

    componentWillMount = () => {
        this.getInitialData()
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }
    }

    getData = () => {
        let resource = "equipamento"
        let userInfo = auth.getUserInfo();
        let requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((response) => {
                delete response.data.id
                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/fabricante/' + response.data.fabricante_id + '/modelo/', config_me)
                    .then((modelos) => {
                        let optionsModelos = []
                        if (modelos.status === 200) {
                            modelos.data.forEach((a) => {
                                optionsModelos.push({ "value": a.id, "label": a.modelo })
                            })
                        }

                        var optionObj = this.state.optionSelected
                        optionObj["fabricante_id"] = { "value": response.data.fabricante, "label": response.data.fabricante__nome }
                        optionObj["modelo_id"] = { "value": response.data.modelo, "label": response.data.modelo__modelo }
                        optionObj["operadora_id"] = { "value": response.data.operadora, "label": response.data.operadora__nome }

                        this.setState({ detalhes: response.data, modelos: optionsModelos, optionSelected: optionObj });
                    })
            })
    }

    ordernarAlfabeticamente = (a, b) => {
        const nomeA = a.label.toLowerCase()
        const nomeB = b.label.toLowerCase()
        if(nomeA < nomeB) {
            return -1
        }
        if(nomeA > nomeB) {
            return 1
        }
        return 0
    }

    getInitialData = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/operadora/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/fabricante/', config_me))

        axios.all(requestArray)
            .then(axios.spread((operadoras, fabricantes) => {
                let optionsFabricantes = []
                if (fabricantes.status === 200) {
                    fabricantes.data.forEach((a) => {
                        optionsFabricantes.push({ "value": a.id, "label": a.nome })
                    })
                    
                    optionsFabricantes.sort(this.ordernarAlfabeticamente)
                }
                let optionsOperadoras = []
                if (operadoras.status === 200) {
                    operadoras.data.forEach((a) => {
                        optionsOperadoras.push({ "value": a.id, "label": a.nome })
                    })
                    optionsOperadoras.sort(this.ordernarAlfabeticamente)
                }
                this.setState({ "fabricantes": optionsFabricantes, "operadoras": optionsOperadoras })
            }));
    }

    handleChangeFabricante = (selectedOption, field) => {
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        let obj = this.state.detalhes
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/fabricante/' + this.state.detalhes.fabricante_id + '/modelo/', config_me)
            .then((resp) => {
                let optionsModelos = []
                if (resp.status === 200) {
                    resp.data.forEach((a) => {
                        optionsModelos.push({ "value": a.id, "label": a.modelo })
                    })
                    optionsModelos.sort(this.ordernarAlfabeticamente)
                }
                //this.setState({"detalhes": obj, "modelos": resp.data})
                this.setState({ "optionSelected": optionObj, "detalhes": obj, "modelos": optionsModelos });
                document.querySelector(`#validacao-${field.slice(0, -3)}`).innerHTML = ""
            });
    }

    handleChangeSelect = (selectedOption, field) => {
        var obj = this.state.detalhes
        obj[field] = selectedOption.value
        var optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        document.querySelector(`#validacao-${field.slice(0, -3)}`).innerHTML = ""
        this.setState({ "optionSelected": optionObj, "detalhes": obj });
    }

    generateOption = (source, value, desc) => {
        let options = source.map((obj, idx) =>
            <option key={idx} value={obj[value]}>{obj[desc]}</option>
        );
        return options;
    }

    openModalCancel = () => {
        this.setState({cancelModal: true})
    }

    closeModalCancel = () => {
        this.setState({cancelModal: false})
    }

    confirmModalCancel = () => {
        window.location = "/equipamento"
    }

    saveAction = (novo_cadastro) => {
        this.setState({ cooldown: true })
        let self = this
        let userInfo = auth.getUserInfo();
        let resource = "equipamento"
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/';
            verb = 'post'
        }

        var bodyFormData = new FormData();

        for (var key in this.state.detalhes) {
            bodyFormData.append(key, this.state.detalhes[key]);
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((res) => {
                this.setState({ idConfiguracao: res.data.config })
                if (novo_cadastro === true)
                    window.location.reload()
                else
                    this.toggleModal()
            })
            .catch(function (error) {
                
                if(error.response.data.code === 33){
                    const aviso = {
                         show: true, message: error.response.data.message, args: { redirect: true, path: "/equipamento" } }
                    self.setState({ aviso })
                    return
                }

                if(error.response.data.code === 34){
                    const aviso = { show: true, message: error.response.data.message, args: { redirect: true, path: "/acessos" } }
                    self.setState({ aviso })
                    return
                }

                let erros = error.response.data
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span)
                            span.innerHTML = erros[i][1]
                    }
                }
            })

        setTimeout(() => this.setState({ cooldown: false }), 1000);
    }

    toggleModal = () => this.setState({ modalParam: !this.state.modalParam })

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    redirecionar = () => this.setState({ redirect: true, path: "/equipamento" })

    render() {
        console.log(this.state)
        const selectedFabricante = this.state.optionSelected.fabricante_id;
        const selectedModelo = this.state.optionSelected.modelo_id;
        const selectedOperadora = this.state.optionSelected.operadora_id;

        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        return (
            <>
                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.setState(this.state.aviso.args)}
                    open={this.state.aviso.show}
                />
                <Modal isOpen={this.state.modalParam} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Parametrizar</ModalHeader>
                    <ModalBody style={{ padding: '12px' }}>
                        <p style={{ margin: '25px 10px' }}>Parametrizar equipamento cadastrado?</p>
                        <ModalFooter>
                            <Link to={`/parametrizacao/${this.state.idConfiguracao || this.props.location.hidroconfig}/configurar`}>
                                <button onClick={() => { }} className="hdv-btn-forms hdv-btn-geen">Confirmar</button>
                            </Link>
                            <Link to="/equipamento">
                                <button onClick={() => this.redirecionar()} className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                            </Link>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.cancelModal}>
                    <ModalHeader>
                        Cancelar
                    </ModalHeader>
                    <ModalBody>
                    {this.props.match.params.id ? <h6>Certeza de que deseja cancelar a edição do equipamento?</h6> : <h6>Certezade que deseja cancelar a criação do equipamento?</h6>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModalCancel}>
                            Não
                        </Button>
                        <Button color="danger" onClick={this.confirmModalCancel}>
                            Sim
                        </Button>
                    </ModalFooter>
                </Modal>
                <div>
                    {this.props.match.params.id ? <h4>Editar Equipamento</h4> : <h4>Novo Equipamento</h4>}
                    <Tabs className="tabs-style">
                        <TabList>
                            <Tab>Detalhes do equipamento</Tab>
                        </TabList>

                        <TabPanel>
                            <form className="hdv-form-default">
                                <label className="required" htmlFor="razao_social">Imei</label>
                                <input disabled={(this.props.match.params.id) ? true : false} type="text" name="imei" id="imei" value={this.state.detalhes.imei} onChange={this.handleChange} />
                                <span id="validacao-imei"></span>
                                <label className="required" htmlFor="fabricante_id">Fabricante</label>
                                <Select
                                    value={selectedFabricante}
                                    onChange={(e) => this.handleChangeFabricante(e, 'fabricante_id')}
                                    options={this.state.fabricantes}
                                />
                                <span id="validacao-fabricante"></span>
                                <label className="required" htmlFor="modelo_id">Modelo</label>
                                <Select
                                    value={selectedModelo}
                                    onChange={(e) => this.handleChangeSelect(e, 'modelo_id')}
                                    options={this.state.modelos}
                                />
                                <span id="validacao-modelo"></span>
                                <label className="required" htmlFor="operadora_id">Operadora</label>
                                <Select
                                    value={selectedOperadora}
                                    onChange={(e) => this.handleChangeSelect(e, 'operadora_id')}
                                    options={this.state.operadoras}
                                />
                                <span id="validacao-operadora"></span>
                                <label className="required" htmlFor="sim_card">Número do SimCard</label>
                                <input type="text" name="sim_card" id="sim_card" value={this.state.detalhes.sim_card} onChange={this.handleChange} />
                                <span id="validacao-sim_card"></span>
                                <label className="required" htmlFor="status">Status</label>
                                <div style={{ display: 'flex' }}>
                                    <label>
                                        <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'ativo'} value="ativo" onChange={this.handleChange} /> Ativo
                                    </label>
                                    <label>
                                        <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'bloqueado'} value="bloqueado" onChange={this.handleChange} /> Bloqueado
                                    </label>
                                    <label>
                                        <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'cancelado'} value="cancelado" onChange={this.handleChange} /> Cancelado
                                    </label>
                                </div>
                                <br />
                                <span id="validacao-status"></span>
                            </form>
                        </TabPanel>
                    </Tabs>

                    <div className="hdv-btn-group">
                        <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>Salvar</button>
                        <button onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>Salvar e Cadastrar um Novo</button>
                            <button onClick={() => this.openModalCancel()} className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </div>

                </div>
            </>
        );
    }
}

export default Form;