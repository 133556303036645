import React, { Component } from 'react'
import HeaderMultiReport from '../../components/HeaderMultiReport'
import ReactLoading from 'react-loading'
import CsvDownloader from 'react-csv-downloader'
import axios from 'axios'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import { Link, Redirect } from 'react-router-dom'
import Aviso from '../../components/Aviso'
import ReactEcharts from "echarts-for-react"
import domToImage from 'dom-to-image'
import pdfMake from 'pdfmake'
import Select from 'react-select'
import helper_fn from '../../utils/helper'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

pdfMake.fonts = {
   Roboto: {
      normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
   }
}

class Form extends Component {
   userInfo = auth.getUserInfo()
   config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
   baseUrl = `${cfg.base_api_url + cfg.api_version}`

   state = {
      selectedOption: null,
      detalhesDefault: {
         descricao: "",
         unidade: { label: 'm3', value: 'm3' },
         data: "",
         modal: false,
         agrupar: "",
         cliente: "",
         disponivel_cliente_final: false,
         dispositivos: [],
      },
      ordenar: { label: 'Monitorado', value: 'monitorado' },
      hidrometrosOption: [],
      configuracaoSelecionada: null,
      validacaoFormulario: { data: true, agrupar: true, descricao: true, dispositivos: true },
      aviso: { show: false, args: null, message: '' },
      carregando: false,
      dataCsv: [],
      relatorioGerado: false,
      chart: null,
      table: null,
      csv: { data: [], columns: [] },
      lodaingClientId: false,
      modalWarning: false
   }

   componentDidMount() {
      if (this.props.match.params.id) {
         this.getData()
      }
   }

   getData() {
      axios({
         method: 'GET',
         url: this.baseUrl + '/' + this.userInfo.empresa + `/relatorio/consumo/${this.props.match.params.id}`,
         headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
      })
         .then(res => {
            if (res.data?.equipamento) {
               let dispositivos = []
               let { detalhesDefault } = this.state

               JSON.parse(res.data.equipamento)?.map(equipamento => {
                  dispositivos.push({
                     id: equipamento.id,
                     campo: equipamento.field,
                     posicao: equipamento.position !== false ? equipamento.position : false
                  })
               })

               detalhesDefault['descricao'] = res.data.descricao
               detalhesDefault['dispositivos'] = dispositivos

               if (Boolean(res.data.cliente)) {
                  detalhesDefault['disponivel_cliente_final'] = true
                  detalhesDefault['cliente'] = { label: "", value: res.data.cliente }
               }

               this.setState({ detalhesDefault })
            }
         })
   }

   saveAction = () => {
      let { detalhesDefault } = this.state
      let notValid = false
      let validateForm = this.state.validacaoFormulario
      let dispositivos = []
      let verb, url

      const except = ['modal', 'data', 'agrupar', 'dispositivos', 'unidade', 'disponivel_cliente_final', 'cliente']
      for (let key in detalhesDefault) {
         if (!detalhesDefault[key] && !except.includes(key)) {
            validateForm[key] = false
            notValid = true
         } else validateForm[key] = true
      }

      if (notValid === true) {
         this.setState({ validacaoFormulario: validateForm })
         return
      }

      this.setState({ cooldown: true })

      detalhesDefault.dispositivos.map(dispositivo => {
         dispositivos.push({
            id: dispositivo.id,
            field: dispositivo.campo,
            position: dispositivo.posicao ? Number(dispositivo.posicao) : false
         })
      })

      const bodyFormData = new FormData()
      bodyFormData.append('descricao', detalhesDefault.descricao)
      bodyFormData.append('equipamento', JSON.stringify(dispositivos))

      if (detalhesDefault.disponivel_cliente_final) {
         bodyFormData.append('cliente_id', detalhesDefault.cliente)
      }

      if (this.props.match.params.id) {
         verb = 'PATCH'
         url = this.baseUrl + '/' + this.userInfo?.empresa + `/relatorio/consumo/${this.props.match.params.id}/`
      } else {
         verb = 'POST'
         url = this.baseUrl + '/' + this.userInfo?.empresa + `/relatorio/consumo/`
      }

      axios({
         method: verb,
         url: url,
         data: bodyFormData,
         headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
      }).then(res => {
         const aviso = { show: true, message: `Relatorio salvo com sucesso`, args: { redirect: true, path: "/relatorio-consumo" } }
         this.setState({ aviso, relatorioGerado: false, chart: null, table: null })
      }).catch((error) => console.log(error))

      setTimeout(() => this.setState({ cooldown: false }), 2000)
   }

   buscarDadosRelatorio = async () => {
      const { detalhesDefault } = this.state
      let dispositivos = []
      let notValid = false
      let validateForm = this.state.validacaoFormulario
      let chart = { series: [], xAxis: [] }

      this.setState({ carregando: true, relatorioGerado: false, chart: null, table: null })

      const except = ['modal', 'descricao', 'dispositivos', 'unidade', 'disponivel_cliente_final', 'cliente']
      for (let key in detalhesDefault) {
         if (!detalhesDefault[key] && !except.includes(key)) {
            validateForm[key] = false
            notValid = true
         } else validateForm[key] = true
      }
      if (notValid === true) {
         this.setState({ validacaoFormulario: validateForm, carregando: false })
         return
      }

      if (detalhesDefault.dispositivos.length > 0) {
         detalhesDefault.dispositivos.map(dispositivo => {
            dispositivos.push({
               id: dispositivo.id,
               field: dispositivo.campo,
               position: dispositivo.posicao ? Number(dispositivo.posicao) : false
            })
         })

         await axios({
            method: 'POST',
            url: this.baseUrl + '/' + this.userInfo?.empresa + '/reporte/consumo/',
            data: {
               "timestamp_dispositivo_ini": detalhesDefault.data?.split('-')[0].split('/').reverse().join('-') + ' 00:00:00',
               "timestamp_dispositivo_fim": detalhesDefault.data?.split('-')[1].split('/').reverse().join('-') + ' 23:59:59',
               "grupo": detalhesDefault.agrupar,
               "dispositivos": dispositivos
            },
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
         })
            .then(res => {
               let data = res.data
               let chartData = []
               let csv = { data: [], columns: [] }
               let total = res.data?.data?.TOTAL
               let custoTotal = res.data?.data?.CUSTO_TOTAL?.toFixed(2)
               let custoMedio = res.data?.data?.CUSTO_MEDIO
               let mediaConsumo = res.data?.data?.MEDIA
               let mediaCTotal = res.data?.data?.MEDIA
               let mediaCustoTotal = res.data?.CUSTO_MEDIO
               let table = { total: total, custo_total: custoTotal, media: mediaConsumo, media: mediaCTotal, custo_medio: custoMedio, custoMedio: mediaCustoTotal, itens: [] }
               const keys = []

               Object.keys(data.config.RANGE).map(key => keys.push({ key, ...data.config.RANGE[key] }))
               keys.sort((a, b) => {
                  return new Date(a.MYTIMEZONE.end).getTime() - new Date(b.MYTIMEZONE.end).getTime()
               })

               keys.map(key => chart.xAxis.push(Number(key.key)))
               chart.xAxis.map((key, index) => {
                  if (data.data.TOTAL_GROUP[key]) {
                     chartData.push(data.data.TOTAL_GROUP[key])
                  } else {
                     chartData.push(0)
                  }
               })

               if (res.data.data.SENSOR) {
                  for (let key in res.data.data.SENSOR) {
                     let value = Number(res.data.data.SENSOR[key].total).toFixed(2)
                     let mediaconsumo = Number(res.data.data.SENSOR[key].media).toFixed(2)
                     let valorCusto = Number(res.data.data.SENSOR[key].custo_total).toFixed(2)
                     let customedio = Number(res.data.data.SENSOR[key].customedio).toFixed(2)
                     let entrada = res.data.data.SENSOR[key]?.place ?? ""
                     let monitorado = res.data.data.SENSOR[key]?.monitorado ?? ""
                     let split = key.split("_")
                     const id = split[0]
                     split.shift()

                     table.itens.push({
                        id: id,
                        place: entrada,
                        monitorado: monitorado,
                        label: id,
                        campo: `${split.join("_")}`,
                        consumo: value,
                        mediaConsumo: mediaconsumo,
                        custo: valorCusto,
                        custoMedio: customedio,
                        porcentagem: (Number(value) !== 0 ? (value / total) * 100 : 0).toFixed(2)
                     })

                     csv.data.push({ c0: entrada, c1: key, c2: value, c3: valorCusto })
                  }

                  table.itens.sort(this.sortItensReport)
                  csv.data.sort(this.sortItensReportCsv)
               }

               csv.data.push({ c1: 'Total', c2: total, c3: custoTotal })
               chart.series.push({ type: 'line', data: chartData })

               csv.columns = [
                  { id: 'c0', displayName: 'Monitorado' },
                  { id: 'c1', displayName: 'Dispositivo' },
                  { id: 'c2', displayName: 'Consumo' },
                  { id: 'c3', displayName: 'Custo' }
               ]

               this.setState({ relatorioGerado: true, carregando: false, chart: chart, table: table, csv: csv })
            })
            .catch(err => console.log(err))
      }
      this.setState({ carregando: false })
   }

   sortItensReport = ( a, b ) => {
      let condicaoA = this.state.ordenar.value == 'monitorado' ? a.place.trim() : parseFloat(a.consumo)
      let condicaoB = this.state.ordenar.value == 'monitorado' ? b.place.trim() : parseFloat(b.consumo)

      if ( condicaoA < condicaoB ) {
         return -1
      }

      if ( condicaoA > condicaoB ) {
         return 1
      }

      let condicaoC = this.state.ordenar.value == 'consumo' ? a.place.trim() : parseFloat(a.consumo)
      let condicaoD = this.state.ordenar.value == 'consumo' ? b.place.trim() : parseFloat(b.consumo)

      if ( condicaoC < condicaoD ) {
         return -1
      }

      if ( condicaoC > condicaoD ) {
         return 1
      }

      return 0

   }

   sortItensReportCsv = (a, b) => {
      let condicaoA = this.state.ordenar.value === 'monitorado' ? a.c0.trim() : parseFloat(a.c2);
      let condicaoB = this.state.ordenar.value === 'monitorado' ? b.c0.trim() : parseFloat(b.c2);
    
      if (condicaoA < condicaoB) {
        return -1;
      }
    
      if (condicaoA > condicaoB) {
        return 1;
      }
    
      let condicaoC = this.state.ordenar.value === 'consumo' ? a.c0.trim() : parseFloat(a.c2);
      let condicaoD = this.state.ordenar.value === 'consumo' ? b.c0.trim() : parseFloat(b.c2);
    
      if (condicaoC < condicaoD) {
        return -1;
      }
    
      if (condicaoC > condicaoD) {
        return 1;
      }
    
      return 0;
    }
    

   formatTooltip = (params) => {
      const { detalhesDefault } = this.state
      if (Array.isArray(params)) {
         let formatted = ""
         console.log(params)
         for (let i = 0; i < params.length; i++) {
            formatted += `<div style="display: flex; align-items: center;"><div style="background-color: ${params?.[i]?.color}; height: 14px; width: 14px; border-radius: 7px; margin-right: 5px"></div>`
            //formatted += `${params[i]?.seriesName}:&nbsp; <b>${Number(Number(params[i].data).toFixed(2))}</b>`
            formatted += `${params[i]?.seriesName}:&nbsp; <b>${(detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(params[i].data).toFixed(2))) : Number(Number(params[i].data).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits: 2})}</b>`
            formatted += (i !== (params.length - 1)) ? "</div>\n" : "</div>"
         }

         return formatted
      }
      return ""
   }

   convertDecimalHoursToHourMinuteSecond = (value) => {
      let decimalTimeString = value
      let decimalTime = parseFloat(decimalTimeString)
      decimalTime = decimalTime * 60 * 60
      let hours = Math.floor((decimalTime / (60 * 60)))
      decimalTime = decimalTime - (hours * 60 * 60)
      let minutes = Math.floor((decimalTime / 60))
      decimalTime = decimalTime - (minutes * 60)
      let seconds = Math.round(decimalTime)
      if (hours < 10)
         hours = "0" + hours

      if (minutes < 10)
         minutes = "0" + minutes

      if (seconds < 10)
         seconds = "0" + seconds

      return ("" + hours + ":" + minutes + ":" + seconds)
   }

   handleChange = (key, value) => {
      let { detalhesDefault } = this.state
      detalhesDefault[key] = value

      this.setState({ detalhesDefault: detalhesDefault })
   }

   toggleModalWarning = () => {
      this.setState({modalWarning: !this.state.modalWarning})
   }

   handleChangeAvailability = (key, value) => {
      let { detalhesDefault } = this.state

      if (detalhesDefault.dispositivos[0]) {
         let userInfo = auth.getUserInfo();
         let requestURL = requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/equipamento/' + detalhesDefault.dispositivos[0].id + '/status/';
         let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
         let client_name = []
         let client_id = []

         detalhesDefault[key] = value
         this.setState({ lodaingClientId: true })

         axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/?page=' + 0 + '&pageSize=' + 1000 + '&filter=' + encodeURI(JSON.stringify([])), config_me)
         .then((response) => {
             response.data.results.map(key => client_name.push(key.nome_fantasia))
             response.data.results.map(key => client_id.push(key.id))
         })

         axios({
            method: 'GET',
            url: requestURL,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((res) => {
              detalhesDefault['cliente'] = client_id[client_name.indexOf(res.data.cliente[0])]
              this.setState({ lodaingClientId: false })
            })
            .catch((error) => {
               this.setState({ lodaingClientId: false })
            })
         
         this.setState({ detalhesDefault: detalhesDefault })
      }else{
         this.toggleModalWarning()
      }
   }

   deletar = (index) => {
      let { detalhesDefault } = this.state
      detalhesDefault['dispositivos'].splice(index, 1)
      this.setState({ detalhesDefault })
   }

   adicionar = (itens) => {
      let { detalhesDefault, validacaoFormulario } = this.state
      let bodyFormData = new FormData()
      let dispositivos = []
      let dispositivosOk = true
      validacaoFormulario['dispositivos'] = true

      for (let dispositivo of itens) {
         if (!dispositivo.campo) {
            dispositivosOk = false
            break
         } else {
            dispositivos.push({
               id: dispositivo.id,
               field: dispositivo.campo,
               value: dispositivo.value,
               position: dispositivo.posicao ? Number(dispositivo.posicao) : false
            })
         }
      }

      if (dispositivosOk) {
         bodyFormData.append('equipamento', JSON.stringify(dispositivos))

         if (this.props.match.params.id) {
            axios({
               method: 'PATCH',
               url: this.baseUrl + '/' + this.userInfo.empresa + `/relatorio/consumo/${this.props.match.params.id}/`,
               data: bodyFormData,
               headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
               .then(res => {
                  detalhesDefault['dispositivos'] = itens
                  this.setState({ detalhesDefault, relatorioGerado: false, chart: null, table: null })
                  this.toggleModal()
               })
               .catch((error) => console.log(error))
         } else {
            detalhesDefault['dispositivos'] = itens
            this.setState({ detalhesDefault })
            this.toggleModal()
         }
      } else {
         validacaoFormulario['dispositivos'] = false
         this.setState({ validacaoFormulario })
      }
   }

   toggleAviso = () => {
      const { aviso } = this.state
      aviso['show'] = !aviso['show']
      this.setState({ aviso })
   }

   toggleModal = () => {
      let { detalhesDefault, validacaoFormulario } = this.state
      for (let key in validacaoFormulario) {
         validacaoFormulario[key] = true
      }

      detalhesDefault['modal'] = false
      this.setState({ validacaoFormulario, detalhesDefault })
   }

   gerarGrafico = () => {
      const { chart, detalhesDefault } = this.state
      const axisType = { day: 'Dias', month: 'Meses', hour: 'Horas' }
      const labelDesc = { day: '(Dia)', month: '(Mês)', hour: '(Hora)' }
      let series = []

      series = chart?.series?.map((serie, index) => {
         if (serie.data.length > 0) {
            let label = {
               show: true,
               position: 'top',
               color: "rgba(0, 0, 0, 0.85)",
               fontWeight: '600',
               textBorderColor: '#fff',
               textBorderWidth: 2,
               distance: 5,
               fontSize: 12,
               rotate: chart.xAxis.length > 20 ? 55 : 0,
               formatter: (params) => !isNaN(params.value) ? (detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(params.data).toFixed(2))) : Number(Number(params.data).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits: 2}) : (detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(params.value).toFixed(2))) : Number(Number(params.value).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits: 2})
            }

            return {
               name: `Total ${labelDesc[detalhesDefault.agrupar]}`,
               data: serie.data,
               type: serie.type,
               itemStyle: { color: '#4287f5', opacity: 1 },
               lineStyle: { width: 2 },
               labelLayout: { hideOverlap: true },
               label: label,
               areaStyle: { opacity: 0.5 }
            }
         }
         return null
      }).filter(s => s !== null)

      return (
         <div className="chart-item" style={{ padding: '3px' }}>
            <h5 style={{ textAlign: 'center' }}>Relatório de Consumo</h5>

            <p style={{ opacity: '0.85', margin: '12px 0 0 0' }}>Relatório: {detalhesDefault?.descricao}</p>
            <p style={{ opacity: '0.85', margin: '0' }}>Período: {detalhesDefault.data?.replace('-', ' a ')}</p>

            <div className="d-flex justify-content-between mt-2">
               <strong style={{ fontSize: '20px' }}>Consumo Total</strong>
               <span style={{ fontSize: '20px', opacity: '0.9', fontWeight: 'bold' }}>
                  {(detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(this.state.table.total).toFixed(2))) : Number(Number(this.state.table.total).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits: 2})}
                  {detalhesDefault.unidade?.value ? ` (${detalhesDefault.unidade?.value})` : null}
               </span>
            </div>
            <div className="hdv-chart">
               {series?.length > 0 ?
                  <ReactEcharts
                     style={{ height: '360px', width: '100%' }}
                     option={{
                        series: series,
                        xAxis: {
                           data: chart?.xAxis,
                           name: axisType[detalhesDefault.agrupar],
                           nameTextStyle: { fontWeight: '600', fontSize: '12' },
                           nameLocation: 'center',
                           nameGap: 22
                        },
                        yAxis: { type: 'value' },
                        grid: { left: 60, right: 20, bottom: 60 },
                        tooltip: {
                           show: true,
                           trigger: "axis",
                           axisPointer: { type: "shadow", label: { show: true } },
                           formatter: (params) => this.formatTooltip(params)
                        }
                     }} />
                  : <p style={{ margin: '12px 0', textAlign: 'center', color: '#a33131', fontWeight: 'bold' }}>Erro ao carregar gráfico</p>}
            </div>
            <hr />
         </div>
      )
   }

   exportarPDF = async () => {
      let infoEmpresa
      await axios.get(this.baseUrl + `/${this.userInfo.empresa}/configuracao-sistema`, this.config_me)
         .then(res => { infoEmpresa = res.data?.data })

      fetch((await domToImage.toPng(document.querySelector('section#hdv-multi-report-print')))).then(async (data) => {
         const pdfOptions = {
            content: [{
               table: {
                  widths: ['*', 120],
                  body: [[{
                     text: [infoEmpresa?.email_contato, '\n', infoEmpresa?.endereco, '\n', infoEmpresa?.telefone, "\n"],
                     border: [false, false, false, true]
                  },
                  { image: 'logo', style: 'logo', width: 80, border: [false, false, false, true] },
                  ]]
               },
            },
            { image: 'chart', pageBreak: 'after', width: 516 },
            ],
            images: {
               'logo': document.querySelector('div.hdv-logomarca img').src,
               'chart': data.url
            },
            styles: {
               logo: { margin: [40, -18, 70, 0] }
            }
         }

         const win = window.open('', '_blank')
         pdfMake.createPdf(pdfOptions).open({}, win)
      })
   }

   render() {
      const { detalhesDefault, validacaoFormulario, relatorioGerado } = this.state
      const ordenarOption = this.state.ordenar

      if (this.state.redirect)
         return <Redirect to="/relatorio-consumo" />

      return (
         <div>
            <Modal isOpen={this.state.modalWarning}>
                  <ModalHeader>
                     Aviso
                  </ModalHeader>
                  <ModalBody>
                     <h6>É necessario selecionar no mínimo um dispositivo antes de disponibilizar para cliente final</h6>
                  </ModalBody>
                  <ModalFooter>
                     <Button color="primary" onClick={this.toggleModalWarning}>
                           OK
                     </Button>
                  </ModalFooter>
            </Modal>

            <Aviso
               toggle={() => this.toggleAviso}
               mensagem={this.state.aviso.message}
               action={() => this.setState(this.state.aviso.args)}
               continue={Boolean(this.props.match.params.id) ? () => {
                  let { aviso } = this.state
                  aviso = { show: false, args: null, message: '' }
                  this.setState({ aviso })
               }
                  :
                  null}
               open={this.state.aviso.show}
            />

            <h3>Relatório de Consumo</h3>
            <div id="download-pdf"></div>
            <div className="hdv-header-filters py-0 pt-2 mx-0" style={{ height: 'fit-content' }}>
               <div className="row">
                  <div className="col-md-5  hdv-form-default">
                     <label>Descrição</label>
                     <input
                        type="text"
                        onChange={(e) => this.handleChange('descricao', e.target.value)}
                        style={{ border: '1px solid #d0d0d0' }}
                        value={detalhesDefault.descricao}
                     />
                     <span className={!this.state.validacaoFormulario.descricao ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                  </div>

                  <div className="col-md-3"></div>
                  <div className="col-md-2 px-0 pt-3">
                     <label htmlFor="disponivel_cliente_final">Disponível para cliente final</label>
                     <input
                        type="checkbox"
                        id="disponivel_cliente_final"
                        onChange={(e) => this.handleChangeAvailability('disponivel_cliente_final', e.target.checked)}
                        checked={detalhesDefault.disponivel_cliente_final}
                     />
                  </div>
                  <div className="col-md-2 hdv-form-default">
                     <label>Unidade do Gráfico</label>
                     <Select
                        value={detalhesDefault.unidade}
                        onChange={(e) => this.handleChange('unidade', e)}
                        options={[
                           { label: 'm3', value: 'm3' },
                           { label: 'kW', value: 'kW' },
                           { label: 'kW/h', value: 'kW/h' },
                           { label: 'horas', value: 'horas' },
                        ]}
                     />
                  </div>
               </div>

               <HeaderMultiReport
                  DataChange={detalhesDefault}
                  toggleModal={this.toggleModal}
                  dispositivos={detalhesDefault.dispositivos}
                  adicionar={this.adicionar}
                  validacao={validacaoFormulario}
                  deletar={this.deletar}
                  handleChange={this.handleChange}
               />

               <div className="col-md-4 hdv-form-default px-0">
                  <label className="mt-2">Ordenar por</label>
                  <Select
                     value={ordenarOption} 
                     onChange={(e) => {
                        this.setState({ordenar:e})
                     }}
                     options={[
                        { label: 'Monitorado', value: 'monitorado' },
                        { label: 'Consumo', value: 'consumo' },
                     ]}
                  />
               </div>

               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                  <div>
                     <button
                        disabled={!this.state.relatorioGerado}
                        className={!this.state.relatorioGerado ? "hdv-btn-forms hdv-btn-red hdv-block-button" : "hdv-btn-forms hdv-btn-red"}
                        onClick={this.exportarPDF} > <i className="fa fa-file-pdf-o fa-20px"></i> Exportar PDF
                     </button>

                     <button
                        style={{ backgroundColor: '#4da845', width: 'fit-content', padding: '10px', margin: '12px 8px', borderRadius: '4px', border: 'none', cursor: 'pointer' }}
                        className={(this.state.relatorioGerado ? "" : "hdv-block-button")}
                        disabled={!this.state.relatorioGerado}>
                        {this.state.relatorioGerado
                           ?
                           <CsvDownloader
                              style={{ border: 'none' }}
                              columns={this.state.csv.columns}
                              datas={this.state.csv.data}
                              filename={`relatorio_consumo_${detalhesDefault?.descricao}.csv`}
                              separator=";">
                              <p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '0 10px' }}>
                                 Exportar CSV
                              </p>
                           </CsvDownloader>
                           :
                           <p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '0 10px' }}>
                              Exportar CSV
                           </p>
                        }
                     </button>
                  </div>
                  <div className="hdv-chart-options">
                     {this.state.carregando ?
                        <ReactLoading className="hdv-report-loading" type="cylon" color="#589bd4" width={50} />
                        :
                        null
                     }

                     <button
                        className="hdv-btn-forms hdv-btn-geen m-0 mx-2 hdv-automacao-add-new-button d-flex align-items-center"
                        disabled={this.state.carregando}
                        onClick={() => {
                           let { detalhesDefault } = this.state
                           detalhesDefault['modal'] = true
                           this.setState({ detalhesDefault })
                        }} >
                        Gerenciar Dispositivos
                     </button>

                     <button
                        disabled={this.state.carregando}
                        className="hdv-btn-forms hdv-btn-blue hdv-automacao-add-new-button m-0 d-flex align-items-center"
                        onClick={this.buscarDadosRelatorio}>
                        Gerar Relatório
                     </button>
                  </div>
               </div>
               <br />
            </div>
            <div className="hdv-multi-report-dispositivos">
               {detalhesDefault.dispositivos?.length > 0 ?
                  <details>
                     <summary>Dispositivos</summary>
                     <section className="row mx-1 d-flex justify-content-between">
                        {detalhesDefault.dispositivos?.map((dispositivo, index) => (<>
                           <div
                              className="hdv-multi-report-item my-1 mx-1"
                              style={{ width: '48%' }}
                              key={index}>
                              <span>
                                 {dispositivo.id}
                              </span>
                              <span>
                                 | <b>[{dispositivo.posicao !== false ? `${dispositivo?.campo}_${dispositivo?.posicao}` : dispositivo?.campo}]</b>
                              </span>
                           </div>
                        </>
                        ))}
                     </section>
                  </details>
                  :
                  null
               }
            </div>
            <hr />
            {relatorioGerado ?
               <section id="hdv-multi-report-print" className="mb-4">
                  <div className="hdv-relatorio-consumo col-md-12 mx-0 mt-3" style={{ padding: '8px 0' }}>
                     {this.gerarGrafico()}
                  </div>

                  <table className="table table-sm hdv-multi-report-table">
                     <thead>
                        <tr>
                           <th>Monitorado</th>
                           <th>Entrada</th>
                           <th>Consumo ({detalhesDefault?.unidade?.value})</th>
                           <th>Consumo médio</th>
                           <th>Custo (R$)</th>
                           <th>Custo médio (R$)</th>
                           <th>%</th>
                        </tr>
                     </thead>
                     <tbody>
                        {
                           this.state.table?.itens?.map((item, index) => (
                              <React.Fragment key={index}>
                                 <tr>
                                    <td>{item?.monitorado}</td>
                                    <td>{item?.place ?? '---'}</td>
                                    <td> {(detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(item.consumo).toFixed(2))) : Number(Number(item.consumo).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits: 2})}</td>
                                    <td>{(detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(item.mediaConsumo).toFixed(2))) : Number(Number(item.mediaConsumo).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits: 2})}</td>
                                    <td>{Number(Number(item.custo).toFixed(2)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                                    <td>{Number(Number(item.custoMedio).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</td>
                                    <td colSpan="2">
                                       <span className="d-flex">
                                          <progress value={item.porcentagem} max="100"></progress> <small>{Number(item.porcentagem).toLocaleString(undefined, { maximumFractionDigits: 2 })}%</small>
                                       </span>
                                    </td>
                                 </tr>

                                 {
                                    index === this.state.table.itens.length - 1
                                       ?
                                       <tr key={index + 1}>
                                          <td><strong>Total</strong></td>
                                          <td> {(detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(this.state.table.total).toFixed(2))) : Number(Number(this.state.table.total).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits: 2}) + ' ' + detalhesDefault?.unidade?.value }</td>
                                          <td>{(detalhesDefault.unidade?.value == "horas") ? helper_fn.converte_decimal_to_hhmmss(Number(Number(this.state.table.media).toFixed(2))) : Number(Number(this.state.table.media).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits: 2}) + ' ' + detalhesDefault?.unidade?.value }  </td>
                                          <td> R$ {(this.state.table.custo_total) ? Number(Number(this.state.table.custo_total).toFixed(2)).toLocaleString(undefined, {minimumFractionDigits:2}) : Number(Number(0.00).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits:2})}</td>
                                          <td>R$ {(this.state.table.custo_medio) ? Number(Number(this.state.table.custo_medio).toFixed(2)).toLocaleString(undefined, {minimumFractionDigits:2}) : Number(Number(0.00).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits:2})}</td>
                                          <td>
                                             {String(this.state.table.total) !== '0'
                                                ?
                                                <span className="d-flex">
                                                   <progress value="100" max="100"></progress> <small>100%</small>
                                                </span>
                                                :
                                                <span className="d-flex">
                                                   <progress value="0" max="100"></progress> <small>100%</small>
                                                </span>
                                             }
                                          </td>
                                       </tr>
                                       :
                                       null
                                 }
                              </React.Fragment>
                           ))
                        }
                     </tbody>
                  </table>
               </section>
               : null
            }

            <div className="hdv-btn-group">
               <Link to="/relatorio-consumo">
                  <button className="hdv-btn-forms hdv-btn-yellow ">Cancelar</button>
               </Link>

               <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown || this.state.lodaingClientId}>
                  Salvar
               </button>
            </div>
         </div>
      )
   }
}

export default Form