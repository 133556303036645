import React, { Component } from 'react';
import ReactTable from "react-table";
import { Link, Redirect } from "react-router-dom"
import _ from "underscore"
import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import 'react-table/react-table.css'

class Grid extends Component {

    state = {
        selected: [],
        selectAll: 0,
        data: [],
        pages: null,
        loading: true,
        filtered: true,
        enableDeletar: false,
        enableEditar: false,
        redirectToEdit: false,
        redirectToParam: false,
        modalEquipamentoVirtual: false,
        equipamentoVirtual: false,
        confirmDelete: null,
    };

    toggleRow = (id) => {
        let selecionados = this.state.selected
        let editar = false
        let deletar = false

        if (selecionados.includes(id)) {
            selecionados = selecionados.filter(item => item !== id)
        } else {
            selecionados.push(id)
        }

        if (selecionados.length === 1) {
            editar = true
        }

        if (selecionados.length >= 1) {
            deletar = true
        }

        this.setState({
            enableEditar: editar,
            enableDeletar: deletar,
            selected: selecionados,
            selectAll: 2
        });
    }

    toggleSelectAll = () => {
        let selecionados = [];
        let editar = false
        let deletar = false

        if (this.state.selectAll === 0) {
            this.state.data.forEach(x => {
                selecionados.push(x.id);
            });
        }

        if (selecionados.length === 1) {
            editar = true
        }

        if (selecionados.length >= 1) {
            deletar = true
        }

        this.setState({
            enableEditar: editar,
            enableDeletar: deletar,
            selected: selecionados,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    getClientes = _.debounce((state, instance) => {
        if (state) {
            if ("pageSize" in state) {
                this.setState({ loading: true });
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
                let userInfo = auth.getUserInfo();
                if (state.filtered)
                    state.filtered = state.filtered.filter(filter => filter.id !== "modelo_id")

                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/equipamento/?page=' + state.page + '&pageSize=' + state.pageSize + '&filter=' + encodeURI(JSON.stringify(state.filtered)), config_me)
                    .then((response) => {
                        this.setState({ selected: [], data: response.data.results, loading: false, pages: response.data.pages, filtered: true });
                    })
            }
        }
    }, 500)

    openModal = () => {
        this.setState({confirmDelete: true})
    }

    closeModal = () => {
        this.setState({confirmDelete: false})
    }

    confirmModal = () => {
        this.deletar()
        this.closeModal()
    }

    deletar = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
        this.setState({ loading: true, enableDeletar: false, enableEditar: false });

        this.state.selected.map((value, index) =>
            requestArray.push(axios.delete(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/equipamento/' + value + '/', config_me))
        );

        axios.all(requestArray)
            .then(axios.spread((acct, perms) => this.refReactTable.fireFetchData()))
    }

    editar = () => {
        let hidroConfigSelected = this.state.data.filter((equip) => {
            return equip.id === parseInt(this.state.selected[0])
        })
        this.state.selected.push(hidroConfigSelected[0].configuracao)

        let toGo, from
        toGo = "equipamento/" + this.state.selected[0] + "/editar"
        from = { pathname: toGo }

        this.setState({ redirectToEdit: from })
    }

    parametrizar = () => {
        let selected = this.state.data.filter((equip) => {
            return equip.id === parseInt(this.state.selected[0])
        })

        let toGo, from
        toGo = "parametrizacao/" + selected[0].configuracao + "/configurar"
        from = { pathname: toGo }

        this.setState({ redirectToParam: from })
    }

    criarEquipamentoVirtual = () => {
        let userInfo = auth.getUserInfo()

        axios({
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/equipamento-virtual/',
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((res) => this.setState({ equipamentoVirtual: res.data }))
            .catch(err => console.log(err))
    }

    render() {
        const { redirectToEdit, equipamentoVirtual, redirectToParam, selected } = this.state
        if (redirectToEdit)
            return <Redirect to={{
                    pathname: redirectToEdit.pathname,
                    hidroconfig: selected[1]
                }}
            />

        if (redirectToParam)
            return <Redirect to={redirectToParam} />

        const columns = [{
            Header: x => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={this.state.selectAll === 1}
                        ref={input => {
                            if (input) {
                                input.indeterminate = this.state.selectAll === 2;
                            }
                        }}
                        onChange={() => this.toggleSelectAll()}
                    />
                );
            },
            sortable: false,
            width: 55,
            accessor: "",
            filterable: false,
            Cell: ({ original }) => {
                return (
                    <div className="hdv-aligncenter-checkboxgrid">
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={(this.state.selected.includes(original.id) === true ? true : false)}
                            onChange={() => this.toggleRow(original.id)}
                        />
                    </div>
                );
            }
        }, {
            Header: 'Imei',
            accessor: 'imei'
        }, {
            Header: 'Fabricante',
            accessor: 'fabricante__nome',
            Filter: ({ filter, onChange }) => <input
                value={filter?.value}
                onChange={(e) => onChange(e.target.value.toUpperCase())}
                type="text"
            />
        }, {
            Header: 'Modelo',
            accessor: 'modelo__modelo',
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}>
                    <option value="">Todos</option>
                    <option value="16003W">16003W</option>
                    <option value="DUETO">DUETO</option>
                    <option value="HG4">HG4</option>
                    <option value="HG7">HG7</option>
                    <option value="HGETH">HGETH</option>
                    <option value="HG Virtual">HG Virtual</option>
                    <option value="HO-01">HO-01</option>
                    <option value="INOVE PIVÔ">INOVE PIVÔ</option>
                    <option value="ITC 100">ITC 100</option>
                    <option value="ITS 312">ITS 312</option>
                    <option value="ITS 402">ITS 402</option>
                    <option value="JE-02">JE-02</option>
                    <option value="JE-05">JE-05</option>
                    <option value="JN01">JN01</option>
                    <option value="KONECT">KONECT</option>
                    <option value="KS-3000">KS-3000</option>
                    <option value="LogBox Wi-Fi">LogBox Wi-Fi</option>
                    <option value="NIT 20 LI">NIT 20 LI</option>
                    <option value="OPEN HIDRO">OPEN HIDRO</option>
                    <option value="OPEN HIDRO GPRS">OPEN HIDRO GPRS</option>
                    <option value="OPEN HIDRO MQTT">OPEN HIDRO MQTT</option>
                    <option value="Sensor.log End Device">Sensor.log End Device</option>
                    <option value="TELEMETRIA LoRa">TELEMETRIA LoRa</option>
                </select>
        }, {
            Header: 'Operadora',
            accessor: 'operadora__nome'
        }, {
            Header: 'Número do SIMCARD',
            accessor: 'sim_card'
        }, {
            Header: 'Status',
            accessor: 'status',
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}>
                    <option value="">Mostrar Todos</option>
                    <option value="ativo">Ativo</option>
                    <option value="bloqueado">Bloqueado</option>
                </select>
        }]

        return (
            <>
                <Modal isOpen={this.state.modalEquipamentoVirtual} toggle={() => this.setState({ modalEquipamentoVirtual: false })}>
                    <ModalHeader toggle={() => this.setState({ modalEquipamentoVirtual: false })}>Novo Hidrohub Virtual</ModalHeader>
                    <ModalBody>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            {equipamentoVirtual
                                ?
                                <>
                                    {equipamentoVirtual ? (
                                        <>
                                            <strong>Aviso</strong>
                                            Equipamento {equipamentoVirtual.id} criado!
                                        </>
                                    ) : null}
                                </>
                                : <div style={{ height: '35px' }}></div>}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Link to={`/parametrizacao/${equipamentoVirtual?.id_configuracao}/configurar`}>
                            <Button color="success" disabled={!Boolean(this.state.equipamentoVirtual)} >
                                Parametrizar Equipamento
                            </Button>
                        </Link>
                        {Boolean(this.state.equipamentoVirtual)
                            ?
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.refReactTable.fireFetchData()
                                    this.setState({ modalEquipamentoVirtual: false })
                                }}>
                                Concluído
                            </Button>
                            :
                            <Button
                                color="success"
                                onClick={this.criarEquipamentoVirtual}>
                                Criar
                            </Button>
                        }

                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.confirmDelete}>
                    <ModalHeader>
                        Confirmar
                    </ModalHeader>
                    <ModalBody>
                        Você tem certeza de que deseja deletar?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>
                            Cancelar
                        </Button>
                        <Button color="danger" onClick={this.confirmModal}>
                            Sim
                        </Button>
                    </ModalFooter>
                </Modal>
                <div>
                    <h4>Cadastro de Equipamentos</h4>
                    <div className="hdv-btn-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Link to="/equipamento/novo">
                                <button className="hdv-btn-forms hdv-btn-geen ">Novo</button>
                            </Link>
                            <button onClick={() => { if (this.state.enableEditar === true) { this.editar() } }} className={"hdv-btn-forms hdv-btn-yellow " + (this.state.enableEditar === true ? "" : "hdv-btn-disable")}>Editar</button>
                            <button onClick={() => { if (this.state.enableDeletar === true) { this.openModal() } }} className={"hdv-btn-forms hdv-btn-red " + (this.state.enableDeletar === true ? "" : "hdv-btn-disable")}>Excluir</button>
                            <button onClick={() => { if (this.state.enableEditar === true) { this.parametrizar() } }} className={"hdv-btn-forms hdv-btn-blue " + (this.state.enableEditar === true ? "" : "hdv-btn-disable")}>Parametrizar</button>
                        </div>
                        <button
                            onClick={() => this.setState({ modalEquipamentoVirtual: true, equipamentoVirtual: false })}
                            className="hdv-btn-forms hdv-btn-blue">
                            Criar novo Equipamento Virtual
                        </button>
                    </div>

                    <ReactTable
                        ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                        manual
                        filterable
                        data={this.state.data}
                        pages={this.state.pages}
                        loading={this.state.loading}
                        onFetchData={this.getClientes}
                        columns={columns}
                        defaultPageSize={10}
                        previousText={'Anterior'}
                        nextText={'Próximo'}
                        loadingText={'Carregando...'}
                        noDataText={'Nenhum registro para exibir'}
                        pageText={'Página'}
                        ofText={'de'}
                        rowsText={'linhas'}
                    />

                </div>
            </>
        );
    }
}

export default Grid;
