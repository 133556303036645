const config = {

    base_api_url: "https://api.ivast.com.br/",
    api_version: "v1",
    client_id: "xdiyQEs6nShzJDbFr2L75kRG4ftMNtTzmiy600h7",
    client_secret: "cqyu4x5HAZnKaumjj31euIlfBp7Xx3yQqyaoH2ZbwXg4JOwjcwQ2qrugDjIgZJ0J2ZKF1RiZuBeF3NwTuZwt6wUW5t9xAGLGTEUVGvK0iGnqTGgEEIVislugcI6AwbbL",
    grant_type: "password"
}

export default config;
